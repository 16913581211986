import { Box, IconButton, Stack, Typography } from '@mui/material';
import Question, { Answer, Description } from '../../Question';
import { deletePerson, useGetKeyPerson } from '../../../_actions/application-form';
import { fetchContactInfo, fetchKeyPersonDetails, keyPeopleState, keyPersonState } from '../../../_state/application-form';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularLoading } from '../../../netcapital-components/CircularLoading';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import KeyPersonAddress from './Address';
import KeyPersonBio from './Bio';
import KeyPersonCrdNumber from './CRDNumber';
import KeyPersonDateOfBirth from './DateOfBirth';
import KeyPersonEmploymentRecords from './EmploymentRecords';
import KeyPersonHasDirectorHistory from './HasDirectorHistory';
import KeyPersonHasOfficerHistory from './HasOfficerHistory';
import KeyPersonIsPromoter from './IsPromoter';
import KeyPersonLinkedInProfile from './LinkedInProfile';
import KeyPersonName from './Name';
import Loading from '../../Loading';
import OutlinedButton from '../../../netcapital-components/OutlinedButton';
import SearchUser from './SearchUser';
import SolidButton from '../../../netcapital-components/SolidButton';
import { appGlobalSettingsState } from '../../../_state';
import { isAdminState } from '../../../_state/user';
import styles from './styles';
import { useAlertActions } from '../../../_actions/alert.actions';

function KeyPerson() {
  const { key: personKey, listingKey, offerKey } = useParams();
  const { loaded } = useGetKeyPerson(listingKey, offerKey, personKey);
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);
  const resetKeyPerson = useResetRecoilState(keyPersonState);
  const resetKeyPeople = useResetRecoilState(keyPeopleState);
  const navigate = useNavigate();
  const alertActions = useAlertActions();
  const isAdmin = useRecoilValue(isAdminState);
  const firstName = useRecoilValue(fetchKeyPersonDetails('firstName'));
  const lastName = useRecoilValue(fetchKeyPersonDetails('lastName'));
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    const resp = await deletePerson(listingKey, offerKey, personKey);
    setIsLoading(false);
    
    if (resp.response && resp.response.status > 204) {
      alertActions.error(`Failed to delete ${firstName || 'Key'} ${lastName || 'Person'}`, resp.response, resp.message, resp.request);
    } else {
      resetKeyPerson();
      resetKeyPeople();
      navigate(`/listings/${listingKey}/offers/${offerKey}/key-people`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey, personKey, firstName, lastName]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGoBack = useCallback(() => navigate(`/listings/${listingKey}/offers/${offerKey}/key-people`), []);

  if (!loaded || typeof firstName === 'undefined') {
    return (
      <Box sx={styles.LoadingSection}>
        <Typography align={'center'} component='div'>
          <CircularLoading />
          <p>Loading...</p>
        </Typography>
      </Box>
    );
  }

  return (
    <Stack spacing={4} sx={styles.KeyPerson}>
      <Loading loading={!loaded || isLoading} />
      {(isMobile || isTablet) && (
        <Stack direction='row' spacing={2}>
          <IconButton 
            aria-label='go back'
            onClick={handleGoBack} 
            sx={styles.GoBackButton}
          >
            <ArrowBackIcon color='secondary' />
          </IconButton> 
          <IconButton 
            aria-label='delete person'
            onClick={handleDelete} 
            sx={styles.DeleteButton}
          >
            <DeleteOutlinedIcon color='error' />
          </IconButton>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={styles.Header}>
        <Typography variant='h1' sx={styles.Title}>{`${firstName || 'Key'} ${lastName || 'Person'}`}</Typography>
        {(!isMobile && !isTablet) && (
          <OutlinedButton 
            color='error' 
            onClick={handleDelete}
            endIcon={<DeleteOutlinedIcon color='error' />}
            sx={styles.DeleteButton}
          >
            DELETE
          </OutlinedButton>
        )}
      </Stack>
      {isAdmin && (
        <Question>
          <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
            Does this key person need to be merged with an existing user? Search for a user and merge the key person into it.
          </Typography>
          <Answer
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='bottom'
          >
            <SearchUser 
              firstName={firstName}
              lastName={lastName}
            />
          </Answer>
        </Question>
      )}
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Confirm the full name of the key person.
        </Typography>
        <Answer>
          <KeyPersonName />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          What is their address?
        </Typography>
        <Answer>
          <KeyPersonAddress />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          What is their date of birth?
        </Typography>
        <Answer>
          <KeyPersonDateOfBirth />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          What is their CRD number? (if applicable)
        </Typography>
        <Answer>
          <KeyPersonCrdNumber />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Link to their LinkedIn profile
        </Typography>
        <Answer>
          <KeyPersonLinkedInProfile />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Please provide a brief bio for them.
        </Typography>
        <Description>
          <Typography variant='body2'>
            It is required that you include any positions that they have held over the past three years at {doingBusinessAs || 'the company'} and otherwise, including dates.
          </Typography>
          <br />
          <Typography variant='body2' fontWeight='fontWeightMedium'>
            Note: Leave ”End Date” field blank, if they are currently holding this position.
          </Typography>
        </Description>
        <Answer>
          <KeyPersonEmploymentRecords />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Please also share educational background and other qualifications that might be important to potential investors.
        </Typography>
        <Answer>
          <KeyPersonBio />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Has this person been an <Help explanation={HELP['Officer']}>officer</Help> of {doingBusinessAs || 'your company'}, occupied a similar status, or regularly performed similar functions?
        </Typography>
        <Answer>
          <KeyPersonHasOfficerHistory />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Has this person acted as, or will this person act as, a founder, organizer, or <Help explanation={HELP['Promoter']}>promoter</Help> of {doingBusinessAs || 'your company'}?
        </Typography>
        <Answer>
          <KeyPersonIsPromoter />
        </Answer>
      </Question>
      {isAdmin && (
        <Question>
          <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
            Has this person been a director (board member) of {doingBusinessAs || 'your company'}, occupied a similar status, or regularly performed similar functions?
          </Typography>
          <Answer
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='bottom'
          >
            <KeyPersonHasDirectorHistory />
          </Answer>
        </Question> 
      )}
      {(isMobile || isTablet) && (
        <SolidButton
          onClick={handleGoBack}
          color='secondary'
          disableElevation
        >
          <Typography variant='button'>Go back to Key People</Typography>
        </SolidButton>
      )}
    </Stack>
  );
}

export default KeyPerson;
