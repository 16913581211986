const styles = {
  LogoLink: ({ theme }) => ({
    display: 'block',
    position: 'relative',
    zIndex: 1, //To-do: Verify this is still ok
    [theme.breakpoints.up('md')]: {
      margin: '21px auto 0 12px',
    }
  }),
  LogoImg: ({ theme }) => ({
    display: 'block',
    width: '90px',
    [theme.breakpoints.up('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: '160px',
    },
  }),
};

export default styles;