const styles = {
  UploadBox: {
    display: 'inline-block', 
    width: 'auto'
  },
  Box: ({ palette }) => ({
    width: '100%',
    height: '50px',
    border: `2px solid ${palette.success.main}`,
    borderRadius: '6px',
    fontWeight: 600,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    color: palette.success.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  ButtonTypography: () => ({
    fontSize: '14px',
    lineHeight: 1,
    margin: '0 10px',
  }),
  DroppingFile: ({ palette }) => ({
    width: '264px',
    height: '50px',
    border: `2px solid ${palette.success.main}`,
    borderRadius: '6px',
    fontWeight: 600,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.success.main
  }),
  DropTypography: () => ({
    fontSize: '14px',
  }),
  FileLoaded: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    maxWidth: '100%'
  },
  Icon: {
    Upload: {
      height: '19px',
      width: '19px',
      margin: '0 8px'
    },
    Drop: {
      height: '19px',
      width: '19px',
      marginRight: '8px'
    }
  },
  IconButton: () => ({
    backgroundColor: '#fdeeee',
    width: '36px',
    height:'36px',
    marginLeft: '8px',
    borderRadius: '50%',
    '& .MuiSvgIcon-root': {
      zIndex: -1,
    },
    '&.MuiButtonBase-root': {
      zIndex: 0,
    },
  }),
  Input: () => ({
    display: 'none'
  }),
  PseudoButton: ({ palette }) => ({
    '&.MuiButton-root:hover': {
      backgroundColor: palette.secondary.main
    },
    color: palette.common.black,
    backgroundColor: palette.secondary.main,
    width: '264px',
    height: '50px',
    padding: 0,
    fontSize: '14px',
  }),
  UploadButtonTypography: () => ({
    fontSize: '14px',
    marginRight: '10px'
  }),
  FileLoadedLink: {
    position: 'relative',
    textDecoration: 'none',
    zIndex: 0,
  }
};

export default styles;
