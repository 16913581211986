const styles = {
  Skip: {
    cursor: 'pointer',
    position: 'absolute',
    right: '24px',
  },
  Image: ({ breakpoints }) => ({
    alignSelf: 'center',
    objectFit: 'contain',
    [breakpoints.up('xs')]: {
      height: '200px',
    },
    [breakpoints.up('sm')]: {
      height: '300px',
    },
    [breakpoints.up('md')]: {
      height: '400px',
    },
  }),
  Steps: {
    position: 'relative',
    width: '100%',
    '.MuiMobileStepper-dots': {
      alignItems: 'center',
      minWidth: '90px',
      justifyContent: 'space-between',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
    },
    '.MuiMobileStepper-dot': {
      height: '10px',
      width: '10px',
    },
    '.MuiMobileStepper-dotActive': {
      height: '14px',
      width: '14px',
    },
  },
  ButtonCTA: ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      display: 'none',
    }
  }),
  NavButton:  ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      borderRadius: '50%',
      minWidth: '20px',
      width: '20px',
      height: '20px',
      position: 'relative',
      padding: '20px',
    }
  }),
  ArrowBackIcon: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      height: '20px',
    },
    [breakpoints.up('sm')]: {
      marginRight: '5px',
    },
  }),
  ArrowForwardIcon: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      height: '20px',
    },
    [breakpoints.up('sm')]: {
      marginLeft: '5px',
    },
  }),
};

export default styles;
