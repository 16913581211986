import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonCrdNumber() {
  const { key: personKey } = useParams();
  
  const [ crdNumber, setCrdNumber ] = useRecoilState(fetchKeyPersonDetails('crdNumber'));
  const savingState = useAutoSave(KEY_PERSON, 'crdNumber', crdNumber, personKey);
  const onChangeCrdNumber = useCallback(e => setCrdNumber(e.target.value), [setCrdNumber]);

  if (typeof crdNumber === 'undefined') return null;

  return (
    <TextField 
      value={crdNumber || ''}
      onChange={onChangeCrdNumber}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonCrdNumber;
