import { fetchKeyPeople, keyPersonState } from '../../_state/application-form';
import { fetchRequest, useFetchWrapper } from '../../_helpers';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { KEY_PERSON } from '../../static-info/listing-application-forms';
import { getAPI_URL } from './_api-urls';

export function useGetKeyPerson(listingKey='', offerKey='', personKey=''){
  const setKeyPerson = useSetRecoilState(keyPersonState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}
    setLoaded(false);

    const url = getAPI_URL(KEY_PERSON, listingKey, offerKey, personKey);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setKeyPerson(response.keyPerson);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personKey, listingKey, offerKey]);

  return { loaded };
}

export function useSaveKeyPerson(listingKey='', offerKey='') {
  const [keyPerson, setKeyPerson] = useRecoilState(keyPersonState);
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    save: () => {
      setLoading(true);
      
      const url = getAPI_URL(KEY_PERSON, listingKey, offerKey, keyPerson);
      return fetchWrapper.patch(url)
        .then((response) => {
          setKeyPerson(response.keyPerson);
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  };
}

export function useCreateKeyPerson(listingKey='', offerKey='') {
  const setKeyPerson = useSetRecoilState(keyPersonState);
  const setKeyPeople = useSetRecoilState(fetchKeyPeople('keyPeople'));
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    create: () => {
      setLoading(true);
      const url = getAPI_URL(KEY_PERSON, listingKey, offerKey);
      return fetchWrapper.post(url)
        .then((response) => {
          if (!response) return;
          setKeyPerson(response.keyPerson);
          setKeyPeople((people) => [ ...people, response.keyPerson ]);
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
}

export async function deletePerson(listingKey='', offerKey='', personKey='') {
  const url = getAPI_URL(KEY_PERSON, listingKey, offerKey, personKey);
  const response = await fetchRequest('delete', url, null)
    .catch((error) => error);
  return response;
}

export async function postMergeKeyPersonAndEntity(listingKey='', offerKey='', personKey='', userKey='') {
  const response = await fetchRequest(
    'post',
    `${process.env.REACT_APP_API_HOST}/v2/listings/${listingKey}/offers/${offerKey}/forms/key-person/${personKey}/merge/${userKey}`,
    null
  ).catch((error) => error);
  return response;
}