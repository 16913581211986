import Question, { Answer } from '../../Question';

import FacebookIcon from '@mui/icons-material/Facebook';
import { SOCIAL_MEDIA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchSocialMedia } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function FacebookURL() {
  const [facebookUrl, setFacebookUrl] = useRecoilState(fetchSocialMedia('facebook'));
  const savingState = useAutoSave(SOCIAL_MEDIA, 'facebook', facebookUrl);
  const handleChange = useCallback(e => setFacebookUrl(e.target.value), [setFacebookUrl]);

  if (typeof facebookUrl === 'undefined') return null;

  return (
    <Question>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        What is the address of your company’s Facebook account? (optional)
      </Typography>
      <Answer>
        <TextField
          placeholder={'https://facebook.com'}
          icon={<FacebookIcon />}
          value={facebookUrl}
          onChange={handleChange}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default FacebookURL;
