import { Box, Link } from '@mui/material';
import { Cell, EditField, Row } from '../../../../netcapital-components/Table';
import { useCallback, useState } from 'react';

import ModalInput from '../../../../netcapital-components/ModalInput';
import NumberInput from '../../../../netcapital-components/NumberInput';
import PropTypes from 'prop-types';
import styles from '../styles';

export default function WarrantsOptionsOtherRightsRow({ index, onChange, onRemove, security }){
  const [openTerms, setOpenTerms] = useState(false);
  const toggleTerms = useCallback(() => setOpenTerms(!openTerms), [openTerms, setOpenTerms]);
  const handleRemoveOptionSecurityRow = useCallback(() => onRemove(index), [index, onRemove]);
  const handleChangeOptionSecurityType = useCallback(e => onChange(index, {optionType: e.target.value}), [index, onChange]);
  const handleChangeOptionSecurityNum = useCallback(value => onChange(index, {optionNum: value}), [index, onChange]);
  const handleChangeOptionSecurityDescription = useCallback(value => onChange(index, {optionDescription: value}), [index, onChange]);

  return (
    <Row onClickRemove={handleRemoveOptionSecurityRow}>
      <Cell>
        <Box sx={styles.OptionSecurityTableSizes.OptionType}>
          <EditField 
            value={security.optionType} 
            type='text' 
            placeholder='Warrant, Options, etc.' 
            onChange={handleChangeOptionSecurityType}
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.OptionSecurityTableSizes.OptionNum}>
          <NumberInput
            value={security.optionNum}
            onChange={handleChangeOptionSecurityNum}
            placeholder='0'
            variant='standard'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.OptionSecurityTableSizes.OptionDescription}>
          <Link 
            color='primary.main'
            fontWeight='fontWeightMedium'
            onClick={toggleTerms} 
            sx={styles.HyperLink}
          >
            {security.optionDescription === '' ? '+ Add Terms' : 'View Terms'}
          </Link>
          <ModalInput
            onClose={toggleTerms}
            open={openTerms}
            value={security.optionDescription || ''}
            onChange={handleChangeOptionSecurityDescription}
            title='Terms:'
          />
        </Box>
      </Cell>
    </Row>
  );
}

WarrantsOptionsOtherRightsRow.defaultProps = {
  optionDescription: '',
  optionNum: 0,
  optionType: '',
};

WarrantsOptionsOtherRightsRow.propTypes = {
  optionDescription: PropTypes.string,
  optionNum: PropTypes.number,
  optionType: PropTypes.string,
};