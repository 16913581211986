import { Cell, Header } from '../../../../netcapital-components/Table';

import { HELP } from '../../../../static-info/index';
import Help from '../../../../netcapital-components/Help';
import { Typography } from '@mui/material';

function PreviousFundingHeader(){
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Date of Offering</Typography>
      </Cell>
      <Cell>
        <Help key={1} explanation={HELP['Applicable SEC Exemption']}>Applicable SEC Exemption</Help>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Securities Offered</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Amount Sold</Typography>
      </Cell>
      <Cell>
        <Help key={2} explanation={HELP['Use of Proceeds']}>Use of Proceeds</Help>
      </Cell>
    </Header>
  );
}

export default PreviousFundingHeader;