import MUIRadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import RadioButton from '../RadioButton';
import React from 'react';

const radioGroup = ({value, options, onChange, disabled}) => {
  return (    
    <MUIRadioGroup
      row
      value={value}
      onChange={onChange}>
      {options.map((radio) => {
        return <RadioButton key={radio.id} disabled={disabled} value={radio.value} label={radio.label} />;
      })}
    </MUIRadioGroup>
  );
};

export const RadioGroup = React.memo(radioGroup);

RadioGroup.defaultProps = {
  value: 'initial',
  disabled: false
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number,
    value: PropTypes.oneOfType([ PropTypes.node, PropTypes.bool]),
    label: PropTypes.string
  })).isRequired,
  value: PropTypes.oneOfType([ PropTypes.node, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default RadioGroup;