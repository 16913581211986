import { fetchKeyPeople, fetchKeyPersonDetails } from '../../../_state/application-form';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Grid } from '@mui/material';
import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import _ from 'lodash';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useParams } from 'react-router-dom';

export default function KeyPersonName() {
  const { key: personKey } = useParams();
  const keyPeople = useRecoilValue(fetchKeyPeople('keyPeople'));
  const firstName = useRecoilValue(fetchKeyPersonDetails('firstName'));
  const savingState_firstName = useAutoSave(KEY_PERSON, 'firstName', firstName, personKey);
  const onChangeFirstname = useRecoilCallback(({set}) => (e) => {
    set(fetchKeyPersonDetails('firstName'), e.target.value);
    const keyPersonIndex = _.findIndex(keyPeople, { key: personKey });
    const updatedPeople = produce(keyPeople, (draft) => {
      draft[keyPersonIndex].firstName = e.target.value;
    });
    set(fetchKeyPeople('keyPeople'), updatedPeople);
  });

  const lastName = useRecoilValue(fetchKeyPersonDetails('lastName'));
  const savingState_lastName = useAutoSave(KEY_PERSON, 'lastName', lastName, personKey);
  const onChangeLastname = useRecoilCallback(({set}) => (e) => {
    set(fetchKeyPersonDetails('lastName'), e.target.value);
    const keyPersonIndex = _.findIndex(keyPeople, { key: personKey });
    const updatedPeople = produce(keyPeople, (draft) => {
      draft[keyPersonIndex].lastName = e.target.value;
    });
    set(fetchKeyPeople('keyPeople'), updatedPeople);
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          value={firstName}
          onChange={onChangeFirstname}
          placeholder="First"
          fullWidth
          error={savingState_firstName && savingState_firstName.state === 'error'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={lastName}
          onChange={onChangeLastname}
          placeholder="Last"
          fullWidth
          error={savingState_lastName && savingState_lastName.state === 'error'}
        />
      </Grid>
    </Grid>
  );
}
