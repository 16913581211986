import MUIButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

const outlinedButton = (props) => {
  const { children, color } = props;
  return (
    <MUIButton variant="outlined" color={color} {...props}>{children}</MUIButton>
  );
};

export const OutlinedButton = React.memo(outlinedButton);

OutlinedButton.defaultProps = {
  children: 'initial',
  color: 'secondary'
};

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info', 'warning', 'grey'])
};

export default OutlinedButton;
