import { SIDEBAR_WIDTH } from '../Header/styles';

const styles = {
  AppContainer: {
    minHeight: '100vh',
  },
  LayoutContainer: {
    minHeight: '100vh',
  },
  MainContentContainer: {
    flexGrow: 1,
  },
  MainContent: ({ breakpoints }) => ({
    paddingBottom: '1rem',
    width: '100%',
    [breakpoints.up('md')]: {
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
      marginLeft: `${SIDEBAR_WIDTH}px`,
      paddingTop: '1rem',
    },
  }),
};

export default styles;
