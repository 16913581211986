import { Box, Link, Typography } from '@mui/material';

import styles from './styles';

export default function NoPermissionsPage(){
  return (
    <Box>
      <Box sx={styles.ErrorHeader}>
        <Typography variant='h1' sx={styles.ErrorTitle}>403: You need permission.</Typography>
      </Box>
      <Box sx={styles.ErrorBody}>
        <Typography variant='body1'>We received a request that your account is not allowed to make. Are you logged in to the right account? Are you sure you should have access? If you’re supposed to have access, <Link target='_blank' rel='noopener noreferrer' href='mailto:help@netcapital.com'>send us an email</Link>. Otherwise, <Link href={`${process.env.REACT_APP_WEB_HOST}/dashboard`} target='_blank' rel='noopener noreferrer' sx={styles.RedirectLink}>go back to your dashboard.</Link></Typography>
      </Box>  
    </Box>
  );
}