import { atom, selectorFamily } from 'recoil';

const financialReportState = atom({
  key: 'FinancialReport',
  default: {}
});

const fetchFinancialReport = selectorFamily({
  key: 'fetchFinancialReport',
  get: field => ({get}) => get(financialReportState)[field],
  set: field => ({set}, newValue) =>
    set(financialReportState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchFinancialReport,
  financialReportState,
};
