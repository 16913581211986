import { Cell, Header } from '../../../../netcapital-components/Table';

import { Typography } from '@mui/material';

function CreditorsHeader(){
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Creditor(s)
        </Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Outstanding Balance (in USD)
        </Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Interest Rate
        </Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Maturity Date
        </Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Material Terms
        </Typography>
      </Cell>
    </Header>
  );
}

export default CreditorsHeader;