import { Box, Typography } from '@mui/material';
import { Cell, Row } from '../../../../netcapital-components/Table';
import { NETCAPITAL_FEE, TOTAL_MIN_ALLOCATION, calculateNetcapitalFee } from './utils';

import { NumberFormatCents } from '../../../../netcapital-components/NumberFormat';
import PropTypes from 'prop-types';
import styles from '../styles';

const netcapitalFeeFormatted = `${NETCAPITAL_FEE * 100}%`;

export default function NetcapitalFeeRow({ isSideBySideOffering, raiseAmountMax, raiseAmount4a6Max }){
  const amount4a6MaxFeeCellJsx = (
    <Cell>
      <Box sx={styles.CellBoxNested}>
        <Typography variant='body1' sx={styles.CellSpan}>
          <NumberFormatCents value={calculateNetcapitalFee(raiseAmount4a6Max)} displayType={'text'} />
        </Typography>
        <Typography variant='body1' sx={styles.CellSpan}>
          {netcapitalFeeFormatted}
        </Typography>
      </Box>
    </Cell>
  );

  return (
    <Row onClickRemove={null}>
      <Cell>
        <Typography variant='body1' sx={[styles.CellSpan, styles.UseOfProceedsTableSizes.Proceed]}>
          Netcapital Fee ({netcapitalFeeFormatted})
        </Typography>
      </Cell>
      <Cell>
        <Box sx={[styles.CellBoxNested, styles.NotEditable]}>
          <Typography variant='body1' sx={styles.CellSpan}>
            <NumberFormatCents value={calculateNetcapitalFee(TOTAL_MIN_ALLOCATION)} displayType={'text'} />
          </Typography>
          <Typography variant='body1' sx={styles.CellSpan}>
            {netcapitalFeeFormatted}
          </Typography>
        </Box>
      </Cell>
      {isSideBySideOffering ? amount4a6MaxFeeCellJsx : null}
      <Cell>
        <Box sx={[styles.CellBoxNested, styles.NotEditable]}>
          <Typography variant='body1' sx={styles.CellSpan}>
            <NumberFormatCents value={calculateNetcapitalFee(raiseAmountMax)} displayType={'text'} />
          </Typography>
          <Typography variant='body1' sx={styles.CellSpan}>
            {netcapitalFeeFormatted}
          </Typography>
        </Box>
      </Cell>
    </Row>
  );
}

NetcapitalFeeRow.defaultProps = {
  isSideBySideOffering: false,
  raiseAmountMax: 0,
  raiseAmount4a6Max: 0
};

NetcapitalFeeRow.propTypes = {
  isSideBySideOffering: PropTypes.bool,
  raiseAmountMax: PropTypes.number,
  raiseAmount4a6Max: PropTypes.number
};