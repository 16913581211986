const styles = {
  DialogOption: () => ({
    display: 'flex',
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
  }),
  DialogEditOption: ({ palette }) => ({
    color: palette.primary.main,
    textDecorationColor: palette.primary.main,
  }),
  DialogSaveOption: ({ palette }) => ({
    color: palette.success.main,
    textDecorationColor: palette.success.main,
  }),
  DialogIcon: () => ({
    width: '14px',
    marginRight: '5px'
  }),
  DialogContent: () => ({
    width: '100%',
    padding: '40px 50px'
  }),
  DialogIconButton: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  DialogCloseIcon: {
    height: '20px',
    width: '20px'
  },
};

export default styles;