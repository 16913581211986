import { Cell, Header } from '../../../../netcapital-components/Table';

import { Typography } from '@mui/material';

function PreviousNamesHeader(){
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Company Name</Typography>
      </Cell>
    </Header>
  );
}

export default PreviousNamesHeader;