import {
  Alert,
  Container,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  BANNER_DISPLAYS,
  generateBannerDescription,
  generateInvestorsTableColumns,
  generateQandAModalContent
} from './utils';
import { fetchInvestors, investorStatsState } from '../../_state/offer';
import { useCallback, useState } from 'react';
import { useGetInvestorStats, useGetInvestors } from '../../_actions/offer';
import { useParams, useSearchParams } from 'react-router-dom';

import DataGrid from '../../netcapital-components/DataGrid';
import { Dialog } from '../../netcapital-components/Dialog';
import DialogBar from '../../layouts/DialogBar';
import Filters from './filters';
import InvestorStats from './stats';
import Modal from '../../netcapital-components/Modal';
import styles from './styles';
import theme from '../../theme';
import { useRecoilValue } from 'recoil';

const Investors = () => {
  const { offerKey } = useParams();
  const isSmallerThanTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    investor: searchParams.get('investor') || '',
    orderby: searchParams.get('orderby') || '',
    page: parseInt(searchParams.get('page'), 10) || 1,
  });
  const { loaded: loadedInvestors } = useGetInvestors(offerKey, query);
  const { loaded: loadedInvestorStats } = useGetInvestorStats(offerKey);
  const investors = useRecoilValue(fetchInvestors('data'));
  const metadata = useRecoilValue(fetchInvestors('metadata'));
  const investorStats = useRecoilValue(investorStatsState);
  const offerStatus = investorStats.askOfferStatus;
  const offerStartDate = investorStats.askOfferStartDate;
  const offerEndDate = investorStats.askOfferEndDate;
  const [isQandAModalOpen, setIsQandAModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const modalContent = generateQandAModalContent(investors, selectedIndex);
  
  const handleChangeSearchByInvestor = useCallback((e) => {
    // enforce page 1 when changing any filter
    setQuery({...query, investor: e.target.value, page: 1});
    setSearchParams({...query, investor: e.target.value, page: 1});
  },[query, setQuery, setSearchParams]);

  const handleChangeSortByInvestorData = useCallback((e) => {
    // enforce page 1 when changing any filter
    setQuery({...query, orderby: e.target.value, page: 1});
    setSearchParams({...query, orderby: e.target.value, page: 1});
  },[query, setQuery, setSearchParams]);

  const handleTablePagination = useCallback((paginationModel) => {
    setQuery({...query, page: paginationModel.page + 1});
    setSearchParams({...query, page: paginationModel.page + 1});
  },[query, setQuery, setSearchParams]);

  const handleToggleQandADisplayModal = useCallback((e, rowId) => {
    if(rowId){
      const investorRowIndex = investors.findIndex(investor => investor._key === rowId);
      setSelectedIndex(investorRowIndex);
    } 
    if(!rowId){
      //reset selected index when closing modal - handleToggleQandADisplayModal is called in 3 contexts, when clicking the 'X' IconButton in the top right corner of the modal to close the modal, clicking the primary btn at the bottom of the modal to close the modal, and when clicking on the InsertCommentOutlined IconButton inside of an investor row to open the modal (only at this time do we pass the rowId as an argument to this function, the other 2 times, rowId is undefined)
      setSelectedIndex();
    } 
    setIsQandAModalOpen(!isQandAModalOpen);
  },[investors, isQandAModalOpen]);

  return (
    <Dialog fullScreen open>
      <DialogBar />
      <Container maxWidth='1920' sx={styles.Container}>
        <Stack spacing={4}>
          {loadedInvestorStats && (
            <Alert 
              icon={BANNER_DISPLAYS[offerStatus]?.icon}
              severity={BANNER_DISPLAYS[offerStatus]?.severity}
              sx={styles.Alert}
            >
              {generateBannerDescription(offerStatus, offerStartDate, offerEndDate)}
            </Alert>
          )}
          <Typography variant='h1'>Investors</Typography>
          <InvestorStats loaded={loadedInvestorStats} stats={investorStats} />
          <Filters 
            investorName={query.investor}
            sortBy={query.orderby}
            handleChangeSearch={handleChangeSearchByInvestor} 
            handleChangeSortBy={handleChangeSortByInvestorData}
          />
          <DataGrid 
            columns={generateInvestorsTableColumns(isSmallerThanTablet, handleToggleQandADisplayModal)}
            currentPage={query.page}
            getRowId={(row) => row._key}
            handlePaginate={handleTablePagination}
            isLoading={!loadedInvestors} 
            pageSizeOptions={[25]}
            resultsPerPage={metadata?.limit || 25}
            rows={investors || []}
            totalResults={metadata?.totalCount}
          />
        </Stack>
        <Modal
          handlePrimaryAction={handleToggleQandADisplayModal}
          open={isQandAModalOpen} 
          onClose={handleToggleQandADisplayModal} 
          primaryBtnTitle='Close'
        >
          {modalContent}
        </Modal>
      </Container>
    </Dialog>
  );
};

export default Investors;