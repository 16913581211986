import PropTypes from 'prop-types';
import React from 'react';
import Row from './row';
import TableBody from '@mui/material/TableBody';

const component = ({ children }) => {
  return (
    <TableBody name='Netcapital-Table-Body'>
      {children}
    </TableBody>
  );
};

export const Body = React.memo(component);

Body.defaultProps = {
  children: Row
};

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;