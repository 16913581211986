import { DateTime } from 'luxon';

const format = (dateStr, pattern=DateTime.DATE_SHORT) => {
  return DateTime.fromISO(dateStr).toLocaleString(pattern);
};

const formatEst = (dateStr) => {
  return DateTime.fromISO(dateStr, { zone: 'America/New_York' }).toFormat('ff ZZZZ');
};

const short = (dateStr) => {
  return format(dateStr, DateTime.DATE_SHORT);
};

const abbreviated = (dateStr) => {
  return format(dateStr, DateTime.DATE_MED);
};

const full = (dateStr) => {
  return format(dateStr, DateTime.DATE_FULL);
};

const time = (dateStr) => {
  return format(dateStr, DateTime.TIME_SIMPLE);
};

const timeAndZone = (dateStr) => {
  return format(dateStr, {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'});
};

const datetime = (dateStr) => {
  return format(dateStr, DateTime.DATETIME_MED);
};

const datetimeShort = (dateStr) => {
  return format(dateStr, DateTime.DATETIME_SHORT);
};

const timeAgo = (dateStr) => {
  const timeRelative = DateTime.fromISO(dateStr).toRelative();
  return timeRelative === 'in 0 seconds' ? 'Just now' : timeRelative;
};

export {
  format,
  formatEst,
  short,
  abbreviated,
  full,
  time,
  timeAndZone,
  datetime,
  datetimeShort,
  timeAgo,
};
