import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const complianceState = atom({
  key: 'complianceState',
  default: {}
});

const fetchCompliance = selectorFamily({
  key: 'fetchCompliance',
  get: field => ({get}) => _.get(get(complianceState), field),
  set: field => ({set}, newValue) =>
    set(complianceState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchCompliance,
  complianceState,
};
