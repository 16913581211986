import palette from './palette';
import typography from './typography';

const components = {
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          color: palette.common.white,
          borderRadius: '6px',
          boxShadow: 'none',
          height: '50px',
          padding: '15px 35px',
          fontWeight: typography.button.fontWeight,
          fontSize: typography.button.fontSize,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          color: palette.common.black,
          borderRadius: '6px',
          boxShadow: 'none',
          height: '50px',
          padding: '15px 35px',
          fontWeight: typography.button.fontWeight,
          fontSize: typography.button.fontSize,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          borderRadius: '6px',
          height: '50px',
          padding: '15px 35px',
          fontWeight: typography.button.fontWeight,
          fontSize: typography.button.fontSize,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
        },
      },
      {
        props: { variant: 'text' },
        style: {
          borderRadius: '6px',
          height: '50px',
          padding: '15px 35px',
          fontWeight: typography.button.fontWeight,
          fontSize: typography.button.fontSize,
          display: 'flex',
          alignItems: 'center',
          textTransform: 'uppercase',
        },
      },
    ],
  },
  //To-do: Combine with MuiTextField overrides below ?
  MuiInputBase: {
    variants: [
      {
        props: { size: 'small' },
        style: {
          height: '30px'
        },
      },
    ],
  },
  MuiFab: {
    disableRipple: true,
    styleOverrides: {
      root: {
        boxShadow: 'none',
        height: '36px',
        width: '36px',
        padding: '0'
      },
    },
  },
  //To-do: Combine with MuiTextField overrides below. This seems to just be for variant === 'outlined' ?
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        }
      },
    },
  },
  //This applies to MUI TextField components, components that are derivatives of MUI TextField or components that utilize MUI TextField to render it's input (assuming the ownerState prop is passed and subVariant === 'admin').
  //*Includes*: Our custom TextField, components that use MUI's Autocomplete (SearchUser, AddressInput), our custom DateSelector (uses our custom TextField component, which is a direct extension of MUI's TextField, to render it's input), NumberInput, MoneyInput
  //*Excludes*: MUI Select (technically also a derivative of MUI TextField, but requires it's own style definition. See below.)
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        //Cannot define custom variants in theme for MuiTextField (has to be oneOf 'filled', 'outlined'[default], 'standard'). Therefore, we can create a subvariant via the ownerState prop to act as our 'admin' variant for admin-only fields.
        ...(ownerState.subVariant === 'admin' && {
          '& .MuiInputBase-root:not(:invalid) > fieldset': {
            borderColor: palette.warning.light
          },
          '& .MuiInputBase-root.Mui-error > fieldset': {
            borderColor: palette.error.main
          },
          '& .MuiInputBase-root:hover > fieldset': {
            borderColor: palette.warning.dark
          },
        }),
      }),
    }
  },
  MuiSelect: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        //Cannot define custom variants in theme for MuiSelect, as it's a derivative of MuiTextField. Therefore, we are also creating a subvariant definition here via the ownerState prop to act as our 'admin' variant for admin-only fields. The selectors required are slightly different than the ones in TextField above, hence the separate style definition.
        ...(ownerState.subVariant === 'admin' && {
          '&.MuiInputBase-root:not(:invalid) > fieldset': { 
            borderColor: palette.warning.light
          },
          '&.MuiInputBase-root.Mui-error > fieldset': {
            borderColor: palette.error.main
          },
          '&.MuiInputBase-root:hover > fieldset': {
            borderColor: palette.warning.dark
          },
        }),
      }),
    }
  },
};

export default components;
