import { agreementsState, fetchShares } from '../../../_state/application-form';
import { useRecoilState, useResetRecoilState } from 'recoil';

import RadioGroup from '../../../netcapital-components/RadioGroup';
import { SHARES } from '../../../static-info/listing-application-forms';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

function FinancialsReviewLevel({ readOnly }) {
  const [reviewLevel, setReviewLevel] = useRecoilState(fetchShares('reviewLevel'));
  const resetAgreements = useResetRecoilState(agreementsState);
  const savingState = useAutoSave(SHARES, 'reviewLevel', reviewLevel);
  const handleChangeReviewLevel = useCallback(e => {
    setReviewLevel(e.target.value);
    resetAgreements();
  },[resetAgreements, setReviewLevel]);

  return (
    <RadioGroup
      name='reviewLevel'
      disabled={readOnly}
      value={reviewLevel}
      onChange={handleChangeReviewLevel}
      options={[
        {id: 1, value: 'REVIEWED', label: 'Reviewed'},
        {id: 2, value: 'AUDITED', label: 'Audited'},
        {id: 3, value: 'SELF', label: 'Neither'},
      ]}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default FinancialsReviewLevel;
