import { styled } from '@mui/material';
import { styles } from './styles';

const ToolbarStyles = styled('div')(({ theme }) => theme.mixins.toolbar);

const FixedHeaderOffset = () => {
  return (
    <ToolbarStyles sx={styles.FixedHeaderOffset} name='FixedHeaderOffset' />
  );
};

export default FixedHeaderOffset;