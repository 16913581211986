import Question, { Answer, Description } from '../../../Question';
import Table, { Body } from '../../../../netcapital-components/Table';
import { fetchCapTable, fetchContactInfo, fetchSecurities } from '../../../../_state/application-form';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CAP_TABLE } from '../../../../static-info/listing-application-forms';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { HELP } from '../../../../static-info';
import Help from '../../../../netcapital-components/Help';
import Modal from '../../../../netcapital-components/Modal';
import PrincipalOwnersTableHeader from './header';
import PrincipalOwnersTableRow from './row';
import { Typography } from '@mui/material';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useGetSecurities } from '../../../../_actions/application-form';
import { v4 as uuidv4 } from 'uuid';

function PrincipalOwners() {
  const { listingKey, offerKey } = useParams();
  useGetSecurities(listingKey, offerKey);
  const [principalOwners, setPrincipalOwners] = useRecoilState(fetchCapTable('principalOwners'));
  const savingState = useAutoSave(CAP_TABLE, 'principalOwners', principalOwners);
  const equitySecurities = useRecoilValue(fetchSecurities('equitySecurities'));
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [blockingNewPrincipal, setBlockingNewPrincipal] = useState(false);
  const navigate = useNavigate();

  const closeBlockingPrincipal = useCallback(() => setBlockingNewPrincipal(false), []);

  const addPrincialOwner = useCallback(() => {
    if (equitySecurities.length === 0){
      setBlockingNewPrincipal(true);
      return;
    }

    closeBlockingPrincipal();

    const newOwner = {
      _key: uuidv4(),
      name: 'Holder name',
      securityType: equitySecurities[0]?.securityType,
      votingPower: 0,
      shares: 1,
    };
    setPrincipalOwners([...principalOwners, newOwner]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalOwners, equitySecurities]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToSecurities = useCallback(() => navigate(`/listings/${listingKey}/offers/${offerKey}/securities`), [listingKey, offerKey]);

  const handleChangePrincipalOwner = useCallback((row, newValue) => {
    const updatedValues = [...principalOwners];
    const entry = [...updatedValues][row];
    updatedValues[row] = {...entry, ...newValue};
    setPrincipalOwners([...updatedValues]);
  }, [principalOwners, setPrincipalOwners]);

  const handleRemoveRow = useCallback((index) =>{
    const updatedValues = [...principalOwners];
    updatedValues.splice(index, 1);
    setPrincipalOwners([...updatedValues]);
  }, [principalOwners, setPrincipalOwners]);

  const modalMessage = (
    <>
      <Typography variant='h3' color='warning.main'>Insufficient Information</Typography>
      <Typography variant='body1'>You are trying to add information about the use of principal owners before creating securities.</Typography>
      <Typography variant='body1'>Please fill out the <Typography variant='span' fontWeight='fontWeightMedium'>“List all classes of equities or securities in order of preference“</Typography> question in the Securities form first, and then return to this section.</Typography>
    </>
  );

  if (typeof principalOwners === 'undefined') return null;

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Provide the name and ownership level of each Principal Security Holder.
      </Typography>
      <Description>
        <Typography variant='body2'>
          This includes any person, as of the most recent practicable date, who is the <Help explanation={HELP['Beneficial Owner']}>beneficial owner</Help> of 20% or more of {doingBusinessAs || 'your company'}’s <Help explanation={HELP['Outstanding Voting Equity Securities']}>outstanding voting equity securities</Help>, calculated on the basis of <Help explanation={HELP['Voting Power']}>voting power</Help>. This information must be current as of a date that is no more than 120 days prior to the date of filing this offering with the SEC.
        </Typography>
      </Description>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='top'
      >
        <Modal 
          handlePrimaryAction={goToSecurities}
          handleSecondaryAction={closeBlockingPrincipal}
          hasSecondaryCta
          onClose={closeBlockingPrincipal}
          open={blockingNewPrincipal} 
          primaryBtnTitle='Go to Securities'
          secondaryBtnColor='primary'
          secondaryBtnTitle='Cancel'
        >
          {modalMessage}
        </Modal>
        <Table onNewRow={addPrincialOwner}>
          <PrincipalOwnersTableHeader />
          <Body>
            {principalOwners?.map((owner, index) => (
              <PrincipalOwnersTableRow
                onChange={handleChangePrincipalOwner}
                onRemoveRow={handleRemoveRow}
                data={owner}
                index={index}
                key={owner._key}
                error={savingState && savingState.state === 'error'}
              />
            ))}
          </Body>
        </Table>
      </Answer>
    </Question>
  );
}

export default PrincipalOwners;
