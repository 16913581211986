import Question, { Answer, Description } from '../../Question';

import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import Purpose from './Purpose';
import { Typography } from '@mui/material';
import UseOfProceedsTable from './useOfProceedsTable/index';
import { useGetUseOfProceeds } from '../../../_actions/application-form/use-of-proceeds.actions';
import { useParams } from 'react-router-dom';

function UseOfProceeds() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetUseOfProceeds(listingKey, offerKey);
  
  return (
    <Form title='Use of Proceeds' loaded={loaded}>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Provide a general statement describing how you’ll use the funds you raise.
        </Typography>
        <Description>
          <Typography variant='body2'>
            You should discuss each probable use and the factors your company may consider in allocating proceeds.
          </Typography>
        </Description>
        <Answer>
          <Purpose />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Provide a detailed breakdown of how you’ll allocate the funds you raise.
        </Typography>
        <Description>
          <Typography variant='body2'>
            Create additional rows for any big expenses like real estate, expensive equipment, repayment of an outstanding debt, etc.
          </Typography>
        </Description>
        <br />
        <Help explanation={HELP['Use of Proceeds Table Instructions']}>How do I complete this table?</Help>
        {loaded ? <UseOfProceedsTable /> : null}
      </Question>
    </Form>
  );
}

export default UseOfProceeds;
