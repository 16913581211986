import { useCallback, useState } from 'react';

import { Balloon } from './balloon';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';

export default function Explain({children, explanation}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(e => setAnchorEl(e.currentTarget), [setAnchorEl]);
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  
  return (
    <>
      <Link onClick={handleClick} color='primary.dark' sx={styles.Explain}>
        {children}
      </Link>
      <Balloon id="fp-explain" anchorEl={anchorEl} onClose={handleClose}>
        {explanation}
      </Balloon>
    </>
  );
}

Explain.propTypes = {
  children: PropTypes.node.isRequired,
  explanation: PropTypes.node.isRequired
};