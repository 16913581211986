// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { MenuItem } from '@mui/material';
import MenuLink from './MenuLink';
import styles from './styles';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

//To-do: Re-enable when implementing Issuer Dashboard
const menuLinks = [
  // {
  //   name: 'Dashboard',
  //   enabled: true,
  //   url: '/dashboard',
  //   icon: <DashboardOutlinedIcon />
  // },
];

const MainMenu = () => {
  const location = useLocation();
  const isItemSelected = useCallback(
    (urlPath) => location.pathname.startsWith(urlPath),
    [location]
  );

  return menuLinks.map((link) => {
    const styling = isItemSelected(link.url) ? [styles.MenuItemActive, styles.MenuItem] : styles.MenuItem;

    return (
      <MenuItem sx={link.enabled ? styling : styles.DisabledOption} key={link.name}>
        <MenuLink function={link.function} link={link} isSelected={isItemSelected(link.url)} />
      </MenuItem>
    );
  });
};

export default MainMenu;