import AddressInput from '../../../netcapital-components/AddressInput';
import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import _ from 'lodash';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonAddress() {
  const { key: personKey } = useParams();
  const [ address, setAddress ] = useRecoilState(fetchKeyPersonDetails('address'));
  const savingState = useAutoSave(KEY_PERSON, 'address', address, personKey);
  const handleChangeAddress = useCallback((attribute, newValue) => {
    const updatedAddress = produce(address, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setAddress(updatedAddress);
  }, [setAddress, address]);

  if (typeof address === 'undefined') return null;

  return (
    <AddressInput
      value={address}
      onChange={handleChangeAddress}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonAddress;
