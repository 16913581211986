import Question, { Answer } from '../../../Question';
import { fetchBoardAuthorization, fetchKeyPeople } from '../../../../_state/application-form';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import DateSelector from '../../../../netcapital-components/DateSelector';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';
import _ from 'lodash';
import styles from '../styles';
import { v4 as uuidv4 } from 'uuid';

function SignaturesBoard() {
  const keyPeople = useRecoilValue(fetchKeyPeople('keyPeople'));
  const directorsKeyPeople = keyPeople?.length
    ? keyPeople.filter((person) => person.hasDirectorHistory === true)
    : [];

  const [signatures, setSignatures] = useRecoilState(fetchBoardAuthorization('signatures'));
  const [directors, setDirectors] = useState(signatures);
  
  useEffect(() => {
    if (!keyPeople || keyPeople.length === 0) return;
    
    //To-do: API should return empty array instead of null for signatures.directors if no sigs?
    let directorSignaturesCopy = signatures.directors ? [...signatures.directors] : [];
    
    for(let i = 0; i < keyPeople.length; i++){
      const signaturesIndex = directorSignaturesCopy.findIndex(director => director.entityKey === keyPeople[i].key);
      //if director, and person does not exist in director signatures array, add signature "template"
      if(keyPeople[i].hasDirectorHistory && signaturesIndex === -1){
        directorSignaturesCopy.push({ entityKey: keyPeople[i].key, date: '', _key: uuidv4()});
      } 

      //if no longer a director, and person still exists in director signatures array, remove their signature
      else if(!keyPeople[i].hasDirectorHistory && signaturesIndex >= 0){
        directorSignaturesCopy = directorSignaturesCopy.filter((director) => director.entityKey !== keyPeople[i].key);
      }
    }

    setDirectors(directorSignaturesCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[keyPeople, signatures.directors]);

  const handleChangeDirectorSignatureDate = useCallback((personKey) => (newValue) => {
    const directorsCopy = [...directors];
    const index = _.findIndex(directorsCopy, {entityKey: personKey});

    const newSignature = { entityKey: personKey, date: newValue, _key: directorsCopy[index]._key };
    directorsCopy[index] = newSignature;

    setSignatures({ ...signatures, 'directors': directorsCopy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directors]);

  const getSignature = (person) => {
    const signature = _.find(signatures.directors, { entityKey: person.key });
    return signature;
  };

  return (  
    <>
      <Typography variant='h2' fontWeight='fontWeightMedium' sx={styles.Subtitle}>
        Members of the Board of Directors
      </Typography>
      {directorsKeyPeople.length === 0 && <Typography variant='body2' color='error'>No designated board members.</Typography>}
      {directorsKeyPeople.map((person) => (
        <Question key={person.key}>
          <Answer 
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='bottom'
            sx={styles.SignatureRow}
          >
            <Typography sx={styles.SignatureRowBoardLabel}>{`${person.firstName} ${person.lastName}`}</Typography>
            <DateSelector
              value={_.get(getSignature(person), 'date') || ''}
              onChange={handleChangeDirectorSignatureDate(person.key)}
              ownerState={{ subVariant: 'admin' }}
            />
          </Answer>
        </Question>
      ))}
    </>
  );
}

export default SignaturesBoard;