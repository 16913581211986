const styles = {
  AutoComplete: () => ({
    '& .MuiInputBase-root':{
      height: '48px',
      borderRadius: '6px 6px 6px 6px',
      padding: '0px 15px',
    }
  }),
};

export default styles;