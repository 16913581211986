import Question, { Answer } from '../../../Question';

import EquivalentLineItemsTable from './table';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../../_state/application-form';
import { useRecoilValue } from 'recoil';

function EquivalentLineItems(){
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        What are the values for the equivalent line items reported on {doingBusinessAs || 'your company'}’s most recently completed federal income tax return?
      </Typography>
      <Answer>
        <EquivalentLineItemsTable />
      </Answer>
    </Question>
  );
}

export default EquivalentLineItems;