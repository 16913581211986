import { Cell, EditField, Row } from '../../../../netcapital-components/Table';

import { InputAdornment } from '@mui/material';
import NumberInput from '../../../../netcapital-components/NumberInput';
import Selectbox from '../../../../netcapital-components/Selectbox';
import { fetchSecurities } from '../../../../_state/application-form';
import { useCallback } from 'react';
import { useGetSecurities } from '../../../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const mapEquitySecuritiesToSelectOptions = (equitySecurities) => {
  const options = [];
  if(!equitySecurities){
    return options;
  }
  
  equitySecurities.forEach((security) => {
    options.push({value: security.securityType, label: security.securityType});
  });

  return options;
};

function PrincipalOwnersTableRow({ data, index, onChange, onRemoveRow }) {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetSecurities(listingKey, offerKey);
  const equitySecurities = useRecoilValue(fetchSecurities('equitySecurities'));

  const handleRemoveRow = useCallback(() => onRemoveRow(index), [index, onRemoveRow]);
  const onChangeName = useCallback(e => onChange(index, {name: e.target.value}), [index, onChange]);
  const onChangeShares = useCallback(value => onChange(index, {shares: value}), [index, onChange]);
  const onChangeSecurityType = useCallback(e => onChange(index, {securityType: e.target.value}), [index, onChange]);
  const onChangeVotingPower = useCallback(e => onChange(index, {votingPower: Number(e.target.value)}), [index, onChange]);

  if (typeof equitySecurities === 'undefined' || !loaded) return null;

  return (
    <Row onClickRemove={handleRemoveRow}>
      <Cell>
        <EditField
          value={data.name}
          onChange={onChangeName}
          placeholder='Name'
        />
      </Cell>
      <Cell>
        <NumberInput
          value={data.shares}
          onChange={onChangeShares}
          placeholder='0'
          variant='standard'
        />
      </Cell>
      <Cell>
        <Selectbox
          onChange={onChangeSecurityType}
          options={mapEquitySecuritiesToSelectOptions(equitySecurities)}
          placeholder='Security Type'
          value={data?.securityType?.trim()}
        />
      </Cell>
      <Cell>
        <EditField
          value={data.votingPower || 0}
          inputProps={{type:'number', min:'0', step:'0.01'}}
          onChange={onChangeVotingPower}
          endAdornment={<InputAdornment position='end'>%</InputAdornment>}
        />
      </Cell>
    </Row>
  );
}

export default PrincipalOwnersTableRow;
