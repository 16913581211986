import { atom, selectorFamily } from 'recoil';

const investorsState = atom({
  key: 'investorsState',
  default: {},
});

const investorStatsState = atom({
  key: 'investorStatsState',
  default: {},
});

const fetchInvestors = selectorFamily({
  key: 'fetchInvestors',
  get: field => ({ get }) => get(investorsState)[field],
});

export {
  investorsState,
  investorStatsState,
  fetchInvestors,
};
