import { addKeysToListItems, preparePayload } from './data-modeling';

import Session from '../_session';
import axios from 'axios';
import { useAlertActions } from '../_actions/alert.actions';

function getRequestOptions(method, signal, body, isPublic, contentType){
  const requestOptions = {
    headers: {},
    method,
    withCredentials: true,
    signal,
  };

  if (!isPublic) {
    requestOptions.headers = { 'Authorization': `Bearer ${Session.token()}` };
    requestOptions.withCredentials = false;
  }

  if (body) {
    if (contentType) {
      requestOptions.headers['Content-Type'] = contentType;
      requestOptions.data = body;
    } else {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.data = preparePayload(body);
    }
  }

  return requestOptions;
}

function useFetchWrapper() {
  const alertActions = useAlertActions();
  
  function handleResponse(response) {
    return addKeysToListItems(response.data);
  }

  function handleError(error) {
    if (!error.response){
      return;
    }

    alertActions.error('An error occurred', error.response, error.message, error.request);
    throw error;
  }

  function request(method) {
    return async (url, body, signal, isPublic) => {
      if (!isPublic) {
        await Session.refreshToken();
      }
      const requestOptions = getRequestOptions(method, signal, body, isPublic);
      return axios(url, requestOptions).then(handleResponse).catch(handleError);
    };
  }

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE')
  };
}

async function fetchRequest(method, url, body, isPublic, contentType) {
  const controller = new AbortController();
  const signal = controller.signal;
  const requestOptions = getRequestOptions(method, signal, body, isPublic, contentType);
  if (!isPublic) {
    await Session.refreshToken();
  }
  const response = await axios(url, {...requestOptions});
  return response;
}

export { 
  useFetchWrapper,
  fetchRequest
};