import { NumberFormatCents } from '../NumberFormat';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';

const moneyInput = (props) => {
  const { onChange, value, isBoldFocus, alignRight, isNoBorder, ownerState, ...other } = props;
  const inputProps = { inputComponent: NumberFormatCents };

  if (props.variant === 'standard') {
    inputProps.disableUnderline = true;
  }

  return (
    <TextField
      name='MoneyInput'
      {...other}
      value={value}
      onChange={onChange}
      InputProps={inputProps}
      isBoldFocus={isBoldFocus}
      isNoBorder={isNoBorder}
      alignRight={alignRight}
      ownerState={ownerState}
    />
  );
};

export const MoneyInput = React.memo(moneyInput);

MoneyInput.defaultProps = {
  value: 0,
  isNoBorder: true,
  isBoldFocus: false,
  alignRight: false,
  ownerState: { subVariant: '' }
};

MoneyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  isNoBorder: PropTypes.bool,
  isBoldFocus: PropTypes.bool,
  alignRight: PropTypes.bool,
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  }),
};

export default MoneyInput;