import { Grid, InputAdornment, Slider, Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Selectbox from '../../netcapital-components/Selectbox';
import TextField from '../../netcapital-components/TextField';
import { sortByFilters } from './utils';
import styles from './styles';

export default function Filters({ query, onRangeChange, onSearchChange, onSortChange }) { 
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='flex-end' mt={2}>
      <Grid item xs={12} md={4} sx={styles.FilterGridItem}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchChange}
          placeholder='Search by Company Name'
          value={query.company}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={styles.FilterGridItem}>
        <Stack direction='row'>
          <Typography variant='body1' sx={styles.SortLabelBox}>Sort By</Typography>
          <Selectbox 
            onChange={onSortChange}
            options={sortByFilters}
            placeholder='Filter'
            value={query.orderby}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4} sx={styles.FilterGridItem}>
        <Typography variant='body1' sx={styles.RangeLabelText}>Current Progress Range:</Typography>
        <Slider
          color='primary'
          value={[query.minrange, query.maxrange]}
          onChange={onRangeChange}
          valueLabelDisplay='auto'
        />
        <Stack direction='row' justifyContent='space-between' sx={styles.RangeLabelNumbers}>
          <Typography variant='body1'>0</Typography>
          <Typography variant='body1'>100</Typography>
        </Stack>
      </Grid>
    </Grid> 
  );
}

Filters.defaultProps = {
  query: {
    minrange: 65,
    maxrange: 100,
    company: ''
  },
  onRangeChange: () => { return; },
  onSearchChange: () => { return; },
  onSortChange: () => { return; },
};

Filters.propTypes = {
  query: PropTypes.shape({
    minrange: PropTypes.number,
    maxrange: PropTypes.number,
    company: PropTypes.string,
  }),
  onRangeChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSortChange: PropTypes.func,
};