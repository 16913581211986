import Cell from './cell';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

const component = ({children}) => (
  <MUITableHead name='Netcapital-Table-Header'>
    <MUITableRow>{children}</MUITableRow>
  </MUITableHead>
);

export const Header = React.memo(component);

Header.defaultProps = {
  children: Cell
};

Header.propTypes = {
  children: PropTypes.node
};

export default Header;