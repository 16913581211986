import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const companyFormationState = atom({
  key: 'companyFormationState',
  default: {}
});

const fetchCompanyFormation = selectorFamily({
  key: 'fetchCompanyFormation',
  get: field => ({get}) => _.get(get(companyFormationState), field),
  set: field => ({set}, newValue) =>
    set(companyFormationState, prevState => ({...prevState, [field]: newValue})),
});

const fetchCompanyFormationFile = selectorFamily({
  key: 'fetchCompanyFormationFile',
  get: usage => ({get}) => {
    const files = get(companyFormationState).files || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(companyFormationState).files || [];

    if (!newFile) {
      set(companyFormationState, prevState => ({...prevState, files: files.filter(file => file.usage !== usage)}));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(companyFormationState, prevState => ({...prevState, files: updatedFiles}));
    }
  }
});

export {
  fetchCompanyFormation,
  fetchCompanyFormationFile,
  companyFormationState,
};
