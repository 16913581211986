const styles = {
  DateSelector: () => ({
    width: '165px',
  }),
  Checkbox: {
    height: '20px',
    width: '20px',
    padding: 0,
    marginRight: '7px'
  },
  CreditorTableSizes:{
    Name: {
      width: '182px'
    },
    Balance: {
      width: '182px'
    },
    Rate: {
      width: '147px'
    },
    MaturityDate: {
      width: '182px'
    },
    Terms:{
      width: '146px'
    }
  },
  HyperLink: ({ palette }) => ({
    '&.MuiLink-root':{
      cursor: 'pointer',
      '&:hover':{
        color: palette.primary.dark,
      },
    },
  }),
  OtherCrowdfundingNamesTextarea: {
    marginTop: '15px'
  },
  OfferingDateCellBox: {
    width: '200px'
  },
  ExemptionCellBox: {
    width: '250px'
  },
  SecurityTypeCellBox: {
    width: '200px'
  },
  previousFundingUseOfProceedsCellBox: {
    width: '200px'
  },
  AmountSold: {
    width: '150px'
  }
};

export default styles;