import Question, { Answer } from '../../Question';
import { fetchCompliance, fetchContactInfo } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { COMPLIANCE } from '../../../static-info/listing-application-forms';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { isAdminState } from '../../../_state/user';
import styles from './styles';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

function EdgarCodes() {
  const isAdmin = useRecoilValue(isAdminState);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  
  const [hasEDGARCodes, setHasEDGARCodes] = useRecoilState(fetchCompliance('hasEdgarCodes'));
  const savingState_hasEDGARCodes = useAutoSave(COMPLIANCE, 'hasEDGARCodes', hasEDGARCodes);
  const handleChangeHasEDGARCodes = useCallback((e) => {
    setHasEDGARCodes(e.target.value === 'true');
  },[setHasEDGARCodes]);

  const [CCC_NUMBER, setCCCNumber] = useRecoilState(fetchCompliance('ccc'));
  const savingState_CCC_NUMBER = useAutoSave(COMPLIANCE, 'ccc', CCC_NUMBER);
  const handleChangeCCCNumber = useCallback((e) => {
    setCCCNumber(e.target.value);
  },[setCCCNumber]);

  const [CIK_NUMBER, setCIKNumber] = useRecoilState(fetchCompliance('cik'));
  const savingState_CIK_NUMBER = useAutoSave(COMPLIANCE, 'cik', CIK_NUMBER);
  const handleChangeCIKNumber = useCallback((e) => {
    setCIKNumber(e.target.value);
  },[setCIKNumber]);

  const [SEC_FILE_NUMBER, setSECFileNumber] = useRecoilState(fetchCompliance('fileNumber'));
  const savingState_SEC_FILE_NUMBER = useAutoSave(COMPLIANCE, 'fileNumber', SEC_FILE_NUMBER);
  const handleChangeSECFileNumber = useCallback((e) => {
    setSECFileNumber(e.target.value);
  },[setSECFileNumber]); 
  
  return (
    <Question> 
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Does {doingBusinessAs || 'your company'} have a CIK number with the SEC? If you don’t know what this is, the answer is probably no.
      </Typography>
      <Answer>
        <RadioGroup
          name='CIK_Number'
          value={hasEDGARCodes}
          onChange={handleChangeHasEDGARCodes}
          options={RADIO_OPTIONS.YES_NO}
          error={savingState_hasEDGARCodes && savingState_hasEDGARCodes.state === 'error'}
        />
      </Answer>
      {hasEDGARCodes && 
        <>
          <Answer>
            <TextField 
              name='SEC_CIK' 
              value={CIK_NUMBER || ''} 
              onChange={handleChangeCIKNumber} 
              placeholder='SEC CIK' 
              error={savingState_CIK_NUMBER && savingState_CIK_NUMBER.state === 'error'}
              sx={styles.TextField}
            />
          </Answer>
          <Answer>
            <TextField 
              name='SEC_CCC' 
              value={CCC_NUMBER || ''} 
              onChange={handleChangeCCCNumber} 
              placeholder='SEC CCC' 
              error={savingState_CCC_NUMBER && savingState_CCC_NUMBER.state === 'error'}
              sx={styles.TextField}
            />
          </Answer>
        </>
      }
      {(hasEDGARCodes && isAdmin) && (
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField 
            name='SEC_FILE_NUMBER' 
            value={SEC_FILE_NUMBER || ''} 
            onChange={handleChangeSECFileNumber} 
            placeholder='SEC File Number' 
            sx={styles.TextField}
            ownerState={{ subVariant: 'admin' }}
            error={savingState_SEC_FILE_NUMBER && savingState_SEC_FILE_NUMBER.state === 'error'}
          />
        </Answer>
      )}
    </Question>
  );
}

export default EdgarCodes;