import { Box, Container, Stack } from '@mui/material';

import FixedHeaderOffset from '../Header/offset';
import Header from '../Header';
import Loading from '../../components/Loading';
import { Outlet } from 'react-router-dom';
import { appGlobalSettingsState } from '../../_state';
import { isAuthenticatedState } from '../../_state/user';
import styles from './styles';
import { useGetSession } from '../../_actions/session.actions';
import { useRecoilValue } from 'recoil';

export default function AppContainer() {
  const { loaded } = useGetSession();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);

  return (
    <Box sx={styles.AppContainer} name='AppContainer'>
      <Loading loading={!loaded} />
      <Stack sx={styles.LayoutContainer} name='LayoutContainer'>
        {loaded && isAuthenticated && (
          <>
            <Header />
            <Box sx={styles.MainContentContainer}>
              <Container component='main' maxWidth='xl' sx={styles.MainContent} name='MainContent'>
                {!isMobile && !isTablet && <FixedHeaderOffset />}
                <Outlet />
              </Container>
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
}