import { Divider, Grid, Stack, Typography } from '@mui/material';
import { agreementsState, fetchShares, raiseAmountState } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import CloseIcon from '@mui/icons-material/Close';
import MoneyInput from '../../../netcapital-components/MoneyInput';
import { NumberFormatCents } from '../../../netcapital-components/NumberFormat';
import NumberInput from '../../../netcapital-components/NumberInput';
import { SHARES } from '../../../static-info/listing-application-forms';
import styles from './styles';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

function SharesAndPricing({ doingBusinessAs, readOnly }) {
  const raiseAmount = useRecoilValue(raiseAmountState);
  const resetAgreements = useResetRecoilState(agreementsState);
  
  const [totalQuantity, setTotalQuantity] = useRecoilState(fetchShares('totalQuantity'));
  const savingState_totalQuantity = useAutoSave(SHARES, 'totalQuantity', totalQuantity);

  const handleChangeTotalQuantity = useCallback(value => {
    setTotalQuantity(value);
    resetAgreements();
  }, [resetAgreements, setTotalQuantity]);

  const [pricePerUnit, setPricePerUnit] = useRecoilState(fetchShares('pricePerUnit'));
  const savingState_pricePerUnit = useAutoSave(SHARES, 'pricePerUnit', pricePerUnit);
  
  const handleChangePricePerUnit = useCallback(value => {
    setPricePerUnit(value);
    resetAgreements();
  }, [resetAgreements, setPricePerUnit]);

  if (typeof totalQuantity === 'undefined') return null;

  return (
    <Grid container spacing={4} sx={styles.SharesTable}>
      <Grid item xs={7} sx={[styles.CellLabel, styles.Cell]}>
        <Typography variant='body2'>Shares of {doingBusinessAs || 'your company'}</Typography>
      </Grid>
      <Grid item xs={5}>
        <NumberInput
          value={totalQuantity}
          disabled={readOnly}
          onChange={handleChangeTotalQuantity}
          variant='standard'
          inputProps={{
            style: { fontWeight: 600, textAlign: 'right' }
          }}
          error={savingState_totalQuantity && savingState_totalQuantity.state === 'error'}
        />
      </Grid>
      <Grid item xs={7} sx={[styles.CellLabel, styles.Cell]}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='body2'>Price per share</Typography>
          <CloseIcon color='primary' sx={styles.MultiplyIcon} />
        </Stack>
      </Grid>
      <Grid item xs={5} sx={[styles.CellValue, styles.Cell]}>
        <MoneyInput
          value={pricePerUnit}
          disabled={readOnly}
          onChange={handleChangePricePerUnit}
          variant='standard'
          inputProps={{
            style: { textAlign: 'right' }
          }}
          isNoBorder
          error={savingState_pricePerUnit && savingState_pricePerUnit.state === 'error'}
        />
      </Grid>
      <Divider sx={styles.Divider} /> 
      <Grid item xs={7} sx={[styles.CellLabel, styles.Cell, styles.RaiseAmountRow]}>
        <Typography variant='body2'>Maximum possible amount</Typography>
      </Grid>
      <Grid item xs={5} sx={[styles.RaiseAmountRow, styles.RaiseAmountValue, styles.CellValue, styles.Cell]}>
        <Typography sx={styles.RaiseAmountValueInput}>
          <NumberFormatCents
            value={raiseAmount} 
            displayType='text'
          />
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SharesAndPricing;
