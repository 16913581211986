import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import styles from './styles';

const question = ({ children }) => {
  return (
    <Box name='Netcapital-Question' sx={styles.Boxes}>
      <Box sx={styles.Icon} />
      <Typography sx={styles.Question} component='div'>{children}</Typography>
    </Box>
  );
};

export const Question = React.memo(question);

Question.propTypes = {
  children: PropTypes.node.isRequired,
};

export * from './description';
export * from './answer';
export default Question;