import { forwardRef, useCallback } from 'react';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const valueTypeWeights = {
  decimal: 100,
  percentage: 1,
};

export const NumberFormatPercent = forwardRef((props, ref) => {
  const { onChange, value, valueSavedAs, ...other } = props;
  const valueWeight = valueTypeWeights[valueSavedAs];
    
  const unformatPercent = useCallback((values, sourceInfo) => {
    if (sourceInfo.event === null){
      return;
    }
    const changeValue = (values.value === '') ? null : values.floatValue / valueWeight;
    onChange(changeValue);
  }, [onChange, valueWeight]);

  const isAllowed = useCallback((values) => {
    const {floatValue} = values;
    return typeof floatValue === 'undefined' || floatValue <= 100;
  },[]);
  
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      isAllowed={isAllowed}
      value={value === null ? null : Number(value) * valueWeight}
      decimalScale={2}
      suffix={'%'}
      onValueChange={unformatPercent}
    />
  );
});

NumberFormatPercent.displayName = 'NumberFormatPercent';

NumberFormatPercent.defaultProps = {
  onChange: () => { return; },
  value: 0,
  valueSavedAs: 'decimal',
};

NumberFormatPercent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  valueSavedAs: PropTypes.oneOf(Object.keys(valueTypeWeights)),
};