const styles = {
  TextField: ({ breakpoints }) => ({
    '& .MuiInputBase-formControl': {
      borderRadius: '6px 6px 6px 6px',
    },
    '& .MuiOutlinedInput-input' : {
      [breakpoints.only('xs')]: {
        padding: '8px 8px',
      },
    }
  }),
  LoadingSection: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: '48px 24px 24px',
  },
  Header: ({ breakpoints}) => ({
    '&.MuiStack-root':{
      marginTop: '0.75rem',
      [breakpoints.up('lg')]: {
        marginTop: '-0.6rem',
      },
    },
  }),
  Title: ({ breakpoints }) => ({
    fontSize: '1.75rem',
    [breakpoints.up('lg')]: {
      fontSize: '3rem',
    },
  }),
  KeyPerson: () => ({
    paddingBottom: '1rem',
  }),
  DeleteButton: () => ({
    backgroundColor: '#fdeeee',
    '&:hover':{
      backgroundColor: '#fbdddd',
    },
  }),
  GoBackButton: () => ({
    backgroundColor: '#fdf7e4',
    '&:hover':{
      backgroundColor: '#fcf4db',
    },
  }),
  UserBox: ({ palette }) => ({
    display: 'flex', 
    flexDirection: 'column',
    padding: '10px',
    '&:hover': {
      backgroundColor: palette.grey[100],
      cursor: 'pointer',
    },
  }),
  UserEmail: {
    fontWeight: '600'
  },
  SuccessMergeMessageContainer: ({ palette }) => ({
    marginTop: '5px',
    borderRadius: '4px',
    border: `1px solid ${palette.primary.main}`,
    padding: '15px'
  })
};

export default styles;
