import { atom, selectorFamily } from 'recoil';

const usersState = atom({
  key: 'usersState',
  default: {}
});
  
const fetchUsers = selectorFamily({
  key: 'fetchUsers',
  get: field => ({ get }) => get(usersState)[field],
});
  
export {
  fetchUsers,
  usersState,
};