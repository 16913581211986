const styles = {
  Title: ({ breakpoints }) => ({
    display: 'none',
    [breakpoints.up('lg')]: {
      fontSize: '3rem',
      paddingBottom: '1rem',
      lineHeight: 0.7,
      display: 'block',
    },
  }),
  Content: ({ breakpoints }) => ({
    [breakpoints.down('lg')]: {
      marginBottom: '150px',
    }
  }),
  Navigation: () => ({
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 1100,
    height: '70px'
  }),
  NavigationFullHeight: () => ({
    height: '135px'
  }),
  Container: () => ({
    position: 'absolute',
    bottom: '10px',
  }),
  ButtonStack: ({ breakpoints }) => ({
    '& a': {
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },
    [breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  }),
  PrevButton: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      '&.MuiButtonBase-root': {
        width: '100%',
      },
      padding: '4px 8px 3px 4px',
      fontSize: '14px'
    },
    [breakpoints.up('sm')]: {
      padding: '8px 16px 6px 8px'
    }
  }),
  NextButton: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      '&.MuiButtonBase-root': {
        width: '100%',
      },
      padding: '4px 4px 3px 8px',
      fontSize: '14px'
    },
    [breakpoints.up('sm')]: {
      padding: '8px 8px 6px 16px'
    }
  }),
  ArrowBackIcon: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      height: '20px',
      marginRight: '5px'
    }
  }),
  ArrowForwardIcon: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      height: '20px',
      marginLeft: '5px'
    }
  }),
};

export default styles;
