const styles = {
  Label: {
    Default: {
      margin: 0
    },
    Unchecked: () => ({
      color: 'rgba(0, 18, 112, 0.5)'
    }),
  },
  Checkbox: ({ palette }) => ({
    color: palette.grey[300],
    '&.Mui-checked': {
      color: palette.success.main,
    },
  }),
};

export default styles;