import Cell from './cell';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import MUITableCell from '@mui/material/TableCell';
import MUITableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import styles from './styles';

const StyledTableRow = styled(MUITableRow)(styles.TableRow);
const component = ({children, onClickRemove, onClickEdit}) =>{
  return <StyledTableRow name='Netcapital-Table-Row'>
    {children}
    {onClickEdit && (
      <MUITableCell sx={styles.ActionCell}>
        <Fab color="warning" aria-label="edit" sx={[styles.Fab, styles.FabEdit]}>
          <EditOutlinedIcon color="warning" onClick={onClickEdit} />
        </Fab>
      </MUITableCell>
    )}
    {onClickRemove && (
      <MUITableCell sx={styles.ActionCell}>
        <Fab color="primary" aria-label="remove" sx={[styles.Fab, styles.FabDelete]}>
          <CloseIcon color="error" onClick={onClickRemove} />
        </Fab>
      </MUITableCell>
    )}
  </StyledTableRow>;
};

export const Row = React.memo(component);

Row.defaultProps = {
  children: Cell,
  onClickRemove: false,
  onClickEdit: false,
};

Row.propTypes = {
  children: PropTypes.node,
  onClickRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClickEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default Row;
