import Question, { Answer, Description } from '../../Question';
import { Stack, Typography } from '@mui/material';

import { DateTime } from 'luxon';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FinancialReportTable from './FinancialReportTable';
import Loading from '../../Loading';
import { fetchFinancialStatements } from '../../../_state/application-form';
import styled from '@emotion/styled';
import styles from './styles';
import { useGetFinancialStatements } from '../../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Title = styled(Typography)(styles.Title);

function FinancialReport() {
  const { listingKey, offerKey, key: financialReportKey } = useParams();
  const { loaded } = useGetFinancialStatements(listingKey, offerKey);
  const financialReports = useRecoilValue(fetchFinancialStatements('financialReports'));
  const financialReport = Array.isArray(financialReports) ? financialReports.find(report => report.key === financialReportKey) : false;
  
  if (typeof financialReports === 'undefined') return null;
  
  return (
    <Stack spacing={{ xs: 6, lg: 10 }} sx={styles.Section}>
      <Loading loading={!loaded} />
      <Stack spacing={{ xs: 6 }}>
        <Title variant='h1'>{financialReport?.dateEnd ? `Fiscal Year Ending ${DateTime.fromFormat(financialReport?.dateEnd, 'yyyy-MM-dd').toUTC().toLocaleString(DateTime.DATE_FULL)}` : 'Fiscal Year Ending'}</Title>
        <Question>
          Provide figures from the fiscal year ending {financialReport?.dateEnd ? DateTime.fromFormat(financialReport?.dateEnd, 'yyyy-MM-dd').toUTC().toLocaleString() : ''}
          <Description>Enter zero where it applies, and feel free to include negative numbers with a minus sign in front (i.e., -100).</Description>
          <Answer
            helperColor='warning.main'
            helperContent='Admin Only'
            helperIcon={<ErrorOutlineOutlinedIcon />}
            helperPosition='top'
          >
            <FinancialReportTable financialReportKey={financialReportKey} />
          </Answer>
        </Question>
      </Stack>
    </Stack>
  );
}

export default FinancialReport;