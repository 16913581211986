import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const progressState = atom({
  key: 'progressState',
  default: {}
});

const fetchProgress = selectorFamily({
  key: 'fetchProgress',
  get: field => ({get}) => _.get(get(progressState), field),
});

export {
  fetchProgress,
  progressState,
};