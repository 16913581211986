const styles = {
  Link: ({ palette }) => ({
    '&.MuiLink-root':{
      color: palette.primary.main,
      '&:hover':{
        color: palette.primary.dark,
      },
    },
  }),
};

export default styles;