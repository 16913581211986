import { fetchRequest, useFetchWrapper } from '../_helpers';
import { isAuthenticatedState, userRoleState } from '../_state/user';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Session from '../_session';

export function useGetSession(){
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [userRole, setUserRole] = useRecoilState(userRoleState);
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedState);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (userRole && Object.keys(Session.get('user') ?? {}).length > 0) {
      setLoaded(true);
      return abort;
    }

    fetchWrapper.get(`${process.env.REACT_APP_API_HOST}/v2/auth/verify`, null, signal, true)
      .then((response) => {
        if (!response) return;
        if (!response.authenticated){
          setIsAuthenticated(false);
          window.location.href = `${process.env.REACT_APP_API_HOST}/v2/auth/login?returnTo=${window.location.href}`;
        }
        setIsAuthenticated(true);
        setUserRole(response.user.role);
        Session.setFromResponse(response);
        setLoaded(true);
      });
    
    return abort;
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    
  return { loaded };
}

export async function exitMimicMode(){
  const response = await fetchRequest(
    'delete',
    `${process.env.REACT_APP_API_HOST}/v2/auth/mimic`,
    null,
    true
  ).catch((error) => error);

  return response;
}