import { Cell, Header } from '../../../../netcapital-components/Table';

import { HELP } from '../../../../static-info';
import Help from '../../../../netcapital-components/Help';
import { Typography } from '@mui/material';

export default function EquitySecuritiesTableHeader(){
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Type of Security</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Securities Reserved</Typography>
      </Cell>
      <Cell>
        <Help explanation={HELP['Securities Other Terms']}>Other Terms</Help>
      </Cell>
    </Header>
  );
}