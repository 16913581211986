import Question, { Answer } from '../../Question';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import NumberInput from '../../../netcapital-components/NumberInput';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function NumberOfEmployees() {
  const [numberOfEmployees, setNumberOfEmployees] = useRecoilState(fetchContactInfo('numberOfEmployees'));
  const savingState = useAutoSave(CONTACT_INFO, 'numberOfEmployees', numberOfEmployees);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
    
  const handleChange = useCallback((value) => setNumberOfEmployees(value), [setNumberOfEmployees]);
  
  if (typeof numberOfEmployees === 'undefined') return null;
    
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>How many <Help explanation={HELP['Employees']}>employees</Help> does {doingBusinessAs || 'your company'} have?</Typography>
      <Answer>
        <NumberInput 
          value={numberOfEmployees} 
          onChange={handleChange} 
          name='numberOfEmployees' 
          placeholder='0'
          error={savingState && savingState.state === 'error'}
          isBoldFocus={false}
        />
      </Answer>
    </Question>
  );
}