import { Link } from '@mui/material';

const HELP = {
  'Amount Authorized': <>
    Securities authorized is the maximum number of shares that your company’s board has legally permitted the company to issue at this time. This can usually be found in the capital account of the balance sheet.
  </>,
  'Amount of Interest': <>
    Compute the amount of a related party’s interest in any transaction without regard to the amount of the profit or loss involved in the transaction. Where it is not practicable to state the approximate amount of the interest, disclose the approximate amount involved in the transaction.
  </>,
  'Amount Outstanding': <>
    Securities outstanding is the number of shares that the business has actually issued and are held by any individual or entity.
  </>,
  'Applicable SEC Exemption': <>
    To offer securities for sale, your company must either register them with the SEC (uncommon for most companies that can conduct crowdfunding) or rely on a particular exemption. If you&apos;re not sure which exemption your company relied upon, and it’s not mentioned in the deal documents, you should consult your attorney.
  </>,
  'Beneficial Owner': <>
    If the person has the right to acquire voting power of such securities within 60 days, including through the exercise of any option, warrant or right, the conversion of a security, or other arrangement, or if securities are held by a family member, through corporations or partnerships, or otherwise in a manner that would allow a person to direct or control the voting of the securities (or share in such direction or control—as, for example, a co-trustee) they should be included as a <b>Beneficial Owner</b>. You should include an explanation of these circumstances.
  </>,
  'Beneficial Ownership Key People': <>
    <b>Beneficial ownership</b> for purposes of paragraph (2) shall be determined as of a date that is no more than 120 days prior to the date of filing of this offering statement.
  </>,
  'Employees': <>
    A common-law <b>employee</b> is anyone who performs services for you if you can control what they will do and how they will do it. Generally, you must withold some taxes for employees, and you would typically send your employees a W-2 form annually. In the early stages, your company may have no employees.
  </>,
  'Family Member': <>
    The term <b>“member of the family”</b> includes any child, stepchild, grandchild, parent, stepparent, grandparent, spouse or spousal equivalent, sibling, mother-in-law, father-in-law, son-in-law, daughter-in-law, brother-in-law, or sister-in-law of the person, and includes adoptive relationships. The term “spousal equivalent” means a cohabitant occupying a relationship generally equivalent to that of a spouse.
  </>,
  'Financial Condition': <>
    The financial condition of your company (and its predecessors) includes to the extent material: liquidity, capital resources, and historical results of operations, for each fiscal period your financial statements cover. 
    <br /><br /> 
    For companies without an operating history, focus on financial milestones as well as challenges that the business faces in operations, liquidity, etc. 
    <br /><br /> 
    For companies with an operating history, the discussion should focus on whether historical results and cash flows are representative of what investors should expect in the future. 
    <br /><br /> 
    Take into account the proceeds of the offering and any other known or pending sources of capital. Discuss how the proceeds from the offering will affect liquidity, whether receiving these funds and any other additional funds is necessary to the viability of the business, and how quickly your company anticipates using its available cash. Describe the other available sources of capital to your company, such as lines of credit or required contributions by shareholders.
  </>,
  'Funding Portal': <>
    A <b>funding portal</b> is any person or company acting as an intermediary in a transaction involving the offer or sale of securities pursuant to Securities Act Section 4(a)(6).
    <br /><br />
    Donation-based or reward-based crowdfunding platforms, such as Kickstarter, Indiegogo, or gofundme, are not considered funding portals as of June 25, 2016.
  </>,
  'Independent Accountant': <>
    To qualify as a public accountant that is independent of your company, the accountant must satisfy the independence standards of <i>either</i>:
    <ul>
      <li><Link href="http://www.ecfr.gov/cgi-bin/text-idx?SID=8e0ed509ccc65e983f9eca72ceb26753&node=17:3.0.1.1.8&rgn=div5#se17.3.210_12_601" target="_blank" rel="noopener">Rule 2-01 of Regulation S-X</Link></li>
      <li><Link href="https://www.aicpa.org/InterestAreas/CenterForAuditQuality/Resources/CAQAuditLibrary/Pages/Ethics%20and%20Independence.aspx" target="_blank" rel="noopener">The American Institute of Certified Public Accountants</Link></li>
    </ul>
    and the accountant must be <i>either</i>:
    <ul>
      <li>duly registered and in good standing as a certified public accountant</li>
      <li>in good standing and entitled to practice as a public accountant under the laws of their place of residence or principal office</li>
    </ul>
  </>,
  'Officer': <>
    An <b>officer</b> means a president, vice president, secretary, treasurer, principal financial officer, comptroller or principal accounting officer. If any individual regularly performs functions similar to the above positions, they are considered an officer of your company.
  </>,
  'Outstanding Voting Equity Securities':<>
    To calculate <b>outstanding voting equity securities</b>, assume all outstanding options are exercised and all outstanding convertible securities converted.
  </>,
  'Priority': <>
    Various classes of securities have different claims on a company’s assets and dividends. Security classes are priority ranked according to these claims.
    <br /><br />
    If a company’s assets are liquidated (during an event like bankruptcy) or the company fails to generate enough money to fulfill its dividend schedule requirements, security classes with higher claims are given priority over security classes with lesser claims.
    <br /><br />
    Typically, preferred stock is ranked in terms of priority (prior, first preference, second preference, third preference, etc). Preferred stock holds a higher priority than common stock.
  </>,
  'Promoter': <>
    The category of <b>“promoter”</b> is broad. It includes any person—an individual or legal entity—that alone or with others, directly or indirectly, either:
    <ul>
      <li>takes initiative in founding your company</li>
      <li>receives 10% or more of any securities in your company</li>
      <li>receives 10% or more of the proceeds from the sales of any securities</li>
    </ul>
    The above definition is only a rough summary. Refer to <Link href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR3ba3c08ffc29ca2/section-230.405" target="_blank" rel="noopener">17 CFR 230.405</Link> for a complete definition.
  </>,
  'SEC': <>
    The <b>Securities and Exchange Commission (SEC)</b> is an agency of the United State government which is responsible for enforcing federal securities laws, proposing new securities rules, and regulating the securities industry. The SEC’s mission is to “protect investors, maintain fair, orderly, and efficient markets, and facilitate capital formation.”
  </>,
  'Securities Other Terms': <>
    In addition to voting rights, other rights of the shareholder may include: liquidation preferences, pro rata rights, dividend rights, preemptive rights, right of first refusal, subscription rights, etc.
  </>,
  'Transaction': <>
    The term <b>transaction</b> includes, but is not limited to, any financial transaction, arrangement or relationship (including any indebtedness or guarantee of indebtedness) or any series of similar transactions, arrangements or relationships.
  </>,
  'Use of Proceeds': <>
    A statement about your company’s use of proceeds must be a reasonably detailed description how funds were (or will be) allocated. If your company has identified a range of possible uses, you should identify and describe each probable use and the factors that your company may consider in allocating funds among the probable uses.
  </>,
  'Use of Proceeds Table Instructions': <>
    The columns in this table reflect the proceeds you will portion to different expenses depending on the amount sold in the offering. Fill out the left-hand column of this table with the percentages or dollar amount of the proceeds to reflect the allocation if the minimum amount for a successful raise is reached. Fill out the right-hand column to reflect the allocations if the maximum amount is sold in this offering. You can create additional rows for any large expenditure, like real estate, expensive equipment, repayment of an outstanding debt, etc.
  </>,
  'What are Corporate Bylaws?': <>
    <b>Corporate Bylaws</b> are a set of written rules used by a corporation to organize its internal management. Corporate Bylaws also outline the policies and responsibilities for the shareholders, directors, and officers of a corporation.
  </>,
  'What is an Operating Agreement?': <>
    An <b>Operating Agreement</b> is a document that outlines a limited liability company’s ownership, management, and each member’s rights and responsibilities. An Operating Agreement records the ownership of the company. Ownership is not listed with the State.
  </>,
  'Voting Power': <>
    To calculate total <b>voting power</b>, include all securities for which the person directly or indirectly has or shares the voting power, which includes the power to vote or to direct the voting of such securities.
  </>,
  'Voting Rights': <>
    A <b>voting right</b> is the right of the shareholder to vote or direct the voting on matters of corporate policy and the election of the board of directors.
  </>
};

export default HELP;