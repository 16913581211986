import { Cell, Row } from '../../../netcapital-components/Table';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../Loading';
import Modal from '../../../netcapital-components/Modal';
import { Typography } from '@mui/material';
import { deletePerson } from '../../../_actions/application-form';
import { useAlertActions } from '../../../_actions/alert.actions';

function KeyPersonRow({ keyPerson, row, onRemoveKeyPerson }) {
  const navigate = useNavigate();
  const { listingKey, offerKey } = useParams();
  const alertActions = useAlertActions();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickEditKeyPerson = useCallback(() => {
    navigate(keyPerson.key);
  }, [navigate, keyPerson]);

  const handleClickRemoveKeyPerson = useCallback(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);
  
  const handleCancelRemoveKeyPerson = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);
  
  const deleteKeyPerson = useCallback(async () => {
    setIsLoading(true);
    const resp = await deletePerson(listingKey, offerKey, keyPerson.key);
    setIsLoading(false);
    
    if (resp.response && resp.response.status > 204) {
      alertActions.error(`Failed to delete ${keyPerson.firstName || 'Key'} ${keyPerson.lastName || 'Person'}`, resp.response, resp.message, resp.request);
      return;
    }

    onRemoveKeyPerson(row);
    setModalIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, listingKey, offerKey, keyPerson]);

  const modalMessage = (
    <>
      <Typography variant='h3' color='primary.main'>Please confirm</Typography>
      <Typography variant='body1'>{`Are you sure you want to permanently delete ${keyPerson.firstName || 'Key'} ${keyPerson.lastName || 'Person'}’s profile?`}</Typography>
    </>
  );

  if (!keyPerson) return null;

  return (
    <Row
      key={keyPerson.key}
      onClickEdit={handleClickEditKeyPerson}
      onClickRemove={handleClickRemoveKeyPerson}
    >
      <Loading loading={isLoading} />
      <Modal
        handlePrimaryAction={deleteKeyPerson}
        handleSecondaryAction={handleCancelRemoveKeyPerson}
        hasSecondaryCta
        onClose={handleCancelRemoveKeyPerson}
        open={modalIsOpen}
        primaryBtnColor='error'
        primaryBtnTitle='Delete'
        secondaryBtnColor='primary'
        secondaryBtnTitle='Cancel'
      >
        {modalMessage}
      </Modal>
      <Cell>{keyPerson.firstName || 'Key'}</Cell>
      <Cell>{keyPerson.lastName || 'Person'}</Cell>
    </Row>
  );
}

export default KeyPersonRow;
