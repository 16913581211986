import { Container } from '@mui/material';
import { Dialog } from '../../netcapital-components/Dialog';
import DialogBar from '../DialogBar';
import { FormsMenu } from './FormsMenu';
import Loading from '../../components/Loading';
import OnboardingModal from '../../components/application-form/OnboardingModal';
import styles from './styles';
import { useGetFormGlobals } from '../../_actions/application-form';
import { useParams } from 'react-router-dom';

function ApplicationForm(){
  const { listingKey, offerKey } = useParams();
  const { loaded: loadedGlobals } = useGetFormGlobals(listingKey, offerKey);
  
  return (
    <>
      <Loading loading={!loadedGlobals} />
      <Dialog fullScreen open>
        <DialogBar />
        <Container maxWidth='1920' sx={styles.Container}>
          {loadedGlobals && <OnboardingModal />}
          <FormsMenu />
        </Container>
      </Dialog>
    </>
  );
}

export default ApplicationForm;
