import {
  Avatar,
  Button,
  Link,
  Typography
} from '@mui/material';
import { useCallback, useState } from 'react';

import Session from '../../../_session';
import UserMenu from './menu';
import { appGlobalSettingsState } from '../../../_state';
import { isAuthenticatedState } from '../../../_state/user';
import { styled } from '@mui/material/styles';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const UserLinks = styled(Link)(styles.UserLinks);

const UserOptions = () => {
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const firstName = Session.get('user.firstName');
  const lastName = Session.get('user.lastName');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  
  if (!isAuthenticated) return <UserLinks />;

  return (
    <>
      <Button sx={styles.AvatarIconButton} onClick={handleClick}>
        {firstName && lastName && (
          <Avatar sx={styles.Avatar}>{`${firstName[0]}${lastName[0]}`}</Avatar>
        )}
        {firstName && lastName && (!isMobile && !isTablet) ? 
          <Typography 
            variant='body1' 
            color='common.black' 
            fontWeight={500} 
            sx={styles.Username}
          >
            {`${firstName} ${lastName}`}
          </Typography> 
          :
          null
        }
      </Button>
      <UserMenu handleClose={handleClose} anchorEl={anchorEl} open={open} />
    </>
  );
};

export default UserOptions;