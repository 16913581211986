import { RISKS } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchRisks } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function OtherShareholderRisks() {
  const [otherShareholdersRisks, setOtherShareholdersRisks] = useRecoilState(fetchRisks('otherShareholdersRisks'));
  const savingState = useAutoSave(RISKS, 'otherShareholdersRisks', otherShareholdersRisks);
  const handleChange = useCallback(e => setOtherShareholdersRisks(e.target.value), [setOtherShareholdersRisks]);

  return (
    <TextField
      multiline
      rows={10}
      value={otherShareholdersRisks}
      onChange={handleChange}
      placeholder='Describe...'
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default OtherShareholderRisks;
