import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileField from '../FileField';
import { Typography } from '@mui/material';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function AdditionalInformation(){
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Upload Additional Information
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <FileField usage='OTHER_FINANCIAL' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default AdditionalInformation;