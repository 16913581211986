import {
  AGREEMENTS,
  BOARD_AUTHORIZATION,
  CAP_TABLE,
  COMPANY_DESCRIPTION,
  COMPANY_FORMATION,
  COMPLIANCE,
  CONTACT_INFO,
  FINANCIAL_CONDITION,
  FINANCIAL_REPORT,
  FINANCIAL_STATEMENTS,
  FORMS_RESPONSE_OBJECT,
  FUNDING_HISTORY,
  METADATA,
  RISKS,
  SECURITIES,
  SHARES,
  SOCIAL_MEDIA,
  UOP
} from '../../../static-info/listing-application-forms';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  AccountBalanceOutlined as IconAccountBalanceOutlined,
  BookmarkAddedOutlined as IconBookmarkAddedOutlined,
  BusinessCenterOutlined as IconBriefcase,
  Check as IconCheck,
  CreditCardOutlined as IconCreditCard,
  ViewInArOutlined as IconCube,
  DataObject as IconDataObject,
  EditOutlined as IconEdit,
  LocalAtmOutlined as IconLocalAtmOutlined,
  PaidOutlined as IconMoney,
  PhoneOutlined as IconPhone,
  LocalOfferOutlined as IconPriceTag,
  ReceiptLongOutlined as IconReceiptLongOutlined,
  RequestQuoteOutlined as IconRequestQuoteOutlined,
  GppGoodOutlined as IconShieldCheckOutlined,
  ShieldOutlined as IconShieldOutlined,
  ThumbUpOutlined as IconThumbUp,
  WarningAmberOutlined as IconWarning,
} from '@mui/icons-material';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { fetchFormGlobals, fetchKeyPeople, fetchProgress, saveStatusState } from '../../../_state/application-form';
import { formulateMenuItemSxArray, getCurrentForm } from './utils';

import { CircularLoading } from '../../../netcapital-components/CircularLoading';
import { DateTime } from 'luxon';
import KeyPeopleMenu from './KeyPeopleMenu';
import { isAdminState } from '../../../_state/user';
import styles from './styles';
import { useGetKeyPeople } from '../../../_actions/application-form';
import { useRecoilValue } from 'recoil';

function Nav() {
  const { listingKey, offerKey } = useParams();
  const { loaded: loadedKeyPeople } = useGetKeyPeople(listingKey, offerKey);
  const keyPeople = useRecoilValue(fetchKeyPeople('keyPeople'));
  const formsProgress = useRecoilValue(fetchProgress('forms'));
  const financialReports = useRecoilValue(fetchFormGlobals('financialReports'));
  const saveStatus = useRecoilValue(saveStatusState);
  const location = useLocation();
  const currentForm = getCurrentForm(location);
  const isAdmin = useRecoilValue(isAdminState);

  const getCheckMark = (formId) => {
    if (formId === saveStatus.formId && saveStatus.status === 'SAVING'){
      return <CircularLoading size={20} />;
    }
    
    if (!formsProgress){
      return null;
    }
    
    const formAPIname = FORMS_RESPONSE_OBJECT[formId];
    if (formsProgress[formAPIname] === 100){
      return <IconCheck color='success' />;
    }

    return null;
  };

  return (
    <MenuList>
      <Typography 
        variant='overline' 
        color='primary.main' 
        fontWeight='fontWeightMedium' 
        display='block' 
        gutterBottom
      >
        Forms
      </Typography>
      {/* Contact Information */}
      <MenuItem 
        component={RouterLink}
        sx={formulateMenuItemSxArray('contact-information', currentForm)}
        tabIndex={0} 
        to={CONTACT_INFO}
        disabled={saveStatus.formId !== CONTACT_INFO && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconPhone fontSize='small' />
        </ListItemIcon>
        <ListItemText>Contact Information</ListItemText>
        {getCheckMark(CONTACT_INFO)}
      </MenuItem>
      {/* Social Media */}
      <MenuItem 
        component={RouterLink} 
        sx={formulateMenuItemSxArray('social-media', currentForm)}
        tabIndex={0} 
        to={SOCIAL_MEDIA}
        disabled={saveStatus.formId !== SOCIAL_MEDIA && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconThumbUp fontSize='small' />
        </ListItemIcon>
        <ListItemText>Social Media</ListItemText>
        {getCheckMark(SOCIAL_MEDIA)}
      </MenuItem>
      {/* Company Formation */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('company-formation', currentForm)}
        tabIndex={0} 
        to={COMPANY_FORMATION}
        disabled={saveStatus.formId !== COMPANY_FORMATION && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconBriefcase fontSize='small' />
        </ListItemIcon>
        <ListItemText>Company Formation</ListItemText>
        {getCheckMark(COMPANY_FORMATION)}
      </MenuItem>
      {/* Company Description */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('company-description', currentForm)}
        tabIndex={0} 
        to={COMPANY_DESCRIPTION}
        disabled={saveStatus.formId !== COMPANY_DESCRIPTION && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconEdit fontSize='small' />
        </ListItemIcon>
        <ListItemText>Company Description</ListItemText>
        {getCheckMark(COMPANY_DESCRIPTION)}
      </MenuItem>
      {/* Funding History */}
      <MenuItem
        component={RouterLink} 
        sx={formulateMenuItemSxArray('funding-history', currentForm)}
        tabIndex={0} 
        to={FUNDING_HISTORY}
        disabled={saveStatus.formId !== FUNDING_HISTORY && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconMoney fontSize='small' />
        </ListItemIcon>
        <ListItemText>Funding History</ListItemText>
        {getCheckMark(FUNDING_HISTORY)}
      </MenuItem>
      {/* Securities */}
      {isAdmin &&
        <MenuItem
          component={RouterLink}
          sx={formulateMenuItemSxArray('securities', currentForm)}
          tabIndex={0} 
          to={SECURITIES}
          disabled={saveStatus.formId !== SECURITIES && saveStatus.status === 'SAVING'}
        >
          <ListItemIcon>
            <IconShieldOutlined fontSize='small' />
          </ListItemIcon>
          <ListItemText>Securities (Admin)</ListItemText>
          {getCheckMark(SECURITIES)}
        </MenuItem>
      }
      {/* Cap Table */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('cap-table', currentForm)}
        tabIndex={0} 
        to={CAP_TABLE}
        disabled={saveStatus.formId !== CAP_TABLE && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconAccountBalanceOutlined fontSize='small' />
        </ListItemIcon>
        <ListItemText>Cap Table & Governance</ListItemText>
        {getCheckMark(CAP_TABLE)}
      </MenuItem>
      {/* Key People */}
      {loadedKeyPeople ? 
        <KeyPeopleMenu 
          formId={currentForm.formId} 
          keyPeople={keyPeople} 
          personKey={currentForm.itemId}
          savingForm={saveStatus.formId}
          savingStatus={saveStatus.status}
        />
        : 
        <MenuItem>
          <Stack direction='row' spacing={1}>
            <Skeleton variant='circular' width={30} height={30} />
            <Skeleton variant='rectangular' width={150} height={30} />
          </Stack>
        </MenuItem>
      }
      {/* Shares and Pricing */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('shares-and-pricing', currentForm)}
        tabIndex={0} 
        to={SHARES}
        disabled={saveStatus.formId !== SHARES && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconPriceTag fontSize='small' />
        </ListItemIcon>
        <ListItemText>Shares & Pricing</ListItemText>
        {getCheckMark(SHARES)}
      </MenuItem>
      {/* Use of Proceeds */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('use-of-proceeds', currentForm)}
        tabIndex={0} 
        to={UOP}
        disabled={saveStatus.formId !== UOP && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconCube fontSize='small' />
        </ListItemIcon>
        <ListItemText>Use of Proceeds</ListItemText>
        {getCheckMark(UOP)}
      </MenuItem>
      {/* Risks */}
      <MenuItem
        component={RouterLink}
        sx={formulateMenuItemSxArray('risks', currentForm)}
        tabIndex={0} 
        to={RISKS}
        disabled={saveStatus.formId !== RISKS && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconWarning fontSize='small' />
        </ListItemIcon>
        <ListItemText>Risks</ListItemText>
        {getCheckMark(RISKS)}
      </MenuItem>
      {/* Compliance */}
      <MenuItem
        component={RouterLink} 
        sx={formulateMenuItemSxArray('compliance', currentForm)}
        tabIndex={0} 
        to={COMPLIANCE}
        disabled={saveStatus.formId !== COMPLIANCE && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconBookmarkAddedOutlined fontSize='small' /> 
        </ListItemIcon>
        <ListItemText>Compliance</ListItemText>
        {getCheckMark(COMPLIANCE)}
      </MenuItem>

      <Divider />

      <Typography
        variant='overline' 
        color='primary.main' 
        fontWeight='fontWeightMedium' 
        display='block' 
        gutterBottom
      >
        Financials
      </Typography>
      {/* Financial Condition */}
      <MenuItem
        component={RouterLink} 
        sx={formulateMenuItemSxArray('financial-condition', currentForm)}
        tabIndex={0} 
        to={FINANCIAL_CONDITION}
        disabled={saveStatus.formId !== FINANCIAL_CONDITION && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconCreditCard fontSize='small' />
        </ListItemIcon>
        <ListItemText>Financial Condition</ListItemText>
        {getCheckMark(FINANCIAL_CONDITION)}
      </MenuItem>
      {/* Financial Statements */}
      <MenuItem 
        component={RouterLink}
        sx={formulateMenuItemSxArray('financial-statements', currentForm)}
        tabIndex={0} 
        to={FINANCIAL_STATEMENTS}
        disabled={saveStatus.formId !== FINANCIAL_STATEMENTS && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconRequestQuoteOutlined fontSize='small' />
        </ListItemIcon>
        <ListItemText>Financial Statements</ListItemText>
        {getCheckMark(FINANCIAL_STATEMENTS)}
      </MenuItem>
      {/* Financial Reports */}
      {isAdmin && financialReports?.map((report) => (
        <MenuItem
          component={RouterLink}
          key={report.key}
          sx={[styles.MenuItem, currentForm.itemId === report.key && styles.AccordionMenuItemActive]}
          tabIndex={0}
          to={`${FINANCIAL_REPORT}/${report.key}`}
          disabled={saveStatus.formId !== FINANCIAL_REPORT && saveStatus.status === 'SAVING'}
        >
          <ListItemIcon >
            <IconLocalAtmOutlined fontSize='xs' />
          </ListItemIcon>
          <ListItemText>{report.dateEnd ? `Year Ending ${DateTime.fromISO(report.dateEnd, { zone: 'utc' }).toLocaleString(DateTime.DATE_MED)}` : 'Year Ending'}</ListItemText>
        </MenuItem>
      ))}

      <Divider />

      <Typography
        variant='overline' 
        color='primary.main' 
        fontWeight='fontWeightMedium' 
        display='block' 
        gutterBottom
      >
        Authorization
      </Typography>
      {/* Board Authorization */}
      <MenuItem
        component={RouterLink} 
        sx={formulateMenuItemSxArray('board-authorization', currentForm)}
        tabIndex={0} 
        to={BOARD_AUTHORIZATION}
        disabled={saveStatus.formId !== BOARD_AUTHORIZATION && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconShieldCheckOutlined fontSize='small' />
        </ListItemIcon>
        <ListItemText>Board Authorization</ListItemText>
        {getCheckMark(BOARD_AUTHORIZATION)}
      </MenuItem>
      {/* Agreements */}
      <MenuItem 
        component={RouterLink}
        sx={formulateMenuItemSxArray('agreements', currentForm)}
        tabIndex={0} 
        to={AGREEMENTS}
        disabled={saveStatus.formId !== AGREEMENTS && saveStatus.status === 'SAVING'}
      >
        <ListItemIcon>
          <IconReceiptLongOutlined fontSize='small' />
        </ListItemIcon>
        <ListItemText>Agreements</ListItemText>
        {getCheckMark(AGREEMENTS)}
      </MenuItem>
      
      {isAdmin &&
        <div key={Math.random() * 100}>
          <Divider sx={styles.Divider} />
          <Typography
            variant='overline' 
            color='primary.main' 
            fontWeight='fontWeightMedium' 
            display='block' 
            gutterBottom
          >
            Settings
          </Typography>
          {/* Metadata */}
          <MenuItem 
            component={RouterLink}
            sx={formulateMenuItemSxArray('metadata', currentForm)}
            tabIndex={0} 
            to={METADATA}
            disabled={saveStatus.formId !== METADATA && saveStatus.status === 'SAVING'}
          >
            <ListItemIcon>
              <IconDataObject fontSize='small' />
            </ListItemIcon>
            <ListItemText>Metadata (Admin)</ListItemText>
            {getCheckMark(METADATA)}
          </MenuItem>
        </div>
      }
    </MenuList>
  );
}

export default Nav;