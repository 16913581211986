import { Drawer, IconButton, List } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Footer from '../Footer';
import MenuItems from './items';
import { styled } from '@mui/material/styles';
import styles from './styles';

const DrawerHeader = styled('div')(styles.HeaderMobile);

const HeaderMobile = ({ isMobile, open, handleMobileMenuToggle }) => {
  if (!isMobile){
    return (
      <List sx={[styles.Sidebar, styles.SidebarContent]}>
        <MenuItems />
      </List>
    );
  }

  return (
    <Drawer
      open={open}
      onClose={handleMobileMenuToggle}
      sx={styles.MenuDesktop}
      variant='temporary'
      anchor='left'
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleMobileMenuToggle} sx={styles.HeaderMobileMenuClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <List sx={[styles.Sidebar, styles.SidebarContent]}>
        <MenuItems isMobile handleMobileMenuToggle={handleMobileMenuToggle} />
        <Footer />
      </List>
    </Drawer>
  );
};

export default HeaderMobile;