import Question, { Answer } from '../../../Question';
import { canSecurityTypeBeSold, mapEquitySecuritiesToSelectOptions } from './utils';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SECURITIES } from '../../../../static-info/listing-application-forms';
import Selectbox from '../../../../netcapital-components/Selectbox';
import { Typography } from '@mui/material';
import { fetchSecurities } from '../../../../_state/application-form';
import { useAutoSave } from '../../../../_actions/application-form';

function SecurityTypes(){
  const equitySecurities = useRecoilValue(fetchSecurities('equitySecurities'));
  const [securityTypeToBeSold, setSecurityTypeToBeSold] = useRecoilState(fetchSecurities('securityTypeToBeSold'));
  const savingState = useAutoSave(SECURITIES, 'securityTypeToBeSold', securityTypeToBeSold);
  const handleChangeSecurityTypeToBeSold = useCallback((e) => {
    setSecurityTypeToBeSold(e.target.value);
  },[setSecurityTypeToBeSold]);

  useEffect(() => {
    if (securityTypeToBeSold && !canSecurityTypeBeSold(securityTypeToBeSold, equitySecurities)) {
      setSecurityTypeToBeSold('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equitySecurities]);

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        What type of security are you selling?
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        {equitySecurities.length === 0 && (
          <Typography variant='caption2'>Please add equity securities in the table above.</Typography>
        )}
        {equitySecurities.length > 0 && (
          <Selectbox 
            onChange={handleChangeSecurityTypeToBeSold} 
            options={mapEquitySecuritiesToSelectOptions(equitySecurities)} 
            placeholder='Security type'
            value={securityTypeToBeSold}
            ownerState={{ subVariant: 'admin' }}
            error={savingState && savingState.state === 'error'}
          />
        )}
      </Answer>
    </Question>
  );
}

export default SecurityTypes;