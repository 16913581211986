import { Cell, EditField, Row } from '../../../../netcapital-components/Table';
import { NumberFormatCents, NumberFormatPercent } from '../../../../netcapital-components/NumberFormat';
import { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import MoneyInput from '../../../../netcapital-components/MoneyInput';
import PercentInput from '../../../../netcapital-components/PercentInput';
import PropTypes from 'prop-types';
import { TOTAL_MIN_ALLOCATION } from './utils';
import styles from '../styles';

export default function ProceedRow({ index, isSideBySideOffering, maxUnallocatedPercent4a6Max, maxUnallocatedPercentMax, maxUnallocatedPercentMin, onChange, onRemove, proceed, raiseAmountMax, raiseAmount4a6Max }){
  const minPercent = proceed.amountMin/TOTAL_MIN_ALLOCATION;
  const maxPercent = proceed.amountMax/raiseAmountMax;
  const max4a6Percent = proceed.amount4a6Max/raiseAmount4a6Max;
  const [currentMinPercent, setStateAmountMinPercent] = useState(minPercent);
  const [currentMaxPercent, setStateAmountMaxPercent] = useState(maxPercent);
  const [current4a6MaxPercent, setStateAmount4a6MaxPercent] = useState(max4a6Percent);

  const handleRemoveProceed = useCallback(() => onRemove(proceed._key), [proceed._key, onRemove]);
  
  const handleProceedItemChange = useCallback((e) => onChange(index, { 'item': e.target.value }), [index, onChange]);
  
  const handleAmountMinChange = useCallback((value) => {
    onChange(index, { 'amountMin': value });
    setStateAmountMinPercent(value/TOTAL_MIN_ALLOCATION);
  },[index, onChange, setStateAmountMinPercent]);
  
  const handleAmountMaxChange = useCallback((value) => {
    onChange(index, { 'amountMax': value });
    setStateAmountMaxPercent(value/raiseAmountMax);
  },[index, onChange, raiseAmountMax, setStateAmountMaxPercent]);
  
  const handleAmount4a6MaxChange = useCallback((value) => {
    onChange(index, { 'amount4a6Max': value });
    setStateAmount4a6MaxPercent(value/raiseAmount4a6Max);
  },[index, onChange, raiseAmount4a6Max, setStateAmount4a6MaxPercent]);
  
  const handleAmountMinPercentChange = useCallback((newValue) => {
    if ((newValue > currentMinPercent + maxUnallocatedPercentMin) && newValue !== null) {
      return;
    }
    setStateAmountMinPercent(newValue);
    if (newValue !== null) {
      const proceedNewAmount = Math.floor(newValue * TOTAL_MIN_ALLOCATION);
      handleAmountMinChange(proceedNewAmount); 
    }
  },[currentMinPercent, handleAmountMinChange, maxUnallocatedPercentMin, setStateAmountMinPercent]);
  
  const handleAmountMaxPercentChange = useCallback((newValue) => {
    if ((newValue > currentMaxPercent + maxUnallocatedPercentMax) && newValue !== null) {
      return;
    }
    setStateAmountMaxPercent(newValue);
    if (newValue !== null) {
      const proceedNewAmount = Math.floor(newValue * raiseAmountMax);
      handleAmountMaxChange(proceedNewAmount);
    }
  },[currentMaxPercent, handleAmountMaxChange, maxUnallocatedPercentMax, raiseAmountMax, setStateAmountMaxPercent]);

  const handleAmount4a6MaxPercentChange = useCallback((newValue) => {
    if(newValue > current4a6MaxPercent + maxUnallocatedPercent4a6Max){
      return;
    }
    const proceedNewAmount = Math.floor(newValue * raiseAmount4a6Max);
    setStateAmount4a6MaxPercent(newValue);
    handleAmount4a6MaxChange(proceedNewAmount);
  },[current4a6MaxPercent, handleAmount4a6MaxChange, maxUnallocatedPercent4a6Max, raiseAmount4a6Max, setStateAmount4a6MaxPercent]);
    
  const unallocated4a6MaxCellJsx = (
    <Cell>
      <Box sx={styles.CellBoxNested}>
        <NumberFormatCents value={proceed.amount4a6Max} displayType={'text'} />
        <NumberFormatPercent value={max4a6Percent} displayType={'text'} />
      </Box>
    </Cell>
  );

  const amount4a6MaxCellJsx = (
    <Cell>
      <Box sx={styles.CellBoxNested}>
        <MoneyInput variant='standard' value={proceed.amount4a6Max} onChange={handleAmount4a6MaxChange} isBoldFocus />
        <PercentInput variant='standard' value={current4a6MaxPercent} onChange={handleAmount4a6MaxPercentChange} isBoldFocus />
      </Box>
    </Cell>
  );
  
  if(proceed.item === 'Unallocated Funds'){
    return (
      <Row onClickRemove={null}>
        <Cell>Unallocated Funds</Cell>
        <Cell>
          <Box sx={[styles.CellBoxNested, styles.NotEditable]}>
            <NumberFormatCents value={proceed.amountMin} displayType={'text'} />
            <NumberFormatPercent value={minPercent} displayType={'text'} />
          </Box>
        </Cell>
        {isSideBySideOffering ? unallocated4a6MaxCellJsx : null}
        <Cell>
          <Box sx={[styles.CellBoxNested, styles.NotEditable]}>
            <NumberFormatCents value={proceed.amountMax} displayType={'text'} />
            <NumberFormatPercent value={maxPercent} displayType={'text'} />
          </Box>
        </Cell>
      </Row>
    );
  }

  return (
    <Row onClickRemove={handleRemoveProceed}>
      <Cell>
        <EditField placeholder={'Name'} value={proceed.item} onChange={handleProceedItemChange} />
      </Cell>
      <Cell>
        <Box sx={styles.CellBoxNested}>
          <MoneyInput variant='standard' value={proceed.amountMin} onChange={handleAmountMinChange} isBoldFocus />
          <PercentInput variant='standard' value={currentMinPercent} onChange={handleAmountMinPercentChange} alignRight isBoldFocus/>
        </Box>
      </Cell>
      {isSideBySideOffering ? amount4a6MaxCellJsx : null}
      <Cell>
        <Box sx={styles.CellBoxNested}>
          <MoneyInput variant='standard' value={proceed.amountMax} onChange={handleAmountMaxChange} isBoldFocus />
          <PercentInput variant='standard' value={currentMaxPercent} onChange={handleAmountMaxPercentChange} alignRight isBoldFocus />
        </Box>
      </Cell>
    </Row>
  );
}

ProceedRow.defaultProps = {
  proceed: {
    _key: '',
    amount4a6Max: 0,
    amountMax: 0,
    amountMin: 0,
    item: 'placeholder'
  },
  index: 0,
  isSideBySideOffering: false, 
  onChange: () => { return; },
  onRemove: () => { return; },
  raiseAmountMax: 0,
  raiseAmount4a6Max: 0
};

ProceedRow.propTypes = {
  proceed: PropTypes.shape({
    _key: PropTypes.string,
    amount4a6Max: PropTypes.number,
    amountMax: PropTypes.number,
    amountMin: PropTypes.number,
    item: PropTypes.string
  }),
  index: PropTypes.number,
  isSideBySideOffering: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  raiseAmountMax: PropTypes.number,
  raiseAmount4a6Max: PropTypes.number
};