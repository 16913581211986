import Box from '@mui/material/Box';
import HelperText from '../../netcapital-components/HelperText';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

function answer({ children, helperColor, helperContent, helperIcon, helperIconPosition, helperPosition, sx }) {
  return (
    <Box name='Netcapital-Question-Answer' sx={[styles.Answer, sx]}>
      {(helperContent && helperPosition === 'top') && (
        <HelperText content={helperContent} color={helperColor} icon={helperIcon} iconPosition={helperIconPosition} />
      )}
      {children}
      {(helperContent && helperPosition === 'bottom') && (
        <HelperText content={helperContent} color={helperColor} icon={helperIcon} iconPosition={helperIconPosition} />
      )}
    </Box>
  );
}

export const Answer = React.memo(answer);

Answer.defaultProps = {
  helperColor: 'common.black',
  helperContent: '',
  helperIcon: null,
  helperIconPosition: 'left',
  helperPosition: 'bottom',
  sx: () => { return; },
};

Answer.propTypes = {
  children: PropTypes.node.isRequired,
  helperColor: PropTypes.string,
  helperContent: PropTypes.string,
  helperIcon: PropTypes.node,
  helperIconPosition: PropTypes.oneOf(['left', 'right']),
  helperPosition: PropTypes.oneOf(['top', 'bottom']),
  sx: PropTypes.func,
};

export default Answer;