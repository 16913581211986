import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { assembleFormTitle, getCurrentForm, navIsVisible } from './utils';
import { useCallback, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Nav from './index';
import { fetchKeyPeople } from '../../../_state/application-form';
import styles from './styles-mobile';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export default function MobileNav() {
  const [expanded, setExpanded] = useState(false);
  const keyPeople = useRecoilValue(fetchKeyPeople('keyPeople')) || [];
  const location = useLocation();
  const currentForm = getCurrentForm(location);
  const formTitle = assembleFormTitle(currentForm, keyPeople);
  const isVisible = navIsVisible(currentForm);

  const handleAccordionToggle = useCallback((event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  }, []);
  
  useEffect(() => {
    setExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!isVisible) return null;

  return (
    <Accordion
      sx={styles.Accordion}
      expanded={expanded}
      onChange={handleAccordionToggle}
      disableGutters
      elevation={0}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel-content'
        id='panel-header'
      >
        <Typography variant='h1' sx={styles.Title}>
          {formTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.AccordionDetails}>
        {expanded && <Nav />}
      </AccordionDetails>
    </Accordion>
  );
}
