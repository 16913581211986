import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const fundingHistoryState = atom({
  key: 'fundingHistoryState',
  default: {}
});

const fetchFundingHistory = selectorFamily({
  key: 'fetchFundingHistory',
  get: field => ({get}) => _.get(get(fundingHistoryState), field),
  set: field => ({set}, newValue) =>
    set(fundingHistoryState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchFundingHistory,
  fundingHistoryState,
};
