import { investorStatsState, investorsState } from '../_state/offer';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { useFetchWrapper } from '../_helpers';

export function useGetInvestors(offerKey, queryParams){
  const setInvestors = useSetRecoilState(investorsState);

  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  queryParams['limit'] = 25;
  
  const query = Object.keys(queryParams ? queryParams : {})
    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
    
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }
    
    setLoaded(false);

    const url = `${process.env.REACT_APP_API_HOST}/v2/offerings/offers/${offerKey}/investors?${query}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setInvestors({ metadata: response.metadata, data: response.data });
        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { loaded };
}

export function useGetInvestorStats(offerKey){
  const [investorStats, setInvestorStats] = useRecoilState(investorStatsState);

  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (Object.keys(investorStats).length > 0){
      setLoaded(true);
      return abort;
    }

    const url = `${process.env.REACT_APP_API_HOST}/v2/offerings/offers/${offerKey}/investors-stats`;
    fetchWrapper.get(url, null, signal) 
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setInvestorStats(response);
        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerKey]); 

  return { loaded };
}