const styles = {
  SharesTable: () => ({
    alignItems: 'center',
    marginTop: 0,
    maxWidth: '500px',
    paddingLeft: '28px',
  }),
  Cell: () => ({
    paddingLeft: '0 !important',
  }),
  CellLabel: () => ({
    fontWeight: '400',
  }),
  CellValue: () => ({
    '& .MuiInput-root': {
      fontWeight: '600'
    },
    '& .MuiInput-input': {
      textAlign: 'right',
    }
  }),
  Divider: {
    width: '100%'
  },
  RaiseAmountRow: {
    paddingBottom: '1rem',
  },
  RaiseAmountValue: () => ({
    paddingRight: 0,
  }),
  RaiseAmountValueInput: ({ palette }) => ({
    '&.MuiTypography-root': {
      textAlign: 'right',
      color: palette.primary.dark,
      fontWeight: 600
    },
  }),
  MultiplyIcon: () => ({
    width: '14px',
    height: '14px',
  }),
};

export default styles;
