const styles = {
  ErrorIcon: {
    padding: 0
  },
  StatusMessage: ({ breakpoints }) => ({
    fontStyle: 'italic',
    fontWeight: 600,
    alignItems: 'center',
    color: '#242424',
    marginLeft: '5px',
    marginRight: 'auto',
    [breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '20px'
    },
    [breakpoints.up('sm')]: {
      fontSize: '10px',
      lineHeight: '26px',
    },
    [breakpoints.up('lg')]: {
      paddingLeft: '20px',
      fontSize: '13px',
      lineHeight: '26px'
    },
  }),
  TimeStatusMessage: ({ breakpoints }) => ({
    fontStyle: 'italic',
    fontWeight: 400,
    alignItems: 'center',
    color: '#242424',
    marginLeft: '5px',
    marginRight: 'auto',
    [breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '26px'
    },
    [breakpoints.up('sm')]: {
      fontSize: '10px',
      lineHeight: '26px'
    },
    [breakpoints.up('lg')]: {
      paddingLeft: '20px',
      fontSize: '13px',
      lineHeight: '26px'
    },
  }),
  SavingStatusWrap: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      display: 'inline-flex'
    },
    [breakpoints.up('sm')]: {
      display: 'inline-flex'
    },
    [breakpoints.up('lg')]: {
      display: 'block'
    },
  }),
  SavingStatus_ERROR: ({breakpoints}) => ({
    display: 'inline-grid',
    background: 'rgba(150, 32, 32, 0.2)',
    borderLeft: '4px solid #962020',
    [breakpoints.up('xs')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '195px',
      height: '45px',
      marginLeft: '10px',
    },
    [breakpoints.up('sm')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '200px',
      height: '50px'
    },
    [breakpoints.up('lg')]: {
      paddingTop: '5px',
      marginLeft: '0px',
      width: '304px',
      height: '62px'
    },
  }),
  SavingStatus_INFO: ({breakpoints}) => ({
    display: 'inline-grid',
    background: 'rgba(241, 181, 3, 0.2)',
    borderLeft: '4px solid #F1B503',
    [breakpoints.up('xs')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '195px',
      height: '45px',
      marginLeft: '10px',
    },
    [breakpoints.up('sm')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '200px',
      height: '50px'
    },
    [breakpoints.up('lg')]: {
      paddingTop: '5px',
      marginLeft: '0px',
      width: '304px',
      height: '62px'
    },
  }),
  SavingStatus_SUCCESS: ({breakpoints}) => ({
    display: 'inline-grid',
    background: 'rgba(46, 125, 50, 0.2)',
    borderLeft: '4px solid #2E7D32',
    [breakpoints.up('xs')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '195px',
      height: '45px',
      marginLeft: '10px',
    },
    [breakpoints.up('sm')]: {
      paddingTop: 0,
      marginTop: 0,
      width: '200px',
      height: '50px'
    },
    [breakpoints.up('lg')]: {
      paddingTop: '5px',
      marginLeft: '0px',
      width: '304px',
      height: '62px'
    },
  }),
};

export default styles;
