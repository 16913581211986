import { forwardRef, useCallback } from 'react';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export const NumberFormatCents = forwardRef((props, ref) => {
  const { onChange, value, ...other } = props;

  const unformatMoney = useCallback((e) => {
    const changeValue = (e.value === '') ? null : Math.floor(Math.round(e.floatValue * 100));
    onChange(changeValue);
  }, [onChange]);

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      fixedDecimalScale
      decimalScale={2}
      allowNegative
      value={value === null ? null : Number(value) / 100}
      thousandSeparator
      prefix="$"
      onValueChange={unformatMoney}
    />
  );
});

NumberFormatCents.defaultProps = {
  value: 0,
  onChange: () => { return; }
};

NumberFormatCents.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

NumberFormatCents.displayName = 'NumberFormatCents';
