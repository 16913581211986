import { Cell, Header } from '../../../../netcapital-components/Table';

import { NumberFormatCents } from '../../../../netcapital-components/NumberFormat';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from '../styles';

export default function UseOfProceedsTableHeader({ isSideBySideOffering, raiseAmountMax, raiseAmount4a6Max }){
  const raiseAmount4a6MaxCellJsx = (
    <Cell>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        Allocation at <NumberFormatCents value={raiseAmount4a6Max} displayType='text' /> raised (in $)
      </Typography>
    </Cell>
  );

  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Expense</Typography>
      </Cell>
      <Cell>
        <Typography 
          variant='body1' 
          color='primary.main'
          fontWeight='fontWeightMedium'
          sx={isSideBySideOffering ? styles.UseOfProceedsTableSizes.AmountMinHeader : null}
        >
          Allocation at $10,000 raised (in $)
        </Typography>
      </Cell>
      {isSideBySideOffering ? raiseAmount4a6MaxCellJsx: null}
      <Cell>
        <Typography 
          variant='body1' 
          color='primary.main' 
          fontWeight='fontWeightMedium'
        >
          Allocation at <NumberFormatCents value={raiseAmountMax} displayType='text' /> raised (in $)
        </Typography>
      </Cell>
    </Header>
  );
}

UseOfProceedsTableHeader.defaultProps = {
  isSideBySideOffering: false,
  raiseAmountMax: 0,
  raiseAmount4a6Max: PropTypes.number,
};

UseOfProceedsTableHeader.propTypes = {
  isSideBySideOffering: PropTypes.bool,
  raiseAmountMax: PropTypes.number,
  raiseAmount4a6Max: PropTypes.number
};
