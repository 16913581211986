import { useEffect, useState } from 'react';

import { SHARES } from '../../static-info/listing-application-forms';
import { getAPI_URL } from './_api-urls';
import { sharesState } from '../../_state/application-form';
import { useFetchWrapper } from '../../_helpers';
import { useRecoilState } from 'recoil';

export function useGetShares(listingKey='', offerKey=''){
  const [shares, setShares] = useRecoilState(sharesState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (Object.keys(shares).length > 0){
      setLoaded(true);
      return abort;
    }

    const url = getAPI_URL(SHARES, listingKey, offerKey);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setShares(response.shares);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey, shares]);

  return { loaded };
}