const styles = {
  ProgressBarContainer: ({ breakpoints }) => ({
    width: '100%',
    [breakpoints.up('xs')]: {
      marginLeft: 'auto'
    }
  }),
  ProgressBar: ({ theme }) => ({
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    borderRadius: '20px',
    [theme.breakpoints.up('xs')]: {
      height: '6px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '14px'
    }
  }),
  MinProgressContainer: {
    display: 'inline-block',
    marginRight: '5px'
  },
  MaxProgressContainer: {
    display: 'inline-block',
    marginLeft: '5px',
  },
  CurrentProgressContainer: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      minWidth: '35px',
      maxWidth: '38px',
      position: 'relative',
      left: '10%'
    },
    [breakpoints.up('md')]: {
      left: '5%'
    },
    [breakpoints.up('lg')]: {
      top: -20,
      left: '12%'
    }
  }),
  MinProgress: ({ palette, breakpoints }) => ({
    color: palette.text.secondary,
    [breakpoints.up('lg')]: {
      display: 'inline-block'
    }
  }),
  MaxProgress: ({ palette, breakpoints }) => ({
    color: palette.text.secondary,
    [breakpoints.up('lg')]: {
      display: 'inline-block'
    }
  }),
  CurrentProgress: ({ palette, breakpoints }) => ({
    color: palette.primary.light,
    [breakpoints.up('xs')]: {
      fontWeight: 'bold',
      fontSize: '10px'
    },
    [breakpoints.up('md')]: {
      fontSize: '12px'
    },
    [breakpoints.up('lg')]: {
      fontSize: '15px'
    }
  })
};

export default styles;
