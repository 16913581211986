import { Stack, Typography } from '@mui/material';

import CircularLoading from '../../netcapital-components/CircularLoading';
import { NumberFormat } from '../../_helpers/formatters';
import PropTypes from 'prop-types';
import styles from './styles';

const InvestorStats = ({ loaded, stats }) => {
  const totalInvestors = stats.totalInvestors || 0;
  const averageCommitted = stats.averageCommitted || 0;
  const totalCommitted = stats.totalCommitted || 0;
  
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' spacing={1}>
      <Stack direction='column'>
        <Typography variant='body2' sx={styles.StatLabel}>Total Investors</Typography>
        {loaded ? 
          <Typography variant='h3'>{totalInvestors}</Typography> 
          : 
          <CircularLoading />
        }
      </Stack>
      <Stack direction='column'>
        <Typography variant='body2' sx={styles.StatLabel}>Average Committed</Typography>
        {loaded ?
          <Typography variant='h3'>{NumberFormat.dollars(averageCommitted)}</Typography>
          :
          <CircularLoading />
        }
      </Stack>
      <Stack direction='column'>
        <Typography variant='body2' sx={styles.StatLabel}>Total Committed</Typography>
        {loaded ?
          <Typography variant='h3' color='success.main'>{NumberFormat.dollars(totalCommitted)}</Typography>
          :
          <CircularLoading />
        }
      </Stack>
    </Stack>
  );
};

export default InvestorStats;

InvestorStats.defaultProps = {
  loaded: false,
  stats: {},
};

InvestorStats.propTypes = {
  loaded: PropTypes.bool,
  stats: PropTypes.shape({
    askOfferEndDate: PropTypes.string,
    askOfferStartDate: PropTypes.string,
    askOfferStatus: PropTypes.string,
    averageCommitted: PropTypes.number,
    totalCommitted: PropTypes.number,
    totalInvestors: PropTypes.number,
  }),
};