import DateSelector from '../../../netcapital-components/DateSelector';
import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonDateOfBirth() {
  const { key: personKey } = useParams();
  const [ dateOfBirth, setDateOfBirth ] = useRecoilState(fetchKeyPersonDetails('dateOfBirth'));
  const savingState = useAutoSave(KEY_PERSON, 'dateOfBirth', dateOfBirth, personKey);
  const onChangeDateOfBirth = useCallback(date => setDateOfBirth(date), [setDateOfBirth]);

  if (typeof dateOfBirth === 'undefined') return null;

  return (
    <DateSelector
      onChange={onChangeDateOfBirth}
      value={dateOfBirth || ''}
      error={savingState && savingState.state === 'error'}
    />
  );
}
export default KeyPersonDateOfBirth;
