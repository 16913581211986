import { RISKS } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchRisks } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function CorporateActionRisks() {
  const [corporateActionRisks, setCorporateActionRisks] = useRecoilState(fetchRisks('corporateActionRisks'));
  const savingState = useAutoSave(RISKS, 'corporateActionRisks', corporateActionRisks);
  const handleChange = useCallback(e => setCorporateActionRisks(e.target.value), [setCorporateActionRisks]);

  return (
    <TextField
      multiline
      rows={10}
      value={corporateActionRisks}
      onChange={handleChange}
      placeholder='Risks of corporate actions'
      error={savingState && savingState.state === 'error'}
    />

  );
}

export default CorporateActionRisks;
