const activeMenuItemStyles = {
  backgroundColor: 'primary.contrastText',
  color: 'primary.main',
  '.MuiSvgIcon-root, .MuiTypography-root': {
    color: 'primary.main',
    fill: 'primary.main',
  },
};

const styles = {
  HeaderMobile: ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    minHeight: '2.5rem',
    paddingTop: '1.25rem',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }),
  HeaderMobileMenuClose: ({ palette }) =>({
    color: palette.common.white,
  }),
  MenuItem: {
    borderRadius: '10px 0px 0px 10px',
    paddingTop: 2,
    paddingRight: 0,
    paddingBottom: 2,
    paddingLeft: 2,
    marginBottom: 2,
    '&:hover': activeMenuItemStyles,
  },
  DisabledOption: {
    borderRadius: '10px 0px 0px 10px',
    paddingTop: 2,
    paddingRight: 0,
    paddingBottom: 2,
    paddingLeft: 2,
    marginBottom: 2,
  },
  MenuItemActive: activeMenuItemStyles,
  MenuLinkContainer: {
    textDecoration: 'none',
    width: '100%',
  },
  MenuLinkActive: ({ typography }) => ({
    fontWeight: typography.fontWeightBold
  }),
  MenuLink: ({ typography }) => ({
    color: 'primary.contrastText',
    fill: 'primary.contrastText',
    fontSize: '14px',
    fontWeight: typography.fontWeightRegular,
    lineHeight: 1.666,
    textDecoration: 'none',
  }),
  ComingSoon: ({ palette }) => ({
    display: 'inline-block',
    color: palette.grey[400],
  }),
  ComingSoonIcon: {
    margin: 'auto 0',
  },
  Sidebar: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    display: 'block',
  },
  SidebarContent: ({ breakpoints }) => ({
    // on mobile, the padding to the top is 0
    [breakpoints.up('md')]: {
      paddingTop: '3rem',
    },

    flexGrow: 1,
    paddingLeft: '1rem',
    paddingTop: '0rem',
  }),
};

export default styles;