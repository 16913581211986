import HeaderDesktop from './desktop';
import HeaderMobile from './mobile';
import { appGlobalSettingsState } from '../../_state';
import { useRecoilValue } from 'recoil';

const Header = () => {
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);
  return isTablet || isMobile ? <HeaderMobile /> : <HeaderDesktop />;
};

export default Header;