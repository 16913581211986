const styles = {
  Container: ({ breakpoints }) => ({
    flex: 'auto',
    [breakpoints.up('lg')]: {
      padding: '16px 24px 24px 24px',
    },
    [breakpoints.up('xl')]: {
      padding: '24px 48px 48px 48px',
    },
  }),
  FormGridItem: ({ breakpoints }) => ({
    [breakpoints.up('xs')]: {
      marginTop: '10px',
    },
    [breakpoints.up('sm')]: {
      marginTop: '15px',
    },
    [breakpoints.up('lg')]: {
      marginTop: 0,
    },
  }),
  ProgressBar: {
    marginBottom: '20px',
  },
  ProgressBarGridItem: ({ breakpoints }) => ({
    marginTop: '15px',
    [breakpoints.up('lg')]: {
      maxWidth: '1080px',
    },
  }),
  ProgressBarMobile: {
    marginTop: '10px',
    marginBottom: '5px',
  },
};

export default styles;
