import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const useOfProceedsState = atom({
  key: 'useOfProceedsState',
  default: {}
});

const fetchUseOfProceeds = selectorFamily({
  key: 'fetchUseOfProceeds',
  get: field => ({get}) => _.get(get(useOfProceedsState), field),
  set: field => ({set}, newValue) =>
    set(useOfProceedsState, prevState => ({...prevState, [field]: newValue})),
});

export {
  useOfProceedsState,
  fetchUseOfProceeds,
};
