import { Cell, Header } from '../../../../netcapital-components/Table';

import { Typography } from '@mui/material';
import { memo } from 'react';

function PrincipalOwnersTableHeader() {
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Name of Holder</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Number of Securities Now Held</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Class of Securities</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Percentage of Voting Power</Typography>
      </Cell>
    </Header>
  );
}

export default memo(PrincipalOwnersTableHeader);
