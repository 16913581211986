import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useCallback, useRef, useState } from 'react';

// import { ArrowBack } from '@mui/icons-material';
import FixedHeaderOffset from './offset';
// import HelpMenu from './HelpMenu';
import MainMenu from './MainMenu';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { NetcapitalLogo } from './Logo';
// import SearchIcon from '@mui/icons-material/Search';
import UserMenu from './UserMenu';
import { styles } from './styles';

const HeaderMobile = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const handleSearchToggle = useCallback(() => setIsSearchOpen(!isSearchOpen), [isSearchOpen]);
  const handleMobileMenuToggle = useCallback(() => setMobileOpen(!mobileOpen), [mobileOpen]);
  const containerRef = useRef(null);

  // const { pathname } = useLocation();
  // const subRoutes = pathname?.split('/');
  // const goBack = subRoutes[subRoutes.length-2];
  
  return (
    <Box ref={containerRef}>
      <AppBar position='fixed' variant='light' elevation={4}>
        <Toolbar sx={styles.HeaderToolbarMobile}>
          <Stack direction='row'>
            <IconButton onClick={handleMobileMenuToggle}>
              <MenuOutlinedIcon sx={styles.HamburgerIcon} />
            </IconButton>
            <IconButton disableRipple>
              <NetcapitalLogo variant='light' />
            </IconButton>
          </Stack>
          <UserMenu isDrawer open={mobileOpen} />
        </Toolbar>
      </AppBar>
      <FixedHeaderOffset />
      {/* <Toolbar sx={styles.HeaderToolbarMobile} variant='dense'> */}
      {/* {goBack && (
        <IconButton to={goBack} component={RouterLink} sx={styles.GoBack}>
          <ArrowBack />
        </IconButton>
      )}
      <IconButton
        onClick={handleSearchToggle}
        name='SearchMobile'
        disableRipple
      >
        <SearchIcon />
      </IconButton> */}
      {/* <HelpMenu /> */}
      {/* </Toolbar> */}
      <MainMenu isMobile open={mobileOpen} handleMobileMenuToggle={handleMobileMenuToggle} />
    </Box>
  );
};

export default HeaderMobile;