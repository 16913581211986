import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const risksState = atom({
  key: 'risksState',
  default: {}
});

const fetchRisks = selectorFamily({
  key: 'fetchRisks',
  get: field => ({get}) => _.get(get(risksState), field),
  set: field => ({set}, newValue) =>
    set(risksState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchRisks,
  risksState,
};
