import { Link as RouterLink, useParams } from 'react-router-dom';

import BoardAuthFiles from './BoardAuthFiles';
import Form from '../FormWrapper';
import Signatures from './Signatures';
import { Typography } from '@mui/material';
import { isAdminState } from '../../../_state/user';
import { useGetBoardAuthorization } from '../../../_actions/application-form';
import { useRecoilValue } from 'recoil';

function BoardAuthorization() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetBoardAuthorization(listingKey, offerKey);
  const isAdmin = useRecoilValue(isAdminState);

  return (
    <Form title='Board Authorization' loaded={loaded}>
      <Typography variant='body1'>
        To conduct this offering via Netcapital, we’ll need a copy of the board resolution that authorizes the shares on offer. Your company will also need to appoint a transfer agent approved by Netcapital. Upload copies of the relevant board resolutions. We provide templates to make this process easier, but you’re not obligated to use them as long as the files you upload have the same effect.
      </Typography>
      <Typography variant='body1'>
        To provide you with the correct template for your board resolution, please <RouterLink to='../company-formation'>tell us your company’s structure</RouterLink>.
      </Typography>
      <BoardAuthFiles />
      {isAdmin && (
        <Signatures />
      )}
    </Form>
  );
}

export default BoardAuthorization;