import { Popover, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const balloon = ({id, anchorEl, onClose, children}) => {
  return (
    <Popover
      id={id}
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Typography component="div" sx={styles.HelpText}>
        {children}
      </Typography>
    </Popover>
  );
};

export const Balloon = React.memo(balloon);

Balloon.defaultProps = {
  anchorEl: null
};

Balloon.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Balloon;