const styles = {
  CertificateFormationBody: ({ breakpoints }) => ({
    width: '100%',
    [breakpoints.up('lg')]: {
      width: '85%',
    }
  }),
};

export default styles;
