import { BOARD_AUTHORIZATION } from '../../../../static-info/listing-application-forms';
import SignaturesBoard from './SignaturesBoard';
import SignaturesExecutive from './SignaturesExecutive';
import { fetchBoardAuthorization } from '../../../../_state/application-form';
import { useAutoSave } from '../../../../_actions/application-form';
import { useRecoilValue } from 'recoil';

export default function Signatures() {
  const signatures = useRecoilValue(fetchBoardAuthorization('signatures'));
  useAutoSave(BOARD_AUTHORIZATION, 'signatures', signatures);
  
  return (
    <>
      <SignaturesExecutive />
      <SignaturesBoard />
    </>
  );
}