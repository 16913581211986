import MUIRadio from '@mui/material/Radio';
import React from 'react';
import { styled } from '@mui/material/styles';
import styles from './styles';

const DefaultIcon = styled('span')(styles.Icon);
const CheckedIcon = styled(DefaultIcon)(styles.CheckedIcon);
  
const radio = (props) => {
  return (
    <MUIRadio
      sx={styles.Radio}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<DefaultIcon />}
      {...props}
    />
  );
};

export const Radio = React.memo(radio);

export default Radio;