import { Autocomplete, Box } from '@mui/material';
import { useCallback, useState } from 'react';

import Options from './Options';
import PropTypes from 'prop-types';
import TextField from '../../../../netcapital-components/TextField';
import { fetchUsers } from '../../../../_state/search-user';
import styles from './styles';
import { useGetUsers } from '../../../../_actions/users.actions';
import { useRecoilValue } from 'recoil';

export default function AutoComplete({ handleSelectUser, ownerState }){
  const [query, setQuery] = useState({
    search: '',
    page: 1,
  });

  const { loaded } = useGetUsers(query);
  const users = useRecoilValue(fetchUsers('data'));
  
  const handleChangeInputValue = useCallback((e) => {
    setQuery({...query, search: e.target.value});
  },[query, setQuery]);

  const getOptionLabel = useCallback((user) => `${user.name} ${user.email}`, []);
  const filterOptions = useCallback((x) => x, []);

  const renderInput = useCallback((params) => (
    <TextField 
      {...params}
      ownerState={ownerState}
      placeholder='First Name, Last Name, Email'
      onChange={handleChangeInputValue}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderOption = useCallback((props, user) => <Options key={user.userEntityKey} user={user} onSelectUser={handleSelectUser} props={props} />, []);

  return (
    <Box>
      <Autocomplete
        id='merge-key-person-and-entity'
        inputValue={query.search || ''}
        filterOptions={filterOptions} //overriding the filterOptions prop - https://mui.com/material-ui/react-autocomplete/#search-as-you-type
        getOptionLabel={getOptionLabel}
        loading={!loaded}
        options={users || []}
        noOptionsText={'No matching results'}
        renderInput={renderInput}
        renderOption={renderOption}
        sx={styles.Autocomplete}
      />
    </Box>
  );
}

AutoComplete.propTypes = {
  handleSelectUser: PropTypes.func.isRequired,
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  }).isRequired
};