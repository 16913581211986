import { atom } from 'recoil';
export * from './alert';

const appGlobalSettingsState = atom({
  key: 'appGlobalSettingsState',
  default: {},
});

export {
  appGlobalSettingsState,
};
