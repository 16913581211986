import { fetchFinancialStatements, fetchFormGlobals, financialReportState } from '../../_state/application-form';
import { useEffect, useState } from 'react';

import { FINANCIAL_REPORT } from '../../static-info/listing-application-forms';
import { getAPI_URL } from './_api-urls';
import { useFetchWrapper } from '../../_helpers';
import { useSetRecoilState } from 'recoil';

export function useGetFinancialReport(listingKey='', offerKey='', financialReportKey=''){
  const setFinancialReport = useSetRecoilState(financialReportState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}
    setLoaded(false);

    const url = getAPI_URL(FINANCIAL_REPORT, listingKey, offerKey, financialReportKey);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setFinancialReport(response.financialReport);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialReportKey, listingKey, offerKey]);

  return { loaded };
}

export function useCreateFinancialReport(listingKey='', offerKey='') {
  const setFinancialReports = useSetRecoilState(fetchFinancialStatements('financialReports'));
  const setFinancialReportsGlobalState = useSetRecoilState(fetchFormGlobals('financialReports'));
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    create: (body={}) => {
      setLoading(true);
      const url = getAPI_URL(FINANCIAL_REPORT, listingKey, offerKey);
      return fetchWrapper.post(url, body)
        .then((response) => {
          if (!response) return;
          setFinancialReports((reports) => [ ...reports, response.financialReport ]);
          setFinancialReportsGlobalState((reports) => [ ...reports, response.financialReport ]);
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
}

export function useDeleteFinancialReport(listingKey='', offerKey='', financialReportKey='') {
  const setFinancialReports = useSetRecoilState(fetchFinancialStatements('financialReports'));
  const setFinancialReportsGlobalState = useSetRecoilState(fetchFormGlobals('financialReports'));
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    destroy: () => {
      setLoading(true);
      const url = getAPI_URL(FINANCIAL_REPORT, listingKey, offerKey, financialReportKey);
      return fetchWrapper.delete(url)
        .then((response) => {
          if (!response) return;
          setFinancialReports((reports) => reports.filter((report) => report.key !== financialReportKey));
          setFinancialReportsGlobalState((reports) => reports.filter((report) => report.key !== financialReportKey));
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
}