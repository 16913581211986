import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const agreementsState = atom({
  key: 'AgreementsState',
  default: {}
});

const fetchAgreementsInfo = selectorFamily({
  key: 'fetchAgreementsState',
  get: field => ({get}) => _.get(get(agreementsState), field),
  set: field => ({set}, newValue) =>
    set(agreementsState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchAgreementsInfo,
  agreementsState,
};
