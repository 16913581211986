import { useEffect, useState } from 'react';

import { UOP } from '../../static-info/listing-application-forms';
import { getAPI_URL } from './_api-urls';
import { useFetchWrapper } from '../../_helpers';
import { useOfProceedsState } from '../../_state/application-form';
import { useRecoilState } from 'recoil';

export function useGetUseOfProceeds(listingKey='', offerKey=''){
  const [useOfProceeds, setUOP] = useRecoilState(useOfProceedsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (Object.keys(useOfProceeds).length > 0){
      setLoaded(true);
      return abort;
    }

    const url = getAPI_URL(UOP, listingKey, offerKey);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setUOP(response.offerUseOfProceeds);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey, useOfProceeds]);

  return { loaded };
}