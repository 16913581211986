const styles = {
  Boxes: ({ breakpoints }) => ({
    display: 'inline-flex',
    width: '100%',
    [breakpoints.up('xs')]: {
      width: '98%'
    }
  }),
  Answer: () => ({
    margin: '1rem 0 0.5rem 0',
    '> *': {
      width: '100%'
    },
  }),
  Icon: ({ breakpoints, palette }) => ({
    minWidth: '14px',
    minHeight: '14px',
    maxWidth: '14px',
    maxHeight: '14px',
    border: `5px solid ${palette.primary.main}`,
    [breakpoints.up('xs')]: {
      margin: '5px 10px auto 0'
    },
    [breakpoints.up('md')]: {
      margin: '5px 21px auto 0'
    }
  }),
  Question: ({ breakpoints }) => ({
    fontWeight: 600,
    width: 'calc(100% - 10px)',
    [breakpoints.up('lg')]: {
      maxWidth: '1000px'
    }
  }),
  DescriptionBox: () => ({
    marginTop: '8px',
  }),
  Description: ({ palette }) => ({
    color: palette.primary.dark,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '26px',
  }),
};
  
export default styles;
  