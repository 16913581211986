import { atom } from 'recoil';

const saveStatusState = atom({
  key: 'saveStatusState',
  default: {
    status: 'SAVED',
    formId: null,
    msg: null,
  }
});

export { saveStatusState };