import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from '../styles';
import { useCallback } from 'react';

export default function Options({ user, onSelectUser, ...props }){
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelectUser = useCallback(() => onSelectUser(user), [user]);
  if (!user) return null;
  
  return (
    <Box
      component='li' 
      onClick={handleSelectUser}
      {...props}
    >
      <Box sx={styles.UserBox}>
        <Typography variant='body1'>{user.name}</Typography> 
        <Typography variant='body1' sx={styles.UserEmail}>{user.email}</Typography>
      </Box>
    </Box>
  );
}

Options.defaultProps = {
  onSelectUser: () => { return; },
  user: {
    email: '',
    holdingEntities: [],
    name: '',
    userEntityKey: ''
  }
};

Options.propTypes = {
  onSelectUser: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    holdingEntities: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    userEntityKey: PropTypes.string
  }),
};