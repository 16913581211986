import { atom, selector, selectorFamily } from 'recoil';

import _ from 'lodash';
import { isAdminState } from '../user';

const formGlobalsState = atom({
  key: 'formGlobalsState',
  default: {}
});
  
const fetchFormGlobals = selectorFamily({
  key: 'fetchFormGlobals',
  get: field => ({get}) => get(formGlobalsState)[field],
  set: field => ({set}, newValue) =>
    set(formGlobalsState, prevState => ({...prevState, [field]: newValue})),
});

const isReadOnlyState = selector({
  key: 'isReadOnly',
  get: ({get}) => {
    const offerStatus = _.get(get(formGlobalsState), 'offerStatus', '');
    const isLocked = _.get(get(formGlobalsState), 'isLocked', true);
    const isAdmin = get(isAdminState);

    return (offerStatus !== 'DRAFT' && !isAdmin) || // Offer is not a draft
    (isLocked && !isAdmin); // Offer is locked and user is not an admin
  }
});

export {
  fetchFormGlobals,
  formGlobalsState,
  isReadOnlyState
};
