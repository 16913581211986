import { Typography } from '@mui/material';
import styles from './styles';

const Copyright = () => {
  return (
    <Typography sx={styles.Copyright}>
      Copyright Netcapital Systems LLC {new Date().getFullYear()} ©
    </Typography>
  );
};

export default Copyright;
