import Question, { Answer } from '../../Question';

import FileField from '../FileField';
import { Typography } from '@mui/material';
import { fetchFinancialStatementsFile } from '../../../_state/application-form';

function SignedLetterOfReview(){
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Upload your Signed Letter of Review
      </Typography>
      <Answer>
        <FileField usage='REVIEW_LETTER' state={fetchFinancialStatementsFile} />
      </Answer>
    </Question>
  );
}

export default SignedLetterOfReview;