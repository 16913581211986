const styles = {
  SharesTable: () => ({
    alignItems: 'center',
    marginTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '500px',
    width: '100%',
  }),
  Value: ({ typography }) => ({
    fontWeight: typography.fontWeightBold,
    textAlign: 'right',
  }),
  IconContainer: () => ({
    position: 'relative',
  }),
  MultiplyIcon: () => ({
    position: 'absolute',
    bottom: '3px',
    right: 0,
    width: '14px',
    height: '14px',
  }),
};

export default styles;
