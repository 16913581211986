import Question, { Answer } from '../../Question';
import { useCallback, useEffect, useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchMetadata } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useRecoilState } from 'recoil';

function ShortPitch() {
  const maxCharactersShortPitch = 180;
  const [charactersRemaining, setCharactersRemaining] = useState(maxCharactersShortPitch);
  const [shortPitch, setShortPitch] = useRecoilState(fetchMetadata('shortPitch'));
  const savingState = useAutoSave(METADATA, 'shortPitch', shortPitch);
  const handleChangeShortPitch = useCallback(e => setShortPitch(e.target.value), [setShortPitch]);
  
  useEffect(() => {
    const numChars = shortPitch ? shortPitch.length : 0;
    setCharactersRemaining(maxCharactersShortPitch - numChars);
  }, [shortPitch]);
  
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Short pitch: describe your company in 1-2 sentences.
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <Typography variant='caption1'>{charactersRemaining} characters remaining</Typography>
        <TextField
          multiline
          rows={10}
          value={shortPitch || ''}
          onChange={handleChangeShortPitch}
          ownerState={{ subVariant: 'admin' }}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default ShortPitch;
