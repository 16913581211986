import { Cell, Row } from '../../../../netcapital-components/Table';
import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import DateSelector from '../../../../netcapital-components/DateSelector';
import Link from '@mui/material/Link';
import ModalInput from '../../../../netcapital-components/ModalInput';
import MoneyInput from '../../../../netcapital-components/MoneyInput';
import PropTypes from 'prop-types';
import Selectbox from '../../../../netcapital-components/Selectbox';
import { fetchFormGlobals } from '../../../../_state/application-form';
import styles from '../styles';
import { useRecoilValue } from 'recoil';

function PreviousFundingRow({ index, onChange, onRemoveRow, round }){
  const [openUseOfProceeds, setOpenUseOfProceeds] = useState(false);
  const toggleUseOfProceeds = useCallback(() => setOpenUseOfProceeds(!openUseOfProceeds), [openUseOfProceeds, setOpenUseOfProceeds]);

  const SECURITY_TYPES = useRecoilValue(fetchFormGlobals('securityTypes'));
  const EXEMPTIONS = useRecoilValue(fetchFormGlobals('exemptions'));

  const handleUseOfProceedsChange = useCallback(value => onChange(index, { useOfFunds: value }), [index, onChange]);
  const handleRemoveRow = useCallback(() => onRemoveRow(index), [index, onRemoveRow]);
  const handleChangeOfferingDate = useCallback(value => onChange(index, { offeringDate: value }), [index, onChange]);
  const handleChangeExemption = useCallback(e => onChange(index, { exemption: e.target.value }), [index, onChange]);
  const handleChangeSecurityType = useCallback(e => onChange(index, { securityType: e.target.value }), [index, onChange]);
  const handleChangeAmountSold = useCallback(value => onChange(index, { amountSold: value }), [index, onChange]);

  return (
    <Row onClickRemove={handleRemoveRow}>
      <Cell>
        <Box sx={styles.OfferingDateCellBox}>
          <DateSelector
            onChange={handleChangeOfferingDate}
            size='medium'
            value={round.offeringDate || ''}
            isBoldFocus
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.ExemptionCellBox}>
          <Selectbox 
            value={round.exemption} 
            onChange={handleChangeExemption}
            options={EXEMPTIONS} 
            placeholder='Exemption'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.SecurityTypeCellBox}>
          <Selectbox 
            value={round.securityType} 
            onChange={handleChangeSecurityType} 
            options={SECURITY_TYPES} 
            placeholder='Security'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.AmountSold}>
          <MoneyInput 
            value={round.amountSold}
            onChange={handleChangeAmountSold}
            sx={styles.AmountSold}
            variant='standard'
            isBoldFocus
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.previousFundingUseOfProceedsCellBox}>
          <Link 
            onClick={toggleUseOfProceeds} 
            color='primary.main'
            fontWeight='fontWeightMedium'
            sx={styles.HyperLink}
          >
            {round.useOfFunds === '' ? '+ Add Uses' : 'Uses'}
          </Link>
          <ModalInput
            onClose={toggleUseOfProceeds}
            open={openUseOfProceeds}
            value={round.useOfFunds || ''}
            onChange={handleUseOfProceedsChange}
            title='Uses:'
          />
        </Box>
      </Cell>
    </Row>
  );
}

export default PreviousFundingRow;

PreviousFundingRow.defaultProps = {
  index: 0,
  onChange: () => { return; },
  onRemoveRow: () => { return; },
  round: {
    amountSold: 0,
    exemption: '',
    offeringDate: '',
    securityType: '',
    useOfFunds: ''
  }
};

PreviousFundingRow.propTypes = {
  index: PropTypes.number,
  onChange: PropTypes.func,
  onRemoveRow: PropTypes.func,
  round: PropTypes.shape({
    amountSold: PropTypes.number,
    exemption: PropTypes.string,
    offeringDate: PropTypes.string,
    securityType: PropTypes.string,
    useOfFunds: PropTypes.string
  })
};