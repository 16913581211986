const styles = {
  Autocomplete: {
    '& .MuiInputBase-root': {
      padding: '0',
      '& .MuiOutlinedInput-input': {
        paddingLeft: '12px'
      }
    }
  }
};

export default styles;