import { Box, CircularProgress, Grid } from '@mui/material';
import { Suspense, useMemo } from 'react';

import AutoSaveStatus from '../../components/application-form/AutoSaveStatus';
import FormRoutes from './FormRoutes';
import MobileNav from '../../components/application-form/Nav/Mobile';
import Nav from '../../components/application-form/Nav';
import ProgressBar from '../../netcapital-components/ProgressBar';
import { appGlobalSettingsState } from '../../_state';
import { fetchProgress } from '../../_state/application-form';
import styles from './styles';
import { useGetProgress } from '../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

export function FormsMenu(){  
  const { listingKey } = useParams();
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);
  useGetProgress(listingKey);
  const progressLevel = useRecoilValue(fetchProgress('total'));

  const mobileProgressAndAutoSaveStatusJsx = useMemo(() => {
    return ( 
      <>
        <Grid item xs={12}>
          <AutoSaveStatus />
        </Grid>
        <Grid item xs={12} sx={styles.ProgressBarMobile}>
          <ProgressBar value={progressLevel || 0} color='primary'/>
        </Grid>
      </>
    );
  }, [progressLevel]);
  
  return (  
    <Grid container columnSpacing={6}>
      {(isMobile || isTablet) && mobileProgressAndAutoSaveStatusJsx}
      <Grid item xs={12} lg={3.5} xl={3}>
        <Suspense fallback={<CircularProgress />}>
          {(!isMobile && !isTablet)
            ? 
            <>
              <Box sx={styles.ProgressBar}>
                <ProgressBar value={progressLevel || 0} color='primary'/>
              </Box>
              <AutoSaveStatus />
            </>
            : 
            null
          }
          {(isMobile || isTablet) ? <MobileNav /> : <Nav />}
        </Suspense>
      </Grid>
      <Grid item xs={12} lg={8.5} xl={9} sx={styles.FormGridItem}>
        <Suspense fallback={<CircularProgress />}>
          <FormRoutes />
        </Suspense>
      </Grid>
    </Grid>
  );
}