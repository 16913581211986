import { Box, LinearProgress, Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import styles from './styles';

const MuiLinearProgress = styled(LinearProgress)(styles.ProgressBar);

const progressBar = (props) => {
  const MIN = 0;
  const MAX = 100;
  const { color, ownerState, value } = props;
  const normalizedValue = ((value - MIN) * 100) / (MAX - MIN);
  
  return (
    <Stack direction='row' alignItems='center'>
      <Box sx={styles.MinProgressContainer}>
        <Typography variant='body2' sx={styles.MinProgress}>{`${value}%` || '0%'}</Typography>
      </Box>
      <Box sx={styles.ProgressBarContainer}>
        <MuiLinearProgress
          color={color}
          ownerState={ownerState}
          variant='determinate'
          value={normalizedValue}
          {...props}
        />
      </Box>
      {!ownerState.hideMaxLabel && (
        <Box sx={styles.MaxProgressContainer}>
          <Typography variant='body2' sx={styles.MaxProgress}>100%</Typography>
        </Box>
      )}
    </Stack>
  );
};

export const ProgressBar = React.memo(progressBar);

ProgressBar.defaultProps = {
  ownerState: {
    hideMaxLabel: false,
  },
};

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  ownerState: PropTypes.shape({
    hideMaxLabel: PropTypes.bool,
  }),
  value: PropTypes.number.isRequired,
};

export default ProgressBar;