const styles = {
  AccordionMenu: ({ breakpoints }) => ({
    paddingTop: 0,
    paddingRight: '16px',
    '&:last-of-type:first-of-type': {
      borderRadius: '8px',
    },
    '&:hover': {
      borderRadius: '8px',
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:before': {
      display: 'none',
    },
    '& + .MuiDivider-root': {
      margin: '8px 0',
    },
    [breakpoints.up('xs')]: {
      '& .MuiAccordionSummary-root': {
        border: '0 !important',
        minHeight: '34px',
        '&:focus':{
          borderRadius: '8px'
        },
        '& .MuiMenuItem-root': {
          paddingTop: 0,
          paddingBottom: 0,
        }
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '48px',
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
          minHeight: '48px',
        }
      }
    },
    [breakpoints.up('lg')]: {
      paddingRight: 0,
    }
  }),
  AccordionMenuTopLevelItem: ({ breakpoints }) => ({
    width: '100% !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    [breakpoints.up('lg')]: {
      paddingLeft: 0
    }
  }),
  AccordionMenuDetails: ({ breakpoints }) => ({
    '&.MuiAccordionDetails-root a:hover':{
      borderRadius: '8px'
    },
    '&.MuiAccordionDetails-root a:focus':{
      borderRadius: '8px'
    },
    [breakpoints.up('xl')]: {
      paddingTop: 0
    }
  }),
  MenuItem: () => ({
    '&:hover': {
      borderRadius: '8px'
    },
    '&:focus': {
      borderRadius: '8px'
    }
  }),
  Divider: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  ActiveMenuItem: ({ palette, typography }) => ({
    background: palette.background.active,
    borderRadius: '8px',
    '&:focus': {
      background: palette.background.active
    },
    '& .MuiListItemText-root span':{
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold
    },
    '& .MuiListItemIcon-root svg': {
      fill: palette.primary.main
    }
  }),
  ActiveAccordion: ({ palette, typography }) => ({
    background: palette.background.active,
    borderRadius: '8px',
    fontWeight: typography.fontWeightBold,
    '&:hover': {
      background: palette.background.active
    },
    '&:focus': {
      background: palette.background.active
    },
    '&.MuiAccordion-root': {
      background: palette.background.active
    }
  }),
  AccordionTopLevelMenuItemActive: ({ palette, typography }) => ({
    '& .MuiListItemText-root span':{
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold
    },
    '& .MuiSvgIcon-root':{
      color: palette.primary.main
    }
  }),
  AccordionMenuItemActive: ({ palette, typography }) => ({
    '& .MuiListItemText-root span':{
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold
    },
    '&:focus': {
      background: palette.background.active
    }
  })
};

export default styles;
