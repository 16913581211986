import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OutlinedButton from '../../../netcapital-components/OutlinedButton';
import { Link as RouterLink } from 'react-router-dom';
import SolidButton from '../../../netcapital-components/SolidButton';
import { saveStatusState } from '../../../_state/application-form';
import styles from './styles';
import { useRecoilValue } from 'recoil';

function SectionCtaNavigation({ prevRoute, nextRoute }){
  const saveStatus = useRecoilValue(saveStatusState);

  return (
    <>
      {prevRoute && (
        <RouterLink to={saveStatus?.status !== 'SAVING' && `../${prevRoute.pathname}`}>
          <OutlinedButton
            color='secondary'
            sx={styles.PrevButton}
            disableElevation
            disabled={saveStatus?.status === 'SAVING'}
          >
            <ArrowBackIcon sx={styles.ArrowBackIcon} />
            {`Back to ${prevRoute.name}`}
          </OutlinedButton>
        </RouterLink>
      )}
      {nextRoute && (
        <RouterLink to={saveStatus?.status !== 'SAVING' && `../${nextRoute.pathname}`}>
          <SolidButton
            color='secondary'
            sx={styles.NextButton}
            disableElevation
            disabled={saveStatus?.status === 'SAVING'}
          >
            {`Continue to ${nextRoute.name}`}
            <ArrowForwardIcon sx={styles.ArrowForwardIcon} />
          </SolidButton>
        </RouterLink>
      )}
    </>
  );
}

export default SectionCtaNavigation;
