import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const boardAuthorizationState = atom({
  key: 'boardAuthorizationState',
  default: {}
});

const fetchBoardAuthorization = selectorFamily({
  key: 'fetchBoardAuthorization',
  get: field => ({get}) => _.get(get(boardAuthorizationState), field),
  set: field => ({set}, newValue) =>
    set(boardAuthorizationState, prevState => ({...prevState, [field]: newValue})),
});

const fetchBoardAuthorizationFile = selectorFamily({
  key: 'fetchBoardAuthorizationFile',
  get: usage => ({get}) => {
    const files = get(boardAuthorizationState).files || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(boardAuthorizationState).files || [];

    if (!newFile) {
      set(boardAuthorizationState, prevState => ({...prevState, files: files.filter(file => file.usage !== usage)}));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(boardAuthorizationState, prevState => ({...prevState, files: updatedFiles}));
    }
  }
});

export {
  fetchBoardAuthorization,
  boardAuthorizationState,
  fetchBoardAuthorizationFile,
};
