import { Link, styled } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';

const LogoLink = styled(Link)(styles.LogoLink);
const LogoImg = styled('img')(styles.LogoImg);

const LOGOS = {
  dark: `${process.env.REACT_APP_CDN_IMAGES}/_logos/logo-netcapital.svg`,
  light: `${process.env.REACT_APP_CDN_IMAGES}/_logos/logo-netcapital-white.svg`,
};

export const NetcapitalLogo = ({ variant }) => {
  return (
    <LogoLink href='https://netcapital.com'>
      <LogoImg alt='Netcapital' src={LOGOS[variant]} />
    </LogoLink>
  );
};

NetcapitalLogo.defaultProps = {
  variant: 'dark',
};

NetcapitalLogo.propTypes = {
  variant: PropTypes.oneOf(['dark', 'light']),
};