const styles = {
  Alert: () => ({
    '&.MuiAlert-root':{
      display: 'flex',
      alignItems: 'center',
    },
  }),
  Container: ({ breakpoints }) => ({
    flex: 'auto',
    paddingTop: '12px',
    paddingBottom: '16px',
    [breakpoints.up('lg')]: {
      padding: '12px 144px 16px 144px',
    },
  }),
  ErrorDark: ({ palette }) => ({
    color: palette.error.dark,
    '&.MuiSvgIcon-root':{
      color: palette.error.dark,
    },
  }),
  InfoDark: ({ palette }) => ({
    color: palette.info.dark,
    '&.MuiSvgIcon-root':{
      color: palette.info.dark,
    },
  }),
  SearchBar: ({ breakpoints }) => ({
    '&.MuiTextField-root':{
      [breakpoints.up('sm')]: {
        width: '50%',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
  }),
  SortByContainer: ({ breakpoints }) => ({
    [breakpoints.up('sm')]: {
      width: '50%',
      '&.MuiStack-root':{
        width: '50%',
      },
    },
  }),
  SortLabelBox: ({ palette }) => ({
    display: 'flex', 
    position: 'relative',
    width: '100px',
    marginRight: '-2px',
    alignItems: 'center', 
    justifyContent: 'center', 
    border: `1px solid ${palette.grey[400]}`,
    borderRight: '0', 
    borderRadius: '4px 0px 0px 4px', 
  }),
  StatLabel: () => ({
    marginBottom: '0.25rem',
  }),
  SuccessDark: ({ palette }) => ({
    color: palette.success.dark,
    '&.MuiSvgIcon-root':{
      color: palette.success.dark,
    },
  }),
};

export default styles;