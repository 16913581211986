import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const metadataState = atom({
  key: 'metadataState',
  default: {}
});

const fetchMetadata = selectorFamily({
  key: 'fetchMetadata',
  get: field => ({get}) => _.get(get(metadataState), field),
  set: field => ({set}, newValue) =>
    set(metadataState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchMetadata,
  metadataState,
};