import { Link } from '@mui/material';
import MenuLinkContent from './content';
import { Link as RouterLink } from 'react-router-dom';
import styles from '../styles';

const isExternalLink = (url) => {
  return !url.startsWith('/') && !url.startsWith(process.env.MARKETPLACE_WEB_APP_HOST);
};

const MenuLink = (props) => {
  const { link, isSelected } = props;

  if (!link.enabled) {
    return (
      <Link sx={styles.MenuLinkContainer}>
        <MenuLinkContent link={link} isSelected={isSelected} />
      </Link>
    );
  }

  if (isExternalLink(link.url)) {
    return (
      <Link href={link.enabled && !link.function ? link.url : '#'} sx={styles.MenuLinkContainer} onClick={link.handleFunction}>
        <MenuLinkContent link={link} isSelected={isSelected} />
      </Link>
    );
  }

  return (
    <Link component={RouterLink} to={link.enabled ? link.url : '#'} sx={styles.MenuLinkContainer}>
      <MenuLinkContent link={link} isSelected={isSelected} />
    </Link>
  );
};

export default MenuLink;