import { Box, Typography } from '@mui/material';
import Question, { Answer } from '../../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { RADIO_OPTIONS } from '../../../../static-info';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import { SECURITIES } from '../../../../static-info/listing-application-forms';
import TextField from '../../../../netcapital-components/TextField';
import { fetchSecurities } from '../../../../_state/application-form';
import styles from '../styles';
import { useAutoSave } from '../../../../_actions/application-form';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function SecurityDifferences(){
  const [securityDifferences, setSecurityDifferences] = useRecoilState(fetchSecurities('securityDifferences'));
  const savingState_securityDifferences = useAutoSave(SECURITIES, 'securityDifferences', securityDifferences);
  const handleChangeSecurityDifferences = useCallback((e) => {
    setSecurityDifferences(e.target.value);
  },[setSecurityDifferences]);
  
  const [hasSecurityDifferences, setHasSecurityDifferences] = useRecoilState(fetchSecurities('hasSecurityDifferences'));
  const savingState_hasSecurityDifferences = useAutoSave(SECURITIES, 'hasSecurityDifferences', hasSecurityDifferences);
  const handleChangeHasSecurityDifferences = useCallback((e) => {
    setHasSecurityDifferences(e.target.value === 'true');
    //clean data for securityDifferences when unchecking radio for hasSecurityDifferences
    if(e.target.value === false){
      setSecurityDifferences('');
    }
  },[setSecurityDifferences, setHasSecurityDifferences]);
  
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Are there any other differences between the securities listed above and the securities you will offer on Netcapital?
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <RadioGroup
          name='hasSecurityDifferences'
          onChange={handleChangeHasSecurityDifferences}
          options={RADIO_OPTIONS.YES_NO}
          value={hasSecurityDifferences}
          error={savingState_hasSecurityDifferences && savingState_hasSecurityDifferences.state === 'error'}
        />
        {hasSecurityDifferences && (
          <Box sx={styles.SecurityDifferences}>
            <TextField
              multiline
              onChange={handleChangeSecurityDifferences}
              placeholder='Describe the differences between the securities listed above and the securities you will offer on Netcapital'
              rows={10}
              ownerState={{ subVariant: 'admin' }}
              value={securityDifferences}
              error={savingState_securityDifferences && savingState_securityDifferences.state === 'error'}
            />
          </Box>
        )}
      </Answer>
    </Question>
  );
}

export default SecurityDifferences;