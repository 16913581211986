const styles = {
  Container: ({ breakpoints }) => ({
    flex: 'auto',
    paddingTop: '12px',
    paddingBottom: '16px',
    [breakpoints.up('lg')]: {
      padding: '24px 48px 48px 48px',
    },
  }),
  FilterGridItem: ({ breakpoints }) => ({
    marginBottom: '1rem',
    [breakpoints.up('md')]: {
      paddingRight: '24px',
    },
  }),
  ProgressBar: {
    '&.MuiLinearProgress-root': {
      width: '189px'
    }
  },
  RangeLabelNumbers: {
    marginTop: '-12px',
  },
  RangeLabelText: {
    display: 'inline-block', 
  },
  SortLabelBox: ({ palette }) => ({
    display: 'flex', 
    width: '100px',
    marginRight: '-2px',
    alignItems: 'center', 
    justifyContent: 'center', 
    border: `1px solid ${palette.grey[400]}`,
    borderRight: '0', 
    borderRadius: '4px 0px 0px 4px', 
    position: 'relative',
  }),
  SliderBox: () => ({
    display: 'flex',
  }),
  Title: ({breakpoints}) => ({
    fontSize: '30px',
    marginBottom: '1rem',
    [breakpoints.up('lg')]: {
      fontSize: '36px',
    }
  }),
  TitleWrapper: {
    display: 'flex',
  },
};

export default styles;