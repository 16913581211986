import { Box, Link, Typography } from '@mui/material';

import { isAdminState } from '../../_state/user';
import styles from './styles';
import { useRecoilValue } from 'recoil';

export default function NotFoundPage() {
  const isAdmin = useRecoilValue(isAdminState);
  
  return (
    <Box>
      <Box sx={styles.ErrorHeader}>
        <Typography variant='h1' sx={styles.ErrorTitle}>404: Page not found.</Typography>
      </Box>
      <Box sx={styles.ErrorBody}>
        <Typography variant='body1'>The page you’re trying to access doesn’t exist. If you typed it, double-check your spelling. Otherwise, {isAdmin ? <Link href={`${process.env.REACT_APP_WEB_HOST}/admin/listings`} target='_blank' rel='noopener noreferrer' sx={styles.RedirectLink}>go back to Listings.</Link> : <Link href={`${process.env.REACT_APP_WEB_HOST}/dashboard`} target='_blank' rel='noopener noreferrer' sx={styles.RedirectLink}>go back to your dashboard.</Link>}</Typography>
      </Box>
    </Box>
  );
}
