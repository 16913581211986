import { KEY_PERSON } from '../../static-info/listing-application-forms';
import { fetchKeyPersonDetails } from '../../_state/application-form';
import { getAPI_URL } from './_api-urls';
import { useFetchWrapper } from '../../_helpers';
import { useSetRecoilState } from 'recoil';
import { useState } from 'react';

export function useCreateEmploymentRecord(listingKey='', offerKey='', personKey='') {
  const setEmploymentRecords = useSetRecoilState(fetchKeyPersonDetails('employmentRecords'));
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    create: () => {
      setLoading(true);
      const url = getAPI_URL(KEY_PERSON, listingKey, offerKey, personKey);
      return fetchWrapper.post(`${url}/employment-records`, { personKey })
        .then((response) => {
          if (!response) return;
          setEmploymentRecords((records) => [ ...records, response.employmentRecord ]);
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
}

export function useDeleteEmploymentRecord(listingKey='', offerKey='', personKey='', employmentRecordKey='') {
  const setEmploymentRecords = useSetRecoilState(fetchKeyPersonDetails('employmentRecords'));
  const fetchWrapper = useFetchWrapper();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);

  return {
    loading,
    saved,
    destroy: () => {
      setLoading(true);
      const url = getAPI_URL(KEY_PERSON, listingKey, offerKey, personKey);
      return fetchWrapper.delete(`${url}/employment-records/${employmentRecordKey}`)
        .then((response) => {
          if (!response) return;
          setEmploymentRecords((records) => records.filter((record) => record.key !== employmentRecordKey));
          setSaved(true);
        })
        .catch(() => {
          setSaved(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
}