import {
  BrowserTracing,
  Replay,
  init,
  reactRouterV6Instrumentation
} from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import React from 'react';

export function initSentryReactSDK(){
  init({
    dsn: process.env.REACT_APP_ISSUERS_WEB_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^http:\/\/dev\.issuers\.netcapital\.com.*/, /^https:\/\/issuers\.netcapital\.com\/.*$/],
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Replay()
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production (was 0.2) 
    // [ Currently 100% sample rate for production and 0% sample rate for dev ]
    tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 1.0 : 0, 
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}