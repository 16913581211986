import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const financialConditionState = atom({
  key: 'financialConditionState',
  default: {}
});

const fetchFinancialCondition = selectorFamily({
  key: 'fetchFinancialCondition',
  get: field => ({get}) => _.get(get(financialConditionState), field),
  set: field => ({set}, newValue) =>
    set(financialConditionState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchFinancialCondition,
  financialConditionState,
};
