const palette = {
  mode: 'light',
  primary: {
    light: '#e2dced',
    main: '#674fa0',
    dark: '#2a095a',
    contrastText: '#ffffff' //un-necessary?
  },
  secondary: {
    light: '#fcf4db',
    main: '#f2c94c',
    dark: '#d8a300',
    contrastText: '#242424' //un-necessary?
  },
  success: {
    light: '#4caf50',
    main: '#2e7d32',
    dark: '#1b5e20',
  },
  error: {
    light: '#ef5350',
    main: '#d32f2f',
    dark: '#c62828',
  },
  warning: {
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#e65100',
  },
  info: {
    light: '#03a9f4',
    main: '#0288d1',
    dark: '#01579b',
  },
  common: {
    black: '#242424',
    white: '#ffffff',
  },
  grey: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#eeeeee',
    '300': '#DDE5E9',
    '400': '#bdbdbd',
    450: '#6B75888F',
    '500': '#9e9e9e',
    'main': '#757575',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#4B4B4B',
  },
  shadows: {
    primary: 'rgba(0, 16, 101, 0.27)'
  },
  text: {
    primary: '#242424',
    secondary: '#00000099',
    disabled: '#00000061',
    title: '#24094B',
    white: '#fff',
    black: '#242424',
  },
  'divider': 'rgba(0, 0, 0, 0.12)',
  background: {
    active: 'rgba(108, 78, 165, 0.15)',
    default: '#fff',
    'paper': '#fff',
    tableCell: 'rgba(108, 78, 165, 0.03)',
    tableHeader: 'rgba(108, 78, 165, 0.1)'
  },
};

export default palette;
