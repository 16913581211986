import { useEffect, useState } from 'react';

import { APIListingApplicationURL } from './_api-urls';
import { progressState } from '../../_state/application-form';
import { useFetchWrapper } from '../../_helpers';
import { useSetRecoilState } from 'recoil';

export function useGetProgress(listingKey=''){
  const setProgressLevel = useSetRecoilState(progressState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}
    
    fetchWrapper.get(`${APIListingApplicationURL(listingKey)}/progress`, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setProgressLevel(response);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loaded };
}