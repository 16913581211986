import ProgressBar from '../../netcapital-components/ProgressBar';
import { Switch } from '../../netcapital-components/Switch';
import { Typography } from '@mui/material';
import styles from './styles';

export const sortByFilters = [
  {
    value: '',
    label: 'Default (None)' 
  },
  {
    value: 'newest', 
    label: 'Last Edited Date (Newest - Oldest)'
  },
  {
    value: 'oldest', 
    label: 'Last Edited Date (Oldest - Newest)'
  },
  {
    value: 'az', 
    label: 'Name of Company (A - Z)'
  },
  {
    value: 'za', 
    label: 'Name of Company (Z - A)'
  },
  {
    value: 'least-progress', 
    label: 'Current App Progress (Least - Most)'
  },
  {
    value: 'most-progress', 
    label: 'Current App Progress (Most - Least)'
  },
  {
    value: 'locked',
    label: 'Locked Listings First'
  },
  {
    value: 'unlocked',
    label: 'Unlocked Listings First'
  }
];

export const generateQuestionnairesTableColumns = (isMobile, onQuestionnaireLockedStatusChange) => {
  return (
    [
      {
        field: 'companyName',
        headerName: 'Company Name',
        width: isMobile ? 225 : 300,
        editable: false,
        renderCell: (params) => {
          return <Typography variant='body1' fontWeight={600}>{params.formattedValue}</Typography>;
        },
      },
      {
        field: 'currentAppProgress',
        headerName: 'Current Progress',
        width: isMobile ? 200 : 300,
        editable: false,
        renderCell: (params) => {
          if(isMobile){
            return <Typography variant='body1'>{params.formattedValue}%</Typography>;
          }
          return <ProgressBar color='primary' ownerState={{ hideMaxLabel: true }} value={params.formattedValue} sx={styles.ProgressBar} />;
        },
      },
      {
        field: 'lastEdited',
        headerName: 'Last Edited',
        width: isMobile ? 200 : 300,
        editable: false,
        renderCell: (params) => {
          return <Typography variant='body1'>{new Date(Date.parse(params.formattedValue)).toLocaleString()}</Typography>;
        },
      },
      {
        field: 'locked',
        headerName: 'Status',
        width: isMobile ? 200 : 300,
        editable: false,
        renderCell: (params) => {
          return (
            <Switch 
              checked={params.formattedValue} 
              label={params.formattedValue ? 'Locked' : 'Unlocked'} 
              onChange={(e) => { onQuestionnaireLockedStatusChange(e, params.row._key, params.row.EntityKey);} }
            />
          );
        },
      },
    ]
  );
};