import { Dialog as MUIDialog, Slide } from '@mui/material';
import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const dialog = (props) => <MUIDialog TransitionComponent={Transition} {...props}>{props.children}</MUIDialog>;

export const Dialog = React.memo(dialog);

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
};