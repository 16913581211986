import Question, { Answer } from '../../Question';
import { useGetContactInfo, useGetFundingHistory } from '../../../_actions/application-form';

import { CreditorsTable } from './creditorsTable';
import CrowdfundingHistory from './CrowdfundingHistory';
import Form from '../FormWrapper';
import HasLifetimeCrowdfundingHistory from './HasLifetimeCrowdfundingHistory';
import OtherCrowdfundingHistory from './OtherCrowdfundingHistory';
import { fetchFormGlobals } from '../../../_state/application-form';
import { isRegCF } from '../../../_helpers/exemptions';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function FundingHistory() {
  const {listingKey, offerKey} = useParams();
  const { loaded } = useGetFundingHistory(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  const isRegCfOffering = isRegCF(useRecoilValue(fetchFormGlobals('offerExemptions')));

  return (
    <Form title='Funding History' loaded={loaded && loadedContactInfo}>
      {isRegCfOffering && (
        <>
          <HasLifetimeCrowdfundingHistory />
          <OtherCrowdfundingHistory />
        </>
      )}
      <CrowdfundingHistory />
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          List your company&apos;s outstanding loans, promissory notes, and convertible debt instruments. Include the dollar amount, interest rate, maturity date and any other terms.
        </Typography>
        <Answer>
          <CreditorsTable />
        </Answer>
      </Question>
    </Form>
  );
}

export default FundingHistory;
