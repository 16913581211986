const styles = {
  Footer: () => ({
    backgroundColor: 'primary.main',
    padding: '0 1.25rem'
  }),
  MenuLink: ({ theme }) => ({
    color: theme.palette.text.white,
    textDecoration: 'none',
  }),
  FooterMenuEmail: ({ palette }) => ({
    color: palette.secondary.main,
    textDecoration: 'underline',
  }),
  Copyright: {
    color: 'rgba(255, 255, 255, 0.7)',
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 300,
    '&.MuiTypography-root':{
      marginBottom: '12px',
    }
  },
};

export default styles;
