import Question, { Answer } from '../../Question';
import { fetchContactInfo, fetchMetadata } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import { SECTORS } from '../../../static-info';
import Selectbox from '../../../netcapital-components/Selectbox';
import { Typography } from '@mui/material';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

function Industry() {
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [sector, setSector] = useRecoilState(fetchMetadata('sector'));
  const savingState = useAutoSave(METADATA, 'sector', sector);
  const handleChangeSector = useCallback(e => setSector(e.target.value), [setSector]);
  
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        What industry best describes {doingBusinessAs || 'your company'}?
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <Selectbox
          onChange={handleChangeSector}
          options={SECTORS}
          placeholder='Industry'
          value={sector}
          ownerState={{ subVariant: 'admin' }}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default Industry;