const styles = {
  Icon: () => ({
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    background: 'rgba(107, 117, 136, 0.2)',
    border: '2px solid rgba(107, 117, 136, 0.3)',
    '.Mui-focusVisible &': {
      outline: '2px solid rgba(107, 117, 136, 0.3);',
      outlineOffset: 2,
    },
  }),
  CheckedIcon: ({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    border: theme.palette.secondary.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  Radio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  RadioButton: () => ({
    marginRight: '35px',
  }),
};

export default styles;