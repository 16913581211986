const styles = {
  DataGridContainer: {
    height: '600px',
    width: '100%',
  },
  DataGrid: {
    '&.MuiDataGrid-root': {
      border: 'none'
    },
  },
};

export default styles;