import { Outlet, useLocation } from 'react-router-dom';

const IssuerDashboard = () => {
  const location = useLocation();
  const pathNameArray = location.pathname.split('/');
  
  //To-do: Handle top-level protected routes. For now, re-direct to legacy dashboard unless user is navigating to Investors page child route. 403 page and 404 page handles other cases we need for now?
  if(pathNameArray?.length && !pathNameArray.includes('investors')){
    window.location.href = `${process.env.REACT_APP_WEB_HOST}/dashboard`;
  }
  
  return <Outlet />;
};

export default IssuerDashboard;