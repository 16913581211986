import Table, { Body, Cell, Footer } from '../../../../netcapital-components/Table';

import CreditorsTableHeader from './header';
import CreditorsTableRow from './row';
import { FUNDING_HISTORY } from '../../../../static-info/listing-application-forms';
import { NumberFormatCents } from '../../../../netcapital-components/NumberFormat';
import { Typography } from '@mui/material';
import { fetchFundingHistory } from '../../../../_state/application-form';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const calculateTotalDebt = (creditors) => {
  if(!creditors) return 0;

  let total = 0;
  creditors.forEach(row => {
    if(row.balance){
      total += row.balance;
    }
  });

  return total;
};

export function CreditorsTable() {
  const [creditors, setCreditors] = useRecoilState(fetchFundingHistory('creditors'));
  const savingState = useAutoSave(FUNDING_HISTORY, 'creditors', creditors);

  const handleChange = useCallback((row, newValue) => {
    const copyCreditors = [...creditors];
    const entry = [...copyCreditors][row];
    copyCreditors[row] = {...entry, ...newValue};
    setCreditors([...copyCreditors]);
  }, [creditors, setCreditors]);

  const onNewRowDebt = useCallback(() => {
    const initialCreditors = [];
    const newCreditor = {
      _key: uuidv4(),
      balance: 0,
      isPayableOnDemand: false,
      maturityDate: '',
      name: 'Creditor name',
      noMaturityDate: false,
      rate: 0,
      terms: '',
    };
    if(!creditors){
      initialCreditors.push(newCreditor);
      setCreditors(initialCreditors);
    } else {
      setCreditors([...creditors, newCreditor]);
    }
  }, [creditors, setCreditors]);

  const handleRemoveRow = useCallback((index) => {
    const copyCreditors = [...creditors];
    copyCreditors.splice(index, 1);
    setCreditors([...copyCreditors]);
  }, [creditors, setCreditors]);

  const totalLoans = calculateTotalDebt(creditors);

  return (
    <Table 
      onNewRow={onNewRowDebt} 
      error={savingState && savingState.state === 'error'}
    >
      <CreditorsTableHeader />
      {creditors && (
        <Body>
          {creditors.map((creditor, index) => (
            <CreditorsTableRow 
              key={creditor._key} 
              creditor={creditor} 
              index={index} 
              onRemove={handleRemoveRow} 
              onChange={handleChange} 
            />
          ))}
        </Body>
      )}
      <Footer>
        <Cell />
        <Cell>
          <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
            Outstanding Loans <NumberFormatCents value={totalLoans} displayType='text' />
          </Typography>
        </Cell>
        <Cell />
        <Cell />
        <Cell />
      </Footer>
    </Table>
  );
}
