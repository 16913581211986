import { Autocomplete, Grid, InputAdornment } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import CountrySelect from './CountrySelect';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import _ from 'lodash';
import addressFormats from './addressFormats';
import produce from 'immer';
import styles from './styles';

const getCountryConfig = (country) => {
  return addressFormats[country];
};

const getStateOptions = (country) => {
  const fields = _.get(addressFormats[country], 'fields');
  const config = _.find(fields, {'key': 'administrativearea'}) || {};
  return _.map(config.options, (value, key) => {
    return {
      value: key,
      label: value
    };
  });
};

const getFieldLabel = (countryConfig, key) => {
  if (countryConfig?.fields) {
    const field = _.find(countryConfig.fields, {key});
    return field ? field.label : null;
  }
  return null;
};

function AddressInput(props) {
  const {
    value,
    onChange,
    forceCountry,
    displayPhone,
    error
  } = props;

  const [address, setAddress] = useState(value);
  const [stateOptions, setStateOptions] = useState(getStateOptions(address.country));
  const [countryConfig, setCountryConfig] = useState(getCountryConfig(address.country) || {});
  const [selectedState, setSelectedState] = useState(_.find(stateOptions, {value: address.state}));
  const isStateFieldVisible = Boolean(stateOptions?.length);

  const handleChange = useCallback((attribute) => (e) => {
    const newValue = e.target.value;
    const updatedAddress = produce(address, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setAddress(updatedAddress);
    onChange(attribute, newValue);
  }, [onChange, setAddress, address]);

  const renderCountryField = useCallback((params) => <TextField {...params} />, []);
  const handleChangeCountry = useCallback((countryId) => {
    setCountryConfig(getCountryConfig(countryId));
    setStateOptions(getStateOptions(countryId));
    onChange('country', countryId);
  }, [setStateOptions, onChange]);

  const checkStateOptionEquality = useCallback((opt, val) => _.isEqual(opt, val), []);
  const getStateOptionLabel = useCallback((opt) => opt.label || '', []);
  const renderStateField = useCallback((params) => <TextField {...params} placeholder={`${getFieldLabel(countryConfig, 'administrativearea') ?? 'State'}`} fullWidth />, [countryConfig]);
  const handleChangeState = useCallback((e, newValue) => {
    setSelectedState(newValue);
    onChange('state', typeof(newValue) === 'object' ? newValue.value : newValue);
  }, [onChange, setSelectedState]);
  
  useEffect(() => {
    if (forceCountry && address.country !== forceCountry) {
      const updatedAddress = produce(address, (draft) => {
        _.set(draft, 'country', forceCountry);
      });
      setAddress(updatedAddress);
      onChange('country', forceCountry);
    }
  }, [forceCountry, address, setAddress, onChange]);

  return (
    <Grid container spacing={2}>
      {!forceCountry && (
        <Grid item xs={12}>
          <CountrySelect
            value={address.country || ''}
            onChange={handleChangeCountry}
            renderInput={renderCountryField}
            error={error}
            sx={styles.AutoComplete}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          value={address.streetLine1 || ''}
          onChange={handleChange('streetLine1')}
          placeholder={`${getFieldLabel(countryConfig, 'thoroughfare') ?? 'Street Address'}`}
          fullWidth
          error={error}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={address.streetLine2 || ''}
          onChange={handleChange('streetLine2')}
          placeholder={`${getFieldLabel(countryConfig, 'premise') ?? 'Building, Floor, Suite, etc.'}`}
          fullWidth
          error={error}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={isStateFieldVisible ? 5 : 6}>
          <TextField
            value={address.city || ''}
            onChange={handleChange('city')}
            placeholder={`${getFieldLabel(countryConfig, 'localityname') ?? 'City'}`}
            fullWidth
            error={error}
          />
        </Grid>
        {isStateFieldVisible && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              autoHighlight
              options={stateOptions}
              value={selectedState}
              onChange={handleChangeState}
              getOptionLabel={getStateOptionLabel}
              isOptionEqualToValue={checkStateOptionEquality}
              renderInput={renderStateField}
              sx={styles.AutoComplete}
            />
          </Grid>
        )}
        <Grid item xs={12} md={isStateFieldVisible ? 3 : 6}>
          <TextField
            value={address.zipCode || ''}
            onChange={handleChange('zipCode')}
            placeholder={`${getFieldLabel(countryConfig, 'postalcode') ?? 'Zip'}`}
            fullWidth
            error={error}
          />
        </Grid>
      </Grid>
      {displayPhone && (
        <Grid container item spacing={2}>
          <Grid item xs={6} sm={3}>
            <TextField
              value={address.countryCode || ''}
              onChange={handleChange('countryCode')}
              placeholder='Country Code'
              InputProps={{
                startAdornment: <InputAdornment position='start'>+</InputAdornment>,
              }}
              fullWidth
              error={error}
            />
          </Grid>
          <Grid item xs={6} sm={9}>
            <TextField
              value={address.phone || ''}
              onChange={handleChange('phone')}
              placeholder='Phone Number'
              fullWidth
              error={error}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

AddressInput.defaultProps = {
  value: {
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    countryCode: '',
    phone: '',
  },
  forceCountry: false,
  displayPhone: true,
  error: false,
};

AddressInput.propTypes = {
  value: PropTypes.shape({
    streetLine1: PropTypes.string,
    streetLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zipCode: PropTypes.string,
    countryCode: PropTypes.string,
    phone: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  displayPhone: PropTypes.bool,
  forceCountry: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  error: PropTypes.bool,
};

export default AddressInput;
