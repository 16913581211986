import { useGetContactInfo, useGetMetadata } from '../../../_actions/application-form';

import Accelerator from './Accelerator';
import Buzzword from './Buzzword';
import DataLayerConfig from './DataLayerConfig';
import Description from './Description';
import Form from '../FormWrapper';
import Industry from './Industry';
import Keywords from './Keywords';
import MarketSize from './MarketSize';
import ShortPitch from './ShortPitch';
import { Typography } from '@mui/material';
import VideoUrl from './VideoUrl';
import { useParams } from 'react-router-dom';

function Metadata() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetMetadata(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  
  return (
    <Form title='Metadata' loaded={loaded && loadedContactInfo}>
      <Industry />
      <Description />
      <Keywords />
      <VideoUrl />
      <ShortPitch />
      <Typography variant='body1' fontStyle='italic'>
        The fields below are used to control which companies display on the homepage in which sections. Talk to a member of the Dev team before editing these.
      </Typography>
      <Buzzword />
      <MarketSize />
      <Accelerator />
      <DataLayerConfig />
    </Form>
  );
}

export default Metadata;