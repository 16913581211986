import { Box, Link } from '@mui/material';
import { Cell, EditField, Row } from '../../../../netcapital-components/Table';
import { useCallback, useState } from 'react';

import ModalInput from '../../../../netcapital-components/ModalInput';
import NumberInput from '../../../../netcapital-components/NumberInput';
import PropTypes from 'prop-types';
import { RADIO_OPTIONS } from '../../../../static-info';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import styles from '../styles';

const validateSecurityAmountOutstanding = (newAmountOutstanding, amountAuthorized) => {
  return Boolean(newAmountOutstanding > amountAuthorized);
};

export default function EquitySecurityRow({ index, onChange, onRemove, security }){
  const [openTerms, setOpenTerms] = useState(false);
  const toggleTerms = useCallback(() => setOpenTerms(!openTerms), [openTerms, setOpenTerms]);
  const handleRemoveEquitySecurityRow = useCallback(() => onRemove(index), [index, onRemove]);
  const handleChangeSecurityType = useCallback(e => onChange(index, {securityType: e.target.value}), [index, onChange]);
  const handleChangeSecurityAmount = useCallback(value => onChange(index, {securityAmount: value}), [index, onChange]);
  const handleChangeSecurityAmountOutstanding = useCallback(value => {
    const inputValid = validateSecurityAmountOutstanding(value, security.securityAmount);
    if(!inputValid){
      return;
    }
    onChange(index, {securityAmountOutstanding: value});
  }, [index, onChange, security.securityAmount]);
  const handleChangeHasVotingRights = useCallback(e => onChange(index, {hasVotingRights: e.target.value === RADIO_OPTIONS.YES_NO[0].value.toString()}), [index, onChange]);
  const handleChangeAdditionalRights = useCallback(value => onChange(index, {additionalRights: value}), [index, onChange]);
  const handleChangePriority = useCallback(value => onChange(index, {priority: value}), [index, onChange]);

  return (
    <Row onClickRemove={handleRemoveEquitySecurityRow}>
      <Cell>
        <Box sx={styles.EquityTableSizes.SecurityType}>
          <EditField value={security.securityType} type='text' placeholder='Class A, Common Stock' onChange={handleChangeSecurityType}/>
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.EquityTableSizes.SecurityAmount}>
          <NumberInput
            value={security.securityAmount}
            onChange={handleChangeSecurityAmount}
            placeholder='0'
            variant='standard'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.EquityTableSizes.SecurityAmountOutstanding}>
          <NumberInput
            value={security.securityAmountOutstanding}
            onChange={handleChangeSecurityAmountOutstanding}
            placeholder='0'
            variant='standard'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.EquityTableSizes.VotingRights}>
          <RadioGroup
            name='votingRights'
            value={security.hasVotingRights}
            onChange={handleChangeHasVotingRights}
            options={RADIO_OPTIONS.YES_NO}
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.EquityTableSizes.AdditionalRights}>
          <Link 
            color='primary.main'
            fontWeight='fontWeightMedium'
            onClick={toggleTerms} 
            sx={styles.HyperLink}
          >
            {security.additionalRights === '' ? '+ Add Terms' : 'View Terms'}
          </Link>
          <ModalInput
            onClose={toggleTerms}
            open={openTerms}
            value={security.additionalRights || ''}
            onChange={handleChangeAdditionalRights}
            title='Terms:'
          />
        </Box>
      </Cell>
      <Cell>
        <Box sx={styles.EquityTableSizes.Priority}>
          <NumberInput
            value={security.priority}
            onChange={handleChangePriority}
            placeholder='0'
            variant='standard'
          />
        </Box>
      </Cell>
    </Row>
  );
}

EquitySecurityRow.defaultProps = {
  additionalRights: '',
  hasVotingRights: false,
  priority: 0,
  securityAmount: 0,
  securityAmountOutstanding: 0,
  securityType: ''
};

EquitySecurityRow.propTypes = {
  additionalRights: PropTypes.string,
  hasVotingRights: PropTypes.bool,
  priority: PropTypes.number,
  securityAmount: PropTypes.number,
  securityAmountOutstanding: PropTypes.number,
  securityType: PropTypes.string
};