import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import TextFieldMUI from '@mui/material/TextField';
import styles from './styles';

const component = (props) => {
  const { alignRight, icon, isBoldFocus, isNoBorder, ownerState, variant, ...others } = props;
  
  if (!icon) {
    return (
      <TextFieldMUI
        {...others}
        variant={variant}
        ownerState={ownerState}
        sx={[isNoBorder && styles.NoBorder, alignRight && styles.AlignRight, isBoldFocus && styles.BoldFocus, styles.TextField]}
      />
    );
  }

  return (
    <Box sx={styles.Root} name='NC-TextField-root'>
      <Box sx={styles.IconContainer}>
        {React.cloneElement(icon, {sx: styles.IconCentered})}
      </Box>
      <Box sx={styles.TextWrapper}>
        <TextFieldMUI
          {...others}
          variant={variant}
          name='NC-TextField-component'
          ownerState={ownerState}
          sx={[isNoBorder && styles.NoBorder, alignRight && styles.AlignRight, isBoldFocus && styles.BoldFocus, styles.TextField, styles.TextFieldIcon]}
        />
      </Box>
    </Box>
  );
};

export const TextField = React.memo(component);

TextField.defaultProps = {
  alignRight: false,
  icon: false,
  isNoBorder: false,
  isBoldFocus: false,
  ownerState: { subVariant: '' },
  variant: 'outlined' //MUI default
};

TextField.propTypes = {
  alignRight: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  isNoBorder: PropTypes.bool,
  isBoldFocus: PropTypes.bool,
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  }),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard'])
};

        
export default React.memo(TextField);