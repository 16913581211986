const styles = {
  Title: ({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
      fontSize: '28px',
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '60px',
      display: 'block',
    }
  }),
};

export default styles;
