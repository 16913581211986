import { useCallback, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import countries from './countries';
import styles from './styles';

function CountrySelect(props) {
  const { onChange, value } = props;
  const [ country, setCountry ] = useState(countries.find(countryOption => countryOption.code === value) || null);

  const renderCountryField = useCallback((params) => <TextField {...params} inputProps={{ ...params.inputProps }} placeholder='Country' />, []);
  const getCountryOptionLabel = useCallback((option) => option.label || '', []);
  const renderCountryOption = useCallback((params, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
      <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt="" />
      {option.label} ({option.code}) +{option.phone}
    </Box>
  ), []);

  const handleChange = useCallback((e, newValue) => {
    setCountry(newValue);
    onChange(typeof(newValue) === 'object' ? newValue.code : newValue);
  }, [onChange, setCountry]);

  return (
    <Autocomplete
      disablePortal
      autoComplete
      autoHighlight
      autoSelect
      options={countries}
      onChange={handleChange}
      value={country}
      getOptionLabel={getCountryOptionLabel}
      renderOption={renderCountryOption}
      renderInput={renderCountryField}
      sx={styles.AutoComplete}
    />
  );
}

CountrySelect.defaultProps = {
  value: ''
};

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CountrySelect;
