import Table, { Body, Cell, Header } from '../../../netcapital-components/Table';
import { fetchKeyPeople, keyPersonState } from '../../../_state/application-form';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import KeyPersonRow from './KeyPersonRow';
import Loading from '../../Loading';
import { Typography } from '@mui/material';
import { useCreateKeyPerson } from '../../../_actions/application-form/key-person.actions';

function KeyPeopleTable() {
  const [keyPeople, setKeyPeople] = useRecoilState(fetchKeyPeople('keyPeople'));
  const newKeyPerson = useRecoilValue(keyPersonState);
  const navigate = useNavigate();
  const { listingKey, offerKey } = useParams();
  const { saved: savedNewKeyPerson, loading: isCreating, create: createKeyPerson } = useCreateKeyPerson(listingKey, offerKey);

  const addKeyPerson = useCallback(createKeyPerson, [createKeyPerson]);
  const onRemoveKeyPerson = useCallback((index) => {
    const copyKeyPeople = [...keyPeople];
    copyKeyPeople.splice(index, 1);
    setKeyPeople([...copyKeyPeople]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPeople]);
  
  useEffect(() => {
    if (!isCreating && savedNewKeyPerson && newKeyPerson) {
      navigate(newKeyPerson.key);
    }
  }, [isCreating, savedNewKeyPerson, newKeyPerson, navigate]);

  if (typeof keyPeople === 'undefined') return null;

  return (
    <Table onNewRow={addKeyPerson}>
      <Loading loading={isCreating} />
      <Header>
        <Cell>
          <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>First Name</Typography>
        </Cell>
        <Cell>
          <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Last Name</Typography>
        </Cell>
      </Header>
      <Body>
        {keyPeople?.map((person, index) => (
          <KeyPersonRow 
            keyPerson={person} 
            key={person.key} 
            row={index} 
            onRemoveKeyPerson={onRemoveKeyPerson}
          />
        ))}
      </Body>
    </Table>
  );
}

export default KeyPeopleTable;
