import { ListItemIcon, Stack, Typography } from '@mui/material';

import styles from '../styles';

const MenuLinkContent = (props) => {
  const { link, isSelected } = props;

  return (
    <Stack direction='row'>
      <ListItemIcon sx={[styles.MenuLink, !link.enabled && styles.ComingSoonIcon]}>{link.icon}</ListItemIcon>
      <Stack direction='column'>
        <Typography
          component="span"
          sx={[styles.MenuLink, isSelected && styles.MenuLinkActive]}>
          {link.name}
        </Typography>
        {!link.enabled && <Typography
          sx={[styles.MenuLink, styles.ComingSoon]}>(coming soon)
        </Typography>}
      </Stack>
    </Stack>
  );
};

export default MenuLinkContent;