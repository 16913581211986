import Question, { Answer } from '../../Question';
import { fetchContactInfo, fetchFinancialStatements } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FINANCIAL_STATEMENTS } from '../../../static-info/listing-application-forms';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { Typography } from '@mui/material';
import { useAutoSave } from '../../../_actions/application-form';
import { useCallback } from 'react';

function HasOperatingHistory() {
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [ hasOperatingHistory, setHasOperatingHistory ] = useRecoilState(fetchFinancialStatements('hasOperatingHistory'));
  const savingState = useAutoSave(FINANCIAL_STATEMENTS, 'hasOperatingHistory', hasOperatingHistory);
  const handleChangeHasOperatingHistory = useCallback((e) => setHasOperatingHistory(e.target.value === 'true'), [setHasOperatingHistory]);

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Does {doingBusinessAs || 'your company'} have an operating history?
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <RadioGroup
          name='hasOperatingHistory'
          value={hasOperatingHistory}
          onChange={handleChangeHasOperatingHistory}
          options={RADIO_OPTIONS.YES_NO}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default HasOperatingHistory;
