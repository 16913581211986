import { Link, AppBar as MUIAppBar, Toolbar, Typography } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '../../netcapital-components/UnderlinedButton';
import styles from './styles';
import { useParams } from 'react-router-dom';

const DialogBar = () => {
  const { offerKey } = useParams();

  return (
    <MUIAppBar sx={styles.AppBar}>
      <Toolbar sx={styles.Toolbar}>
        <Link href={offerKey ? `${process.env.REACT_APP_WEB_HOST}/dashboard/offerings/${offerKey}` : `${process.env.REACT_APP_WEB_HOST}/dashboard`} style={styles.Link}>
          <Button variant='text' color='primary' startIcon={<ArrowBackIcon color='primary' />} sx={styles.Button}>
            <Typography variant='button'>Back To Dashboard</Typography>
          </Button>
        </Link>;
      </Toolbar>
    </MUIAppBar>
  );
};

export default DialogBar;