import Question, { Answer, Description } from '../../Question';

import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchContactInfo } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function DoingBusinessAs() {
  const [doingBusinessAs, setDoingBusinessAs] = useRecoilState(fetchContactInfo('doingBusinessAs'));
  const handleChangeDoingBusinessAs = useCallback((e) => setDoingBusinessAs(e.target.value), [setDoingBusinessAs]);
  const savingState = useAutoSave(CONTACT_INFO, 'doingBusinessAs', doingBusinessAs);
  
  return (
    <Question>
      Do you have a brand name or doing business as (“dba”) name that you&apos;d like to display instead of your official company name?
      <Description>This name will appear anywhere we reference your company on netcapital.com. It will appear next to your legal name on any official forms.</Description>
      <Answer>
        <TextField 
          name="doingBusinessAs"
          value={doingBusinessAs || ''}
          onChange={handleChangeDoingBusinessAs}
          placeholder="My Company, Inc."
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}