const styles = {
  EquityTableSizes:{
    AdditionalRights: {
      width: '100px'
    },
    Priority: {
      width: '90px'
    },
    SecurityAmount: {
      width: '110px'
    },
    SecurityAmountOutstanding: {
      width: '110px'
    },
    SecurityType: {
      width: '165px'
    },
    VotingRights: {
      width: '75px'
    }
  },
  HyperLink: ({ palette }) => ({
    '&.MuiLink-root':{
      cursor: 'pointer',
      '&:hover':{
        color: palette.primary.dark,
      },
    },
  }),
  OptionSecurityTableSizes: {
    OptionDescription: {
      width: '200px'
    },
    OptionNum: {
      width: '200px'
    },
    OptionType: {
      width: '200px'
    }
  },
  SecurityDifferences: {
    paddingTop: '1rem',
  },
};

export default styles;