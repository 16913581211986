import {
  Avatar,
  Divider,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { Logout, Settings } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';

import Session from '../../../_session';
import { exitMimicMode } from '../../../_actions/session.actions';
import { styled } from '@mui/material/styles';
import styles from './styles';
import { useAlertActions } from '../../../_actions/alert.actions';
import { useCallback } from 'react';

const UserLinks = styled(Link)(styles.UserLinks);
  
const UserMenu = ({ handleClose, anchorEl, open }) => {
  const alertActions = useAlertActions();
  const location = useLocation();
  const navigate = useNavigate();
  const locationPathArray = location.pathname.split('/');
  const currentRoute = locationPathArray[locationPathArray.length - 1];
  const isMimicMode = Session.get('mimicMode');
  
  const handleExitMimicMode = useCallback(async () => {
    const res = await exitMimicMode();
    const routeMatchesNoPermissions = currentRoute === 'no-permission';

    if (res.response && res.response.status > 204) {
      alertActions.error('An error occurred', res.response, '', res.request);
      return;
    }
    if (res && res.status === 204){
      if(routeMatchesNoPermissions){
        window.location.href = `${process.env.REACT_APP_WEB_HOST}/admin/listings`;
      } else {
        navigate(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[alertActions]);
    
  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: styles.UserMenu,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem>
        <UserLinks href={`${process.env.REACT_APP_WEB_HOST}/dashboard`} target='_blank' rel='noopener'>
          <Avatar /> Dashboard
        </UserLinks>
      </MenuItem>
      {isMimicMode && (
        <MenuItem>
          <Link component='span' onClick={handleExitMimicMode}>Exit Mimic Mode</Link>
        </MenuItem>
      )}
      <Divider />
      <MenuItem>
        <UserLinks href={`${process.env.REACT_APP_WEB_HOST}/dashboard/settings`} target='_blank' rel='noopener'>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Settings
        </UserLinks>
      </MenuItem>
      <MenuItem>
        <UserLinks href={`${process.env.REACT_APP_WEB_HOST}/logout`} target='_blank' rel='noopener'>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </UserLinks>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;