import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TextField from '../../../netcapital-components/TextField';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonLinkedInProfile() {
  const { key: personKey } = useParams();
  const [ linkedinProfile, setLinkedinProfile ] = useRecoilState(fetchKeyPersonDetails('linkedinProfile'));
  const savingState = useAutoSave(KEY_PERSON, 'linkedinProfile', linkedinProfile, personKey);
  const onChangeLinkedinProfile = useCallback((e) => setLinkedinProfile(e.target.value), [setLinkedinProfile]);

  if (typeof linkedinProfile === 'undefined') return null;

  return (
    <TextField
      placeholder={'https://www.linkedin.com/in'}
      icon={<LinkedInIcon />}
      value={linkedinProfile}
      onChange={onChangeLinkedinProfile}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonLinkedInProfile;
