import Question, { Answer, Description } from '../../Question';

import { COMPANY_DESCRIPTION } from '../../../static-info/listing-application-forms';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchCompanyDescription } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function BusinessSummary() {
  const [businessSummary, setBusinessSummary] = useRecoilState(fetchCompanyDescription('businessSummary'));
  const savingState = useAutoSave(COMPANY_DESCRIPTION, 'businessSummary', businessSummary);
  const handleBusinessSummary = useCallback(e => setBusinessSummary(e.target.value), [setBusinessSummary]);

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        In a few paragraphs, please describe your company’s business. What are your company’s objectives? What plans do you have for reaching them? How will you monetize them? You may choose to include relevant background information about your company, technology, or team.
      </Typography>
      <Description>
        <Typography variant='body2'>
          The text you submit here is a required component of the <Help explanation={HELP['SEC']}>SEC</Help> forms we’ll file for you. This is not the text that will appear on your offering page at netcapital.com/companies/your-company. If you have a formal business plan, you can probably copy most of the information directly from there. <Typography variant='span' fontWeight='fontWeightMedium'>Please aim to make your description at least 500 words. Investors and the SEC love information!</Typography>
        </Typography>
      </Description>
      <Answer>
        <TextField
          multiline
          rows={10}
          value={businessSummary}
          onChange={handleBusinessSummary}
          placeholder='Describe your business'
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default BusinessSummary;
