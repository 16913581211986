import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const addKeysToListItems = (data) => {
  try {
    Object.keys(data).forEach((prop) => {
      if (Array.isArray(data[prop])) {
        data[prop] = data[prop].map(obj => {
          if (typeof obj === 'object') {
            obj._key = uuidv4();
          }
          return obj;
        });
      }
      else if (typeof data[prop] === 'object' && data[prop] !== null) {
        data[prop] = addKeysToListItems(data[prop]);
      }
    });
  }
  catch (e) {
    return data;
  }
  
  return data;
};

export const preparePayload = (data) => {
  function deepOmit(deepObj, keysToOmit) {
    const keysToOmitIndex =  _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit] );
  
    function omitFromObject(obj) {
      if (obj instanceof Date){
        return obj;
      }

      return _.transform(obj, (result, value, key) => {
        if (key in keysToOmitIndex) {
          return;
        }

        result[key] = _.isObject(value) ? omitFromObject(value) : value;
      });
    }

    return omitFromObject(deepObj);
  }

  return JSON.stringify(deepOmit(data, '_key'));
};
