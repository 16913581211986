import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchMetadata } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function Accelerator() {
  const [accelerator, setAccelerator] = useRecoilState(fetchMetadata('accelerator'));
  const savingState = useAutoSave(METADATA, 'accelerator', accelerator);
  const handleChangeAccelerator = useCallback(e => setAccelerator(e.target.value), [setAccelerator]);
  
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Accelerator (e.g. Techstars 2017)
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <TextField
          value={accelerator || ''}
          onChange={handleChangeAccelerator}
          ownerState={{ subVariant: 'admin' }}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default Accelerator;
