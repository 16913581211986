import { fetchKeyPeople, fetchKeyPersonDetails } from '../../../_state/application-form';

import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import _ from 'lodash';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonHasDirectorHistory() {
  const { key: personKey } = useParams();
  const [hasDirectorHistory, setHasDirectorHistory] = useRecoilState(fetchKeyPersonDetails('hasDirectorHistory'));
  const [keyPeople, setKeyPeople] = useRecoilState(fetchKeyPeople('keyPeople'));
  const savingState = useAutoSave(KEY_PERSON, 'hasDirectorHistory', hasDirectorHistory, personKey);
  
  const onChangeHasDirectorHistory = useCallback((e) => {
    const index = keyPeople.findIndex(person => person.key === personKey);
    const updatedKeyPersonValue = produce(keyPeople[index], (draft) => {
      _.set(draft, 'hasDirectorHistory', e.target.value === 'true');
    });
    const updatedKeyPeople = produce(keyPeople, (draft) => {
      draft[index] = updatedKeyPersonValue;
    });
    
    setHasDirectorHistory(e.target.value === 'true');
    setKeyPeople(updatedKeyPeople);
  },[keyPeople, personKey, setHasDirectorHistory, setKeyPeople]);

  if (typeof hasDirectorHistory === 'undefined') return null;

  return (
    <RadioGroup
      name='hasDirectorHistory'
      value={hasDirectorHistory}
      onChange={onChangeHasDirectorHistory}
      options={RADIO_OPTIONS.YES_NO}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonHasDirectorHistory;
