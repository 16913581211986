import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import styles from '../styles';
import { useCallback } from 'react';

function RelatedPartyTransactionsDialog({ handleClose, open }){
  const onClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <IconButton sx={styles.DialogIconButton} onClick={onClose}>
        <CloseIcon sx={styles.DialogClose}/>
      </IconButton>
      <DialogContent sx={styles.DialogContent}>
        <Box>
          <Typography variant='h1' sx={[styles.BlockSpan, styles.BlockSpan.Bold]}>Related Party Transactions:</Typography>
        </Box>
        <Box>
          <Typography variant='body1' sx={styles.BlockSpan}>Related-party transactions create potential conflicts of interest that may result in actions that benefit the related parties at the expense of the issuer or the investors. Thus, related-party transactions disclosure is required in order to assist investors in obtaining a more complete picture of the financial relationships between certain related parties and the issuer and provide additional insight as to potential uses of the issuer’s resources, including the proceeds of the offering. An issuer is required to disclose transactions with any person who is, as of the most recent practicable date but no earlier than 120 days prior to the date the offering statement or report is filed, the beneficial owner of 20 percent or more of the issuer’s outstanding voting equity securities.</Typography>
          <Typography variant='body1' sx={styles.BlockSpan}>In addition, issuers are required to disclose only related-party transactions that, in the aggregate, are in excess of five percent of the aggregate amount of capital raised by the issuer during the preceding 12-month period, inclusive of the amount the issuer seeks to raise in the current offering under Section 4(a)(6). Any series of similar transactions, arrangements, or relationships should be aggregated for purposes of determining whether related-party transactions should be disclosed.</Typography>
          <Typography variant='body1' sx={styles.BlockSpan}>For example, an issuer seeking to raise $1 million will be required to disclose related-party transactions that, in the aggregate, are in excess of $50,000, and an issuer that raises $250,000 will be required to disclose such transactions in excess of $12,500.</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RelatedPartyTransactionsDialog;

RelatedPartyTransactionsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};