import { InputAdornment, Stack, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Selectbox from '../../netcapital-components/Selectbox';
import styles from './styles';

const SORT_FILTERS = [
  {
    value: '',
    label: 'Default (None)' 
  },
  {
    value: 'az',
    label: 'Name of Investor (A - Z)',
  },
  {
    value: 'za',
    label: 'Name of Investor (Z - A)',
  },
  {
    value: 'oldest',
    label: 'Date of Investment (Oldest - Newest)',
  },
  {
    value: 'newest',
    label: 'Date of Investment (Newest - Oldest)',
  },
  {
    value: 'least-shares',
    label: 'Shares (Lowest - Highest)',
  },
  {
    value: 'most-shares',
    label: 'Shares (Highest - Lowest)',
  },
  {
    value: 'least-amount',
    label: 'Amount Invested (Lowest - Highest)',
  },
  {
    value: 'most-amount',
    label: 'Amount Invested (Highest - Lowest)',
  },
  {
    value: 'has-comments-first',
    label: 'Investors that have left comments first'
  },
];

const Filters = ({ investorName, handleChangeSearch, handleChangeSortBy, sortBy }) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon color='inherit' />
            </InputAdornment>
          ),
        }}
        onChange={handleChangeSearch}
        placeholder='Search for an Investor'
        value={investorName}
        variant='outlined'
        sx={styles.SearchBar}
      />
      <Stack direction='row' sx={styles.SortByContainer}>
        <Typography variant='body1' sx={styles.SortLabelBox}>Sort By</Typography>
        <Selectbox 
          onChange={handleChangeSortBy}
          options={SORT_FILTERS}
          placeholder='Filter'
          value={sortBy}
        />
      </Stack>
    </Stack>
  );
};

export default Filters;

Filters.defaultProps ={
  investorName: '',
  sortBy: '',
};

Filters.propTypes = {
  handleChangeSearch: PropTypes.func.isRequired,
  handleChangeSortBy: PropTypes.func.isRequired,
  investorName: PropTypes.string,
  sortBy: PropTypes.string,
};
