import { SHARES } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchShares } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function Valuation({ readOnly }) {
  const [valuationMethodology, setValuationMethodology] = useRecoilState(fetchShares('valuationMethodology'));
  const savingState = useAutoSave(SHARES, 'valuationMethodology', valuationMethodology);
  const handleChange = useCallback(e => setValuationMethodology(e.target.value), [setValuationMethodology]);

  return (
    <TextField
      multiline
      disabled={readOnly}
      rows={10}
      value={valuationMethodology || ''}
      onChange={handleChange}
      placeholder="Describe valuation methodology and provide examples of how the valuation was reached"
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default Valuation;
