import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonBio() {
  const { key: personKey } = useParams();
  const [ bio, setBio ] = useRecoilState(fetchKeyPersonDetails('bio'));
  const savingState = useAutoSave(KEY_PERSON, 'bio', bio, personKey);
  const onChangeBio = useCallback(e => setBio(e.target.value), [setBio]);

  if (typeof bio === 'undefined') return null;

  return (
    <TextField
      multiline
      rows={10}
      value={bio}
      onChange={onChangeBio}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonBio;
