const styles = {
  Avatar: ({ breakpoints }) => ({
    fontWeight: 600,
    fontSize: '16px',
    width: '40px',
    height: '40px',
    backgroundColor: 'hsl(138, 83%, 58%)',
    '&:hover': {
      backgroundColor: 'none',
    },
    [breakpoints.up('sm')]: {
      marginRight: '10px',
    },
  }),
  AvatarIconButton: {
    marginRight: '5px',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  UserLinks: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  UserMenu: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
  Username: () => ({
    textTransform: 'capitalize',
  }),
};

export default styles;