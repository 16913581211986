import Question, { Answer } from '../../Question';
import { useRecoilState, useRecoilValue } from 'recoil';

import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import PreviousNamesTable from './previousNamesTable';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function PreviousNames() {
  const [hasPreviousNames, setHasPreviousNames] = useRecoilState(fetchContactInfo('hasPreviousNames'));
  const savingState = useAutoSave(CONTACT_INFO, 'hasPreviousNames', hasPreviousNames);
  const handleChangeHasPreviousNames = useCallback((e) => setHasPreviousNames(e.target.value === 'true'), [setHasPreviousNames]);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));

  return (
    <>
      <Question>
        <Typography 
          variant='body1'
          color='primary.main'
          fontWeight='fontWeightMedium'
        >
          Has {doingBusinessAs || 'your company'} used any other names in the last five years?
        </Typography>
        <Answer>
          <RadioGroup
            name='hasPreviousNames'
            value={hasPreviousNames}
            onChange={handleChangeHasPreviousNames}
            options={RADIO_OPTIONS.YES_NO}
            error={savingState && savingState.state === 'error'}
          />
        </Answer>
      </Question>
      {hasPreviousNames && (
        <Question>
          <Typography 
            variant='body1'
            color='primary.main'
            fontWeight='fontWeightMedium'
          >
            What are those names?
          </Typography>
          <Answer>
            <PreviousNamesTable />
          </Answer>
        </Question>
      )}
    </>
  );
}
