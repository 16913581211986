import Question, { Answer } from '../../../Question';
import { Stack, Typography } from '@mui/material';
import { fetchBoardAuthorization, fetchKeyPeople } from '../../../../_state/application-form';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import DateSelector from '../../../../netcapital-components/DateSelector';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Selectbox from '../../../../netcapital-components/Selectbox';
import _ from 'lodash';
import produce from 'immer';
import styles from '../styles';

function SignaturesExecutive() {
  const keyPeople = useRecoilValue(fetchKeyPeople('keyPeople'));
  
  const keyPeopleOptions = useMemo(() => (keyPeople?.length
    ? keyPeople.map((person) => {
      return {
        key: person.key,
        value: person.key,
        label: `${person.firstName} ${person.lastName}`,
      };
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    : []),[JSON.stringify(keyPeople)]);
  
  const [signatures, setSignatures] = useRecoilState(fetchBoardAuthorization('signatures'));
  const handleChangeSignatures = useCallback((attribute) => (e) => {
    const newValue = e.target ? e.target.value : e;
    const updatedValues = produce(signatures, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setSignatures(updatedValues);
  }, [signatures, setSignatures]);

  return (
    <>
      <Typography variant='h2' fontWeight='fontWeightMedium' sx={styles.Subtitle}>
        Principal Executive Officers
      </Typography>
      {(!keyPeople || keyPeople.length === 0) ? 
        <Typography variant='body2' color='error'>No designated key people.</Typography>
        :
        <Stack spacing={2}>
          <Question>
            <Answer 
              helperColor='warning.main'
              helperContent='Admin Only'
              helperIcon={<ErrorOutlineOutlinedIcon />}
              helperPosition='bottom'
              sx={styles.SignatureRow}
            >
              <Typography sx={styles.SignatureRowExecutiveLabel}>Principal Executive Officer</Typography>
              <Selectbox
                options={keyPeopleOptions}
                value={_.get(signatures, 'executive.entityKey') || ''}
                onChange={handleChangeSignatures('executive.entityKey')}
                placeholder='Signator'
                ownerState={{ subVariant: 'admin' }}
              />
              <DateSelector
                value={_.get(signatures, 'executive.date') || ''}
                onChange={handleChangeSignatures('executive.date')}
                ownerState={{ subVariant: 'admin' }}
              />
            </Answer>
          </Question>
          <Question>
            <Answer 
              helperColor='warning.main'
              helperContent='Admin Only'
              helperIcon={<ErrorOutlineOutlinedIcon />}
              helperPosition='bottom'
              sx={styles.SignatureRow}
            >
              <Typography sx={styles.SignatureRowExecutiveLabel}>Principal Financial Officer</Typography>
              <Selectbox
                options={keyPeopleOptions}
                value={_.get(signatures, 'financial.entityKey') || ''}
                onChange={handleChangeSignatures('financial.entityKey')}
                placeholder='Signator'
                ownerState={{ subVariant: 'admin' }}
              />
              <DateSelector
                value={_.get(signatures, 'financial.date') || ''}
                onChange={handleChangeSignatures('financial.date')}
                ownerState={{ subVariant: 'admin' }}
              />
            </Answer>
          </Question>
          <Question>
            <Answer 
              helperColor='warning.main'
              helperContent='Admin Only'
              helperIcon={<ErrorOutlineOutlinedIcon />}
              helperPosition='bottom'
              sx={styles.SignatureRow}
            >
              <Typography sx={styles.SignatureRowExecutiveLabel}>Principal Accounting Officer</Typography>
              <Selectbox
                options={keyPeopleOptions}
                value={_.get(signatures, 'accounting.entityKey') || ''}
                onChange={handleChangeSignatures('accounting.entityKey')}
                placeholder='Signator'
                ownerState={{ subVariant: 'admin' }}
              />
              <DateSelector
                value={_.get(signatures, 'accounting.date') || ''}
                onChange={handleChangeSignatures('accounting.date')}
                ownerState={{ subVariant: 'admin' }}
              />
            </Answer>
          </Question>
        </Stack>
      }
    </>
  );
}

export default SignaturesExecutive;