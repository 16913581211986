export const mapEquitySecuritiesToSelectOptions = (equitySecurities) => {
  const options = [];
  if(!equitySecurities){
    return false;
  }
  equitySecurities.forEach((security) => {
    const availableSharesOrUnits = security.securityAmount - security.securityAmountOutstanding;
    options.push({value: security.securityType, label: `${security.securityType} (${availableSharesOrUnits} left)`});
  });
  
  return options;
};
  
export const canSecurityTypeBeSold = (securityTypeToBeSold, equitySecurities) => {
  return equitySecurities.findIndex(security => security.securityType === securityTypeToBeSold) > -1;
};