import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const checkbox = ({label, sx, size, checked, onChange}) => {
  return (
    <FormControlLabel
      label={label}
      sx={checked ? styles.Label.Default : {...styles.Label.Default, ...styles.Label.Unchecked}}
      control={
        <MUICheckbox
          checked={checked}
          checkedIcon={<CheckIcon />}
          sx={sx ? ({...sx}, styles.Checkbox) : styles.Checkbox}
          onChange={onChange}
          size={size}
          disableRipple 
        />
      } 
    />
  );
};

export const Checkbox = React.memo(checkbox);

Checkbox.defaultProps = {
  size: 'small',
  sx: {}
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape({
    Label: PropTypes.shape({
      Default: PropTypes.shape({
        margin: PropTypes.number
      }),
      Unchecked: PropTypes.shape({
        color: PropTypes.string
      })
    }),
    CheckBox: PropTypes.shape({
      color: PropTypes.string,
      '&.Mui-checked': PropTypes.shape({
        color: PropTypes.string
      })
    })
  }),
  size: PropTypes.oneOf(['small','medium'])
};

export default Checkbox;