import {
  AGREEMENTS,
  BOARD_AUTHORIZATION,
  CAP_TABLE,
  COMPANY_DESCRIPTION,
  COMPANY_FORMATION,
  COMPLIANCE,
  CONTACT_INFO,
  FINANCIAL_CONDITION,
  FINANCIAL_REPORT,
  FINANCIAL_STATEMENTS,
  FUNDING_HISTORY,
  KEY_PEOPLE,
  KEY_PERSON,
  METADATA,
  RISKS,
  SECURITIES,
  SHARES,
  SOCIAL_MEDIA,
  UOP
} from '../../static-info/listing-application-forms';
import { Navigate, Route, Routes } from 'react-router-dom';

import Agreements from '../../components/application-form/Agreements';
import BoardAuthorization from '../../components/application-form/BoardAuthorization';
import CapTable from '../../components/application-form/CapTable';
import CompanyDescription from '../../components/application-form/CompanyDescription';
import CompanyFormation from '../../components/application-form/CompanyFormation';
import Compliance from '../../components/application-form/Compliance';
import ContactInformation from '../../components/application-form/ContactInformation';
import FinancialCondition from '../../components/application-form/FinancialCondition';
import FinancialReport from '../../components/application-form/FinancialReport';
import FinancialStatements from '../../components/application-form/FinancialStatements';
import FundingHistory from '../../components/application-form/FundingHistory';
import KeyPeople from '../../components/application-form/KeyPeople';
import KeyPerson from '../../components/application-form/KeyPerson';
import Metadata from '../../components/application-form/Metadata';
import Risks from '../../components/application-form/Risks';
import Securities from '../../components/application-form/Securities';
import Shares from '../../components/application-form/Shares';
import SocialMedia from '../../components/application-form/SocialMedia';
import UseOfProceeds from '../../components/application-form/UseOfProceeds';
import { applicationFormRouteMap } from './routeMap';
import { history } from '../../_helpers';
import { useRecoilValue } from 'recoil';
import { userRoleState } from '../../_state/user';

const routesComponents = {
  [CONTACT_INFO]: <ContactInformation />,
  [SOCIAL_MEDIA]: <SocialMedia />,
  [COMPANY_FORMATION]: <CompanyFormation />,
  [COMPANY_DESCRIPTION]: <CompanyDescription />,
  [FUNDING_HISTORY]: <FundingHistory />,
  [CAP_TABLE]: <CapTable />,
  [SECURITIES]: <Securities />,
  [KEY_PERSON]: <KeyPerson />,
  [KEY_PEOPLE]: <KeyPeople />,
  [SHARES]: <Shares />,
  [UOP]: <UseOfProceeds />,
  [RISKS]: <Risks />,
  [COMPLIANCE]: <Compliance />,
  [FINANCIAL_CONDITION]: <FinancialCondition />,
  [FINANCIAL_STATEMENTS]: <FinancialStatements />,
  [FINANCIAL_REPORT]: <FinancialReport />,
  [BOARD_AUTHORIZATION]: <BoardAuthorization />,
  [AGREEMENTS]: <Agreements />,
  [METADATA]: <Metadata />
};

export default function FormRoutes() {
  const userRole = useRecoilValue(userRoleState);
  
  return (
    <Routes history={history}>
      {applicationFormRouteMap(userRole).filter((route) => route.hasViewPermission).map(route => {
        return <Route path={route.pathname} element={routesComponents[route.formId]} key={route.formId} />;
      })}
      <Route path="*" element={<Navigate to={CONTACT_INFO} replace />} />
    </Routes>
  );
}