import { atom, selector } from 'recoil';

const userState = atom({
  key: 'userState',
  default: {}
});

const userRoleState = selector({
  key: 'userRoleState',
  get: ({get}) => get(userState)?.userRole,
  set: ({get, set}, newValue) => set(userState, { ...get(userState), ...{userRole: newValue}})
});

const isAdminState = selector({
  key: 'isAdminState',
  get: ({get}) => get(userState)?.userRole === 'ADMIN'
});

const isAuthenticatedState = selector({
  key: 'isAuthenticatedState',
  get: ({get}) => get(userState)?.isAuthenticated,
  set: ({get, set}, newValue) => set(userState, { ...get(userState), ...{isAuthenticated: newValue}})
});

export { 
  userState,
  userRoleState,
  isAdminState,
  isAuthenticatedState
};