import { atom, selectorFamily } from 'recoil';

const questionnairesState = atom({
  key: 'QuestionnairesState',
  default: {}
});

const fetchQuestionnaires = selectorFamily({
  key: 'fetchQuestionnairesState',
  get: field => ({ get }) => get(questionnairesState)[field],
  set: field => ({set}, newValue) =>
    set(questionnairesState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchQuestionnaires,
  questionnairesState,
};