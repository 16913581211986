import { DateFormat, NumberFormat } from '../../_helpers/formatters';
import { IconButton, Typography } from '@mui/material';

import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import styles from './styles';

export const BANNER_DISPLAYS = {
  'DRAFT':{
    severity: 'info',
    icon: <LockOutlinedIcon sx={styles.InfoDark} />,
  },
  'PUBLISHED':{
    severity: 'success',
    icon: <TimerOutlinedIcon sx={styles.SuccessDark} />,
  },
  'FULFILLED':{
    severity: 'info', 
    icon: <LockOutlinedIcon sx={styles.InfoDark} />,
  },
  'POST_PROCESSING':{
    severity: 'info',
    icon: <LockOutlinedIcon sx={styles.InfoDark} />,
  },
  'FAILED':{
    severity: 'error',
    icon: <LockOutlinedIcon sx={styles.ErrorDark} />,
  },
};

export const generateBannerDescription = (offerStatus, startDateAndTime, endDateAndTime) => {
  let description = '';
  const formattedStartDate = DateFormat.formatEst(startDateAndTime);
  const formattedEndDate = DateFormat.formatEst(endDateAndTime);
  
  if(offerStatus === 'POST_PROCESSING' || offerStatus === 'FULFILLED'){
    description = <Typography variant='body2' color='info.dark'>This offering was finalized on <Typography variant='span' fontWeight='fontWeightMedium'>{formattedEndDate}</Typography></Typography>;
  }
  
  if(offerStatus === 'PUBLISHED'){
    description = <Typography variant='body2' color='success.dark'>Offering has started on <Typography variant='span' fontWeight='fontWeightMedium'>{formattedStartDate}</Typography> and will be open until <Typography variant='span' fontWeight='fontWeightMedium'>{formattedEndDate}</Typography></Typography>;
  } 

  if(offerStatus === 'DRAFT'){
    description = <Typography variant='body2' color='info.dark'>This offering is in a draft state and has no investors</Typography>;
  }

  if(offerStatus === 'FAILED'){
    description = <Typography variant='body2' color='error.dark'>This offering has been closed or withdrawn</Typography>;
  }
  
  return description;
};

export const generateQandAModalContent = (investors, selectedIndex) => {
  let question1 = '';
  let question2 = '';
  let answer1 = '';
  let answer2 = '';

  if(investors?.length && selectedIndex >= 0 && investors[selectedIndex] && investors[selectedIndex].questions?.length === 2){
    question1 = investors[selectedIndex].questions[0].question;
    question2 = investors[selectedIndex].questions[1].question;
    answer1 = investors[selectedIndex].questions[0].response || '';
    answer2 = investors[selectedIndex].questions[1].response || '';
    
    return (
      <>
        <img src={`${process.env.REACT_APP_CDN_IMAGES}/_icons/comment-purple-lg.svg`} alt='Questions and Answers' />
        {answer1 && (
          <>
            <Typography variant='h3' color='primary.main'>{question1}</Typography>
            <Typography variant='body1'>{answer1}</Typography>
          </>
        )}
        {answer2 && (
          <>
            <Typography variant='h3' color='primary.main'>{question2}</Typography>
            <Typography variant='body1'>{answer2}</Typography>
          </>
        )}
      </>
    );
  } else {
    return null;
  }
};

export const generateInvestorsTableColumns = (isSmallerThanTablet, handleToggleQandADisplayModal) => {
  //GridColDef- can't use {flex: (0-infinity)} for responsive widths because then the table loses its scrollX behavior for smaller screens (can't exceed past maximum viewport width). We have to use hardcoded widths here it seems to retain scrollX behavior. Docs: https://mui.com/x/react-data-grid/column-dimensions/
  const tableColumns = [
    {
      field: 'commitmentDate',
      headerName: 'Date',
      width: 115,
      editable: false,
      renderHeader: () => {
        return (
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary.main'>Date</Typography>
        );
      },
      renderCell: (params) => {
        const commitmentDate = new Date(Date.parse(params.formattedValue)).toDateString().substring(3);
        return <Typography variant='body1'>{commitmentDate}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      //dynamically generated width below
      editable: false,
      renderHeader: () => {
        return (
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary.main'>Name</Typography>
        );
      },
      renderCell: (params) => {
        const investorName = params.formattedValue;
        return <Typography variant='body1' fontWeight='fontWeightMedium'>{investorName}</Typography>;
      },
    },
    {
      field: 'shares',
      headerName: 'Shares',
      width: 100,
      editable: false,
      renderHeader: () => {
        return (
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary.main'>Shares</Typography>
        );
      },
      renderCell: (params) => {
        const shares = params.formattedValue;
        return <Typography variant='body1'>{shares}</Typography>;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 115,
      editable: false,
      renderHeader: () => {
        return (
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary.main'>Amount</Typography>
        );
      },
      renderCell: (params) => {
        const amount = params.formattedValue;
        return (
          <Typography variant='body1'>{NumberFormat.dollars(amount)}</Typography>
        );
      },
    },
    {
      field: 'questions',
      headerName: 'Comments',
      align: 'center',
      width: 85,
      editable: false,
      renderHeader: () => {
        return (
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary.main'>Comments</Typography>
        );
      },
      renderCell: (params) => {
        const questionsAndAnswers = params.formattedValue; //array length 1 or 2 [{question: '', response: ''}, {...}]
        const targetId = params.id;

        //don't display IconButton to open Q&A modal if user hasn't answered questions/left comments
        if(!questionsAndAnswers){ 
          return null;
        } 
        
        return (
          <IconButton onClick={(e) => { handleToggleQandADisplayModal(e, targetId); }}>
            <InsertCommentOutlinedIcon color='primary' />
          </IconButton>
        );
      },
    },
  ];

  const nameColumn = tableColumns[1];

  if(isSmallerThanTablet){
    nameColumn.width = 325;
  } 

  if(!isSmallerThanTablet){
    nameColumn.flex = 1;
  }

  return tableColumns;
};