import Question, { Answer } from '../../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileField from '../../FileField';
import { Typography } from '@mui/material';
import { fetchSecuritiesFile } from '../../../../_state/application-form';

function PrivateOfferTerms(){
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        Attach Private Offer Terms (i.e. Promisory Note Agreement, if applicable)
      </Typography>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='bottom'
      >
        <FileField
          state={fetchSecuritiesFile}
          usage='PRIVATE_OFFER_TERMS'
        />
      </Answer>
    </Question>
  );
}

export default PrivateOfferTerms;