import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { Radio } from './radio';
import React from 'react';
import styles from './styles';

const radioButton = ({value, label, disabled}) => <FormControlLabel disabled={disabled} value={value} control={<Radio />} label={label} sx={styles.RadioButton} />;

export const RadioButton = React.memo(radioButton);

RadioButton.defaultProps = {
  value: 'initial',
  label: 'initial',
  disabled: false
};

RadioButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default RadioButton;