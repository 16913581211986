import { Link, Typography } from '@mui/material';
import { fetchFormGlobals, fetchShares } from '../../../_state/application-form';
import { useGetFinancialStatements, useGetShares } from '../../../_actions/application-form';

import AdditionalInformation from './AdditionalInformation';
import AnnualReport from './AnnualReport';
import BalanceSheet from './BalanceSheet';
import CashFlowStatement from './CashFlowStatement';
import ChangeInOwnersEquityStatement from './ChangeInOwnersEquityStatement';
import EquivalentLineItems from './EquivalentLineItems';
import ExecutiveCertification from './ExecutiveCertification';
import FiscalYears from './FiscalYears';
import Form from '../FormWrapper';
import HasOperatingHistory from './HasOperatingHistory';
import IncomeStatement from './IncomeStatement';
import SignedAuditReport from './SignedAuditReport';
import SignedLetterOfReview from './SignedLetterOfReview';
import { isAdminState } from '../../../_state/user';
import { isRegCF } from '../../../_helpers/exemptions';
import styles from './styles';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function FinancialStatements() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetFinancialStatements(listingKey, offerKey);
  const { loaded: loadedShares } = useGetShares(listingKey, offerKey);
  const reviewLevel = useRecoilValue(fetchShares('reviewLevel'));
  const isAdmin = useRecoilValue(isAdminState);
  const isRegCfOffering = isRegCF(useRecoilValue(fetchFormGlobals('offerExemptions')));

  return (
    <Form title='Financial Statements' loaded={loaded && loadedShares}>
      <Typography variant='body1' sx={styles.Paragraph}>Upload complete financial statements for your company and its predecessors covering the period from your company’s formation until now.</Typography>
      {isRegCfOffering && (
        <Typography variant='body1' sx={styles.Paragraph}>If you plan to raise more than $124,000, an independent CPA will have to review your statements. If you don’t have an accountant in mind, we can recommend accountants who work with companies like yours. Just <Link target='_blank' href='mailto:help@netcapital.com' rel='noreferrer' sx={styles.Link}>email us</Link>. Sometimes these things take a while, so start now.</Typography>
      )}
      {(!reviewLevel && !isAdmin) && (
        <Typography variant='body1' color='error'>
          To determine which financial documents are needed, please specify your <Link href={`/listings/${listingKey}/offers/${offerKey}/shares-and-pricing`}>financial review status</Link>.
        </Typography>
      )}
      {isAdmin && (
        <Typography variant='body1' fontStyle='italic' sx={styles.Paragraph}><b>For Netcapital Admins:</b> In addition to the purely admin-facing fields, you have the ability to see every document upload slot in this form, regardless of the issuer’s financial review status (<Link href={`/listings/${listingKey}/offers/${offerKey}/shares-and-pricing`} sx={styles.Link}>See Shares & Pricing Form - 1st question</Link>). You can remove old files from previous offerings that are no longer relevant for the issuer’s current raise, to prevent such documents from appearing on their Offering Statement.</Typography>
      )}
      {(reviewLevel === 'REVIEWED' || reviewLevel === 'SELF' || isAdmin) && (
        <SignedLetterOfReview />
      )}
      {(reviewLevel === 'AUDITED' || isAdmin) && (
        <SignedAuditReport />
      )}
      {(reviewLevel === 'SELF' || isAdmin) && (
        <>
          <IncomeStatement />
          <BalanceSheet />
          <CashFlowStatement />
          <ChangeInOwnersEquityStatement />
          <ExecutiveCertification />
        </>
      )}
      {isAdmin && (
        <>
          <AnnualReport />
          <AdditionalInformation />
          <HasOperatingHistory />
        </>
      )}
      {(reviewLevel === 'SELF' || isAdmin) && (
        <EquivalentLineItems />
      )}
      {isAdmin && (
        <FiscalYears />
      )}
    </Form>
  );
}

export default FinancialStatements;
