const styles = {
  BlockSpan: {
    display: 'block',
    marginBottom: '10px',
    Bold: {
      fontWeight: '600',
    },
  },
  DialogContent: () => ({
    width: '100%',
    padding: '40px 50px',
  }),
  DialogIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  DialogCloseIcon: {
    height: '20px',
    width: '20px',
  },
  Hyperlink: () => ({
    '&.MuiLink-root':{
      cursor: 'pointer',
    },
  }),
};

export default styles;