import { Cell, Row } from '../../../../netcapital-components/Table';

import MoneyInput from '../../../../netcapital-components/MoneyInput';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

export default function EquivalentLineItemRow({ onChangeTaxableIncome, onChangeTaxesPaid, onChangeTotalIncome, taxableIncome, taxesPaid, totalIncome, errorTotalIncome, errorTaxableIncome, errorTaxesPaid }) {
  const handleTotalIncomeChange = useCallback((value) => onChangeTotalIncome(value), [onChangeTotalIncome]);
  const handleTaxableIncomeChange = useCallback((value) => onChangeTaxableIncome(value), [onChangeTaxableIncome]);
  const handleTaxesPaidChange = useCallback((value) => onChangeTaxesPaid(value), [onChangeTaxesPaid]);

  return (
    <Row onClickRemove={null}>
      <Cell>
        <MoneyInput value={totalIncome} placeholder={'$0.00'} onChange={handleTotalIncomeChange} error={errorTotalIncome} isNoBorder />
      </Cell>
      <Cell>
        <MoneyInput value={taxableIncome} placeholder={'$0.00'} onChange={handleTaxableIncomeChange} error={errorTaxableIncome} isNoBorder />
      </Cell>
      <Cell>
        <MoneyInput value={taxesPaid} placeholder={'$0.00'} onChange={handleTaxesPaidChange} error={errorTaxesPaid} isNoBorder />
      </Cell>
    </Row>
  );
}

EquivalentLineItemRow.defaultProps = {
  taxableIncome: 0,
  taxesPaid: 0,
  totalIncome: 0,
  errorTotalIncome: false,
  errorTaxableIncome: false,
  errorTaxesPaid: false,
};

EquivalentLineItemRow.propTypes = {
  onChangeTaxableIncome: PropTypes.func.isRequired,
  onChangeTaxesPaid: PropTypes.func.isRequired,
  onChangeTotalIncome: PropTypes.func.isRequired,
  taxableIncome: PropTypes.number,
  taxesPaid: PropTypes.number,
  totalIncome: PropTypes.number,
  errorTotalIncome: PropTypes.bool,
  errorTaxableIncome: PropTypes.bool,
  errorTaxesPaid: PropTypes.bool,
};