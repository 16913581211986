import PrivateOfferTerms from './PrivateOfferTerms';
import SecurityClasses from './SecurityClasses';
import SecurityDifferences from './SecurityDifferences';
import SecurityTypes from './SecurityTypes';
import { Typography } from '@mui/material';
import { fetchSecurities } from '../../../../_state/application-form';
import { useRecoilValue } from 'recoil';

function EquitySecurities() {
  const equitySecurities = useRecoilValue(fetchSecurities('equitySecurities'));
  
  return (
    <>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Equity Securities</Typography>
      <SecurityClasses />
      {equitySecurities && (
        <SecurityTypes />
      )}
      <PrivateOfferTerms />
      <SecurityDifferences />
    </>
  );
}

export default EquitySecurities;