import { RISKS } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { fetchRisks } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function MinorityOwnershipRisks() {
  const [minorityOwnershipRisks, setMinorityOwnershipRisks] = useRecoilState(fetchRisks('minorityOwnershipRisks'));
  const savingState = useAutoSave(RISKS, 'minorityOwnershipRisks', minorityOwnershipRisks);
  const handleChange = useCallback(e => setMinorityOwnershipRisks(e.target.value), [setMinorityOwnershipRisks]);

  return (
    <TextField
      multiline
      rows={10}
      value={minorityOwnershipRisks}
      onChange={handleChange}
      placeholder='Risks of minority ownership'
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default MinorityOwnershipRisks;
