import { atom, selector, selectorFamily } from 'recoil';

import _ from 'lodash';

const sharesState = atom({
  key: 'sharesState',
  default: {}
});

const fetchShares = selectorFamily({
  key: 'fetchShares',
  get: field => ({get}) => _.get(get(sharesState), field),
  set: field => ({set}, newValue) =>
    set(sharesState, prevState => ({...prevState, [field]: newValue})),
});

const raiseAmountState = selector({
  key: 'raiseAmountState',
  get: ({get}) => {
    const numShares = _.get(get(sharesState), 'totalQuantity', 0);
    const pricePerUnit = _.get(get(sharesState), 'pricePerUnit', 0);
    return numShares * pricePerUnit;
  }
});

const proceeds4a6State = selector({
  key: 'proceeds4a6State',
  get: ({get}) => {
    const totalNonAccredited = _.get(get(sharesState), 'totalNonAccredited', 0);
    const pricePerUnit = _.get(get(sharesState), 'pricePerUnit', 0);
    return totalNonAccredited * pricePerUnit;
  }
});

export {
  sharesState,
  fetchShares,
  proceeds4a6State,
  raiseAmountState
};
