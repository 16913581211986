import SecurityClasses from './SecurityClasses';
import { Typography } from '@mui/material';

function WarrantsOptionsOtherRights(){
  return (
    <>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Warrants, Options and Other Rights</Typography>
      <SecurityClasses />
    </> 
  );
}

export default WarrantsOptionsOtherRights;