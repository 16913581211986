import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const socialMediaState = atom({
  key: 'socialMediaState',
  default: {}
});

const fetchSocialMedia = selectorFamily({
  key: 'fetchSocialMedia',
  get: field => ({get}) => _.get(get(socialMediaState), field),
  set: field => ({set}, newValue) =>
    set(socialMediaState, prevState => ({...prevState, [field]: newValue})),
});

export {
  socialMediaState,
  fetchSocialMedia,
};
