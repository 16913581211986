import MUITableCell from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import React from 'react';
import { styled } from '@mui/material/styles';
import styles from './styles';

const StyledTableCell = styled(MUITableCell)(styles.TableCell);

const component = ({children}) => (
  <StyledTableCell name='Netcapital-Table-Cell'>
    {children}
  </StyledTableCell>
);

export const Cell = React.memo(component);

Cell.defaultProps = {
  children: ''
};

Cell.propTypes = {
  children: PropTypes.node
};

export default Cell;