import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const contactInfoState = atom({
  key: 'ContactInfoState',
  default: {}
});

const fetchContactInfo = selectorFamily({
  key: 'fetchContactInfo',
  get: field => ({get}) => _.get(get(contactInfoState), field),
  set: field => ({set}, newValue) =>
    set(contactInfoState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchContactInfo,
  contactInfoState,
};
