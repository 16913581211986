import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import styles from './styles';

const component = (props) => {
  const { icon, iconPosition, content, color } = props;
  
  return (
    <Typography 
      variant='div' 
      color={color} 
      sx={styles.HelperText}
    >
      {icon && iconPosition === 'left' ? icon : null}
      {content}
      {icon && iconPosition === 'right' ? icon : null}
    </Typography>
  );
};

export const HelperText = React.memo(component);

HelperText.defaultProps = {
  icon: '',
  iconPosition: 'left'
};

HelperText.propTypes = {
  color: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconPosition: PropTypes.string
};
   
export default React.memo(HelperText);