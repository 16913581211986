import { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/EditOutlined';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import Stack from '@mui/material/Stack';
import TextField from '../TextField';
import { Typography } from '@mui/material';
import styles from './styles';

function ModalInput({ onClose, open, value, onChange, title, defaultValue }) {
  const [isEdit, setEdit] = useState(value === defaultValue);
  const [bodyValue, setValue] = useState(value);
  const enableEdit = useCallback(() => setEdit(true), [setEdit]);
  const stopEdit = useCallback(() => setEdit(false), [setEdit]);
  const handleValueChange = useCallback(e => setValue(e.target.value), [setValue]);

  const handleSave = useCallback(() => {
    onChange(bodyValue);
    stopEdit();
  }, [bodyValue, onChange, stopEdit]);

  const handleClose = useCallback(() => {
    stopEdit();
    setValue(value);
    onClose();
  }, [onClose, value, setValue, stopEdit]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <IconButton sx={styles.DialogIconButton} onClick={onClose}>
        <CloseIcon sx={styles.DialogClose}/>
      </IconButton>
      <DialogContent sx={styles.DialogContent}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          <Typography variant='h1'>{title}</Typography>
          {!isEdit && (
            <Link onClick={enableEdit} sx={[styles.DialogOption, styles.DialogEditOption]}>
              <EditIcon sx={styles.DialogIcon} /> 
              Edit
            </Link>
          )}
          {isEdit && (
            <Link onClick={handleSave} sx={[styles.DialogOption, styles.DialogSaveOption]}>
              <SaveIcon sx={styles.DialogIcon} /> 
              Save Changes
            </Link>
          )}
        </Stack>
        <TextField
          fullWidth
          multiline
          margin='dense'
          disabled={!isEdit}
          minRows={10}
          value={bodyValue}
          onChange={handleValueChange}
          variant='outlined'
        />
      </DialogContent>
    </Dialog>
  );
}

ModalInput.defaultProps = {
  defaultValue: ''
};

ModalInput.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

export default ModalInput;