import { fetchCompliance, fetchContactInfo, fetchFormGlobals } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { COMPLIANCE } from '../../../static-info/listing-application-forms';
import Checkbox from '../../../netcapital-components/Checkbox';
import { isRegA } from '../../../_helpers/exemptions';
import { useAutoSave } from '../../../_actions/application-form';
import { useCallback } from 'react';

export default function FilingAgreement() {
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const [hasFilingAgreement, setHasFilingAgreement] = useRecoilState(fetchCompliance('hasFilingAgreement'));
  const savingState = useAutoSave(COMPLIANCE, 'hasFilingAgreement', hasFilingAgreement);
  const handleChangeHasFilingAgreement = useCallback(() => setHasFilingAgreement(!hasFilingAgreement), [setHasFilingAgreement, hasFilingAgreement]);
  const isRegAOffering = isRegA(useRecoilValue(fetchFormGlobals('offerExemptions')));
  const label = isRegAOffering
    ? `By checking this box you authorize and designate Netcapital to file the SEC’s Form ID, Form 1-A, and/or Form D on behalf of ${doingBusinessAs || 'your company'} for the next 365 days.`
    : `By checking this box you authorize and designate Netcapital to file the SEC’s Form ID, Form C, and Form D on behalf of ${doingBusinessAs || 'your company'} for the next 365 days.`;
  
  if (typeof hasFilingAgreement === 'undefined') return null;
  
  return (
    <Checkbox
      label={label}
      checked={Boolean(hasFilingAgreement)}
      onChange={handleChangeHasFilingAgreement}
      error={savingState && savingState.state === 'error'}
    />
  );
}