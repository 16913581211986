import { Cell, Header } from '../../../../netcapital-components/Table';

import { Typography } from '@mui/material';

function FiscalYearsTableHeader(){
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Fiscal Year End Date</Typography>
      </Cell>
    </Header>
  );
}

export default FiscalYearsTableHeader;