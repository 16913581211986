const styles = {
  Explain: () => ({
    fontWeight: 600,
    fontSize: 'inherit',
    cursor: 'pointer',
  }),
  HelpText: () => ({
    padding: '20px',
    width: '300px',
  }),
};

export default styles;