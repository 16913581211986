import {
  NETCAPITAL_FEE,
  TOTAL_MIN_ALLOCATION,
  calculate4a6MaxUnallocatedFunds,
  calculateAmount4a6MaxTotal,
  calculateAmountMaxTotal,
  calculateAmountMinTotal,
  calculateMaxUnallocatedFunds,
  calculateMinUnallocatedFunds,
  validateNewProceedAmount
} from './utils';
import Table, { Body } from '../../../../netcapital-components/Table';
import { fetchFormGlobals, fetchUseOfProceeds, proceeds4a6State, raiseAmountState } from '../../../../_state/application-form';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import Modal from '../../../../netcapital-components/Modal';
import NetcapitalFeeRow from './feeRow';
import ProceedRow from './row';
import { Typography } from '@mui/material';
import { UOP } from '../../../../static-info/listing-application-forms';
import UseOfProceedsTableFooter from './footer';
import UseOfProceedsTableHeader from './header';
import { isSideBySide } from '../../../../_helpers/exemptions';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useGetShares } from '../../../../_actions/application-form';
import { v4 as uuidv4 } from 'uuid';

export default function UseOfProceedsTable() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetShares(listingKey, offerKey);
  const raiseAmountMax = useRecoilValue(raiseAmountState);
  const raiseAmount4a6Max = useRecoilValue(proceeds4a6State);
  const isSideBySideOffering = isSideBySide(useRecoilValue(fetchFormGlobals('offerExemptions')));
  const [fundsAllocation, setFundsAllocation] = useRecoilState(fetchUseOfProceeds('fundsAllocation'));
  const savingState = useAutoSave(UOP, 'fundsAllocation', fundsAllocation);
  const amountMinTotal = calculateAmountMinTotal(fundsAllocation);
  const amount4a6MaxTotal = calculateAmount4a6MaxTotal(fundsAllocation, raiseAmount4a6Max);
  const amountMaxTotal = calculateAmountMaxTotal(fundsAllocation, raiseAmountMax);
  const unallocatedIndex = fundsAllocation.findIndex(proceed => proceed.item === 'Unallocated Funds');
  const [blockingNewProceed, setBlockingNewProceed] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (unallocatedIndex === -1 || !raiseAmount4a6Max && !raiseAmountMax) {
      return;
    }
    
    const copyProceeds = [...fundsAllocation];
    const unAllocatedFunds = {...copyProceeds[unallocatedIndex]} || {};

    unAllocatedFunds.amountMin = calculateMinUnallocatedFunds(copyProceeds);
    if (raiseAmount4a6Max > 0){
      unAllocatedFunds.amount4a6Max = calculate4a6MaxUnallocatedFunds(copyProceeds, raiseAmount4a6Max);
    }
    if (raiseAmountMax > 0){
      unAllocatedFunds.amountMax = calculateMaxUnallocatedFunds(copyProceeds, raiseAmountMax);
    }

    copyProceeds.forEach((proceed, index) => {
      if (proceed.item === 'Unallocated Funds'){
        return;
      }

      copyProceeds[index] = {
        ...proceed,
        amountMax: amountMaxTotal > raiseAmountMax ? 0 : proceed.amountMax,
        amountMin: amountMinTotal > TOTAL_MIN_ALLOCATION ? 0 : proceed.amountMin,
        amount4a6Max: amount4a6MaxTotal > raiseAmount4a6Max ? 0 : proceed.amount4a6Max
      };
    });

    copyProceeds[unallocatedIndex] = {
      ...unAllocatedFunds,
      amount4a6Max: unAllocatedFunds.amount4a6Max >= 0 ? unAllocatedFunds.amount4a6Max : raiseAmount4a6Max,
      amountMin: unAllocatedFunds.amountMin >= 0 ? unAllocatedFunds.amountMin : TOTAL_MIN_ALLOCATION * (1-NETCAPITAL_FEE),
      amountMax: unAllocatedFunds.amountMax >= 0 ? unAllocatedFunds.amountMax : raiseAmountMax
    };
    
    setFundsAllocation([...copyProceeds]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fundsAllocation), raiseAmount4a6Max, raiseAmountMax]);

  const handleBlockingProceed = useCallback(() => setBlockingNewProceed(false), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToSharesPricing = useCallback(() => navigate(`/listings/${listingKey}/offers/${offerKey}/shares-and-pricing`), [listingKey, offerKey]);

  const handleRemoveProceed = useCallback((key) => {
    const previousProceeds = [...fundsAllocation];
    const filteredProceeds = previousProceeds.filter((proceed) => {
      return proceed._key !== key;
    });
    const unallocatedEntry = filteredProceeds.find(proceed => proceed.item === 'Unallocated Funds');
    const unallocatedEntryIndex = filteredProceeds.findIndex(proceed => proceed.item === 'Unallocated Funds');
    const newAmountMinUnallocated = calculateMinUnallocatedFunds(filteredProceeds)[0];
    const newAmount4a6MaxUnallocated = calculate4a6MaxUnallocatedFunds(filteredProceeds, raiseAmount4a6Max)[0];
    const newAmountMaxUnallocated = calculateMaxUnallocatedFunds(filteredProceeds, raiseAmountMax)[0];
    
    filteredProceeds[unallocatedEntryIndex] = {...unallocatedEntry, amountMin: newAmountMinUnallocated, amount4a6Max: newAmount4a6MaxUnallocated, amountMax: newAmountMaxUnallocated};
    setFundsAllocation(filteredProceeds);
  }, [fundsAllocation, raiseAmount4a6Max, raiseAmountMax, setFundsAllocation]);
  
  const isValidNewAmount = useCallback((fieldName, amount, row, proceedsTable) => {
    if (amount < 0){
      return false;
    }
    
    if(fieldName === 'amountMin'){
      return validateNewProceedAmount(proceedsTable, row, amount, fieldName, TOTAL_MIN_ALLOCATION);
    }
  
    if(fieldName === 'amount4a6Max'){
      return validateNewProceedAmount(proceedsTable, row, amount, fieldName, raiseAmount4a6Max);
    }

    if(fieldName === 'amountMax'){
      return validateNewProceedAmount(proceedsTable, row, amount, fieldName, raiseAmountMax);
    }

    return true;

  }, [raiseAmountMax, raiseAmount4a6Max]);

  const handleChangeProceed = useCallback((row, newValue) => {
    const copyProceeds = [...fundsAllocation];

    if (unallocatedIndex === -1) {
      copyProceeds[row+1] = {
        _key: uuidv4(),
        item: 'Unallocated Funds',
        amount4a6Max: 0,
        amountMax: 0,
        amountMin: 0,
      };
    }

    const fieldName = Object.keys(newValue)[0];
    const isValid = isValidNewAmount(fieldName, newValue[fieldName], row, copyProceeds);
    if (!isValid) {
      return;
    }
    
    const previousValue = copyProceeds[row];
    copyProceeds[row] = {...previousValue, ...newValue};
    
    setFundsAllocation([...copyProceeds]);
  }, [unallocatedIndex, fundsAllocation, isValidNewAmount, setFundsAllocation]);

  const handleAddProceed = useCallback(() => {
    if (raiseAmountMax === 0){
      setBlockingNewProceed(true);
      return;
    }

    handleBlockingProceed();
    
    const newProceed = {
      _key: uuidv4(),
      item: 'Expense name',
      amount4a6Max: 0,
      amountMax: 0,
      amountMin: 0,
    };
    handleChangeProceed(fundsAllocation.length, newProceed);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundsAllocation, raiseAmountMax]);

  const modalMessage = (
    <>
      <Typography variant='h3' color='warning.main'>Insufficient Information</Typography>
      <Typography variant='body1'>You are trying to add information about the use of proceeds before indicating how much you plan to raise.</Typography>
      <Typography variant='body1'>Please fill out the <Typography variant='span' fontWeight='fontWeightMedium'>“How much money are you planning to raise in this offering?“</Typography> question in the Shares & Pricing form first, and then return to this section.</Typography>
    </>
  );
  
  if (typeof fundsAllocation === 'undefined' || !loaded) return null;

  return (
    <>
      <Modal 
        handlePrimaryAction={goToSharesPricing}
        handleSecondaryAction={handleBlockingProceed}
        hasSecondaryCta
        onClose={handleBlockingProceed}
        open={blockingNewProceed} 
        primaryBtnTitle='Go to Shares & Pricing'
        secondaryBtnColor='primary'
        secondaryBtnTitle='Cancel'
      >
        {modalMessage}
      </Modal>
      <Table 
        onNewRow={handleAddProceed} 
        newRowCTA='Add New Expense' 
        error={savingState && savingState.state === 'error'}
      >
        <UseOfProceedsTableHeader 
          isSideBySideOffering={isSideBySideOffering} 
          raiseAmountMax={raiseAmountMax}
          raiseAmount4a6Max={raiseAmount4a6Max} 
        />
        {fundsAllocation && 
          <Body>
            {fundsAllocation.map((proceed, index) => {
              if(index === unallocatedIndex){
                return null;
              }
              return (
                <ProceedRow 
                  key={proceed._key}
                  index={index} 
                  proceed={proceed} 
                  isSideBySideOffering={isSideBySideOffering}
                  maxUnallocatedPercentMin={fundsAllocation[unallocatedIndex]?.amountMin/TOTAL_MIN_ALLOCATION}
                  maxUnallocatedPercent4a6Max={fundsAllocation[unallocatedIndex]?.amount4a6Max/raiseAmount4a6Max}
                  maxUnallocatedPercentMax={fundsAllocation[unallocatedIndex]?.amountMax/raiseAmountMax}
                  onRemove={handleRemoveProceed} 
                  onChange={handleChangeProceed} 
                  raiseAmountMax={raiseAmountMax}
                  raiseAmount4a6Max={raiseAmount4a6Max}
                />
              );
            })}
            {/* Unallocated funds */}
            {unallocatedIndex > -1 && (
              <ProceedRow 
                index={unallocatedIndex}
                proceed={fundsAllocation[unallocatedIndex]}
                isSideBySideOffering={isSideBySideOffering}
                maxUnallocatedPercentMin={fundsAllocation[unallocatedIndex]?.amountMin/TOTAL_MIN_ALLOCATION}
                maxUnallocatedPercent4a6Max={fundsAllocation[unallocatedIndex]?.amount4a6Max/raiseAmount4a6Max}
                maxUnallocatedPercentMax={fundsAllocation[unallocatedIndex]?.amountMax/raiseAmountMax}
                raiseAmountMax={raiseAmountMax}
                raiseAmount4a6Max={raiseAmount4a6Max}
              />
            )}
            <NetcapitalFeeRow  
              isSideBySideOffering={isSideBySideOffering} 
              raiseAmountMax={raiseAmountMax}
              raiseAmount4a6Max={raiseAmount4a6Max}
            />
          </Body>
        }
        <UseOfProceedsTableFooter 
          amountMinTotal={amountMinTotal} 
          amount4a6MaxTotal={amount4a6MaxTotal} 
          amountMaxTotal={amountMaxTotal}
          isSideBySideOffering={isSideBySideOffering}
        />
      </Table>
    </>
  );
}

