import { CAP_TABLE, KEY_PEOPLE } from '../../../static-info/listing-application-forms';

import styles from './styles';

export const getCurrentForm = (location) => {
  const locationPathArray = location.pathname.split('/');
  if(locationPathArray.length === 0){
    return {
      formId: '',
      itemId: ''
    };
  }
  // Route is a "child" form, e.g. KeyPerson or FinancialReport
  if(locationPathArray.length === 7){
    return {
      formId: locationPathArray[locationPathArray.length - 2],
      itemId: locationPathArray[locationPathArray.length - 1]
    };
  }
  return {
    formId: locationPathArray[locationPathArray.length - 1],
    itemId: ''
  };
};

export const formulateMenuItemSxArray = (formId, currentForm) => {
  const MenuItemSx = [styles.MenuItem];
  if(formId === currentForm.formId){
    MenuItemSx.push(styles.ActiveMenuItem);
  }
  return MenuItemSx;
};

export const assembleAccordionMenuTopLevelItemSx = (formId) => {
  const AccordionTopLevelMenuItemActive = formId === KEY_PEOPLE;
  const sxArray = [styles.AccordionMenuTopLevelItem];

  if (AccordionTopLevelMenuItemActive){
    sxArray.push(styles.AccordionTopLevelMenuItemActive);
  } 

  return sxArray;
};

export const assembleFormTitle = (currentForm, keyPeople) => {
  let formTitle = '';
  if(currentForm.itemId){
    for(let i = 0; i < keyPeople.length; i++){
      if(currentForm.itemId === keyPeople[i].key){
        formTitle = `${keyPeople[i].firstName} ${keyPeople[i].lastName}`;
      }
    }
  }
  else if(currentForm.formId === CAP_TABLE){
    formTitle = 'Cap Table & Governance';
  }
  else {
    formTitle = currentForm.formId.split('-').map((substring) => {
      if(substring === 'of' || substring === 'for'){
        return substring;
      }
      else if(substring === 'and'){
        return '&';
      }
      return substring.charAt(0).toUpperCase() + substring.slice(1);
    }).join(' ');
  }
  return formTitle;
};

export const navIsVisible = (currentForm) => {
  return !currentForm.itemId;
};