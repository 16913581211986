import { createFile, deleteFile } from '../../../_actions/application-form';
import { useCallback, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import FileUpload from '../../../netcapital-components/FileUpload';
import PropTypes from 'prop-types';
import { progressState } from '../../../_state/application-form';
import { useAlertActions } from '../../../_actions/alert.actions';
import { useParams } from 'react-router-dom';

export const DEFAULT_FILE_TYPES = [
  'application/pdf',
  'image/*',
  '.doc',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

function FileField({ usage, state, fileType }) {
  const {listingKey, offerKey} = useParams();
  const [file, setFile] = useRecoilState(state(usage));
  const [loading, setLoading] = useState(false);
  const alertActions = useAlertActions();
  const setProgressLevel = useSetRecoilState(progressState);

  const handleUpdateFile = useCallback(async (newFile) => {
    setLoading(true);
    if (!newFile) {
      const resp = await deleteFile(listingKey, offerKey, file.fileKey);
      if (resp.response && resp.response.status > 204) {
        alertActions.error('An error occurred deleting the file', resp.response, resp.message, resp.request);
      } else {
        setFile(null);
        if (resp?.data?.progress) {
          setProgressLevel(resp.data.progress);
        }
      }
      setLoading(false);
      return;
    }
    
    const formData = new FormData();
    formData.append('usage', usage);
    formData.append('file', newFile);
    const resp = await createFile(listingKey, offerKey, formData);
    if (resp.response && resp.response.status > 204) {
      alertActions.error('An error occurred creating the file', resp.response, resp.message, resp.request);
    } else {
      setFile(resp.data.file);
      if (resp?.data?.progress) {
        setProgressLevel(resp.data.progress);
      }
    }
    setLoading(false);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage, offerKey, listingKey, file]);

  return (
    <FileUpload
      fileType={fileType}
      file={file}
      onChange={handleUpdateFile}
      usage={usage}
      loading={loading}
    />
  );
}

FileField.defaultProps = {
  fileType: DEFAULT_FILE_TYPES.join(','),
};

FileField.propTypes = {
  fileType: PropTypes.string,
  usage: PropTypes.string.isRequired,
  state: PropTypes.func.isRequired,
};

export default FileField;
