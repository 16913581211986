const styles = {
  FormControl: () => ({
    width: '100%',
    '& .MuiOutlinedInput-root' : {
      height: '48px',
    }
  }),
  Placeholder: () => ({
    opacity: 0.45
  }),
};

export default styles;