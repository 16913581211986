const styles = {
  HelperText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.3rem',
    fontSize: '12px',
    fontWeight: 600,
    '& .MuiSvgIcon-root': {
      fontSize: '12px',
      marginRight: '2px',
    }
  }
};

export default styles;