import Table, { Body } from '../../../../netcapital-components/Table';
import { capTableState, fetchSecurities } from '../../../../_state/application-form';
import { useRecoilState, useResetRecoilState } from 'recoil';

import EquitySecuritiesTableHeader from './header';
import EquitySecurityRow from './row';
import { SECURITIES } from '../../../../static-info/listing-application-forms';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useGetSecurities } from '../../../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function EquitySecuritiesTable(){
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetSecurities(listingKey, offerKey);
  const [equitySecurities, setEquitySecurities] = useRecoilState(fetchSecurities('equitySecurities'));
  const savingState = useAutoSave(SECURITIES, 'equitySecurities', equitySecurities);
  const resetCapTable = useResetRecoilState(capTableState);

  const handleAddEquitySecurity = useCallback(() => {
    const initialEquitySecurities = [];
    const newEquitySecurity = {
      _key: uuidv4(),
      additionalRights: '',
      hasVotingRights: false,
      priority: 0,
      securityAmount: 0,
      securityAmountOutstanding: 0,
      securityType: 'Class A',
    };
    if(!equitySecurities){
      initialEquitySecurities.push(newEquitySecurity);
      setEquitySecurities(initialEquitySecurities);
    } else {
      setEquitySecurities([...equitySecurities, newEquitySecurity]);
    }
  },[equitySecurities, setEquitySecurities]);

  const handleRemoveEquitySecurity = useCallback((index) => {
    const copyEquitySecurities = [...equitySecurities];
    copyEquitySecurities.splice(index, 1);
    setEquitySecurities([...copyEquitySecurities]);
    resetCapTable();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[equitySecurities, setEquitySecurities]);

  const handleChangeEquitySecurity = useCallback((row, newValue) => {
    const copyEquitySecurities = [...equitySecurities];
    const entry = [...copyEquitySecurities][row];
    copyEquitySecurities[row] = {...entry, ...newValue};
    setEquitySecurities([...copyEquitySecurities]);
  },[equitySecurities, setEquitySecurities]);

  if (!loaded) return null;
  
  return (
    <Table onNewRow={handleAddEquitySecurity}>
      <EquitySecuritiesTableHeader />
      {equitySecurities && 
        <Body>
          {equitySecurities.map((security, index) => (
            <EquitySecurityRow 
              key={security._key}
              index={index} 
              security={security} 
              onRemove={handleRemoveEquitySecurity} 
              onChange={handleChangeEquitySecurity}
              error={savingState && savingState.state === 'error'}
            />
          ))}
        </Body>
      }
    </Table>
  );
}