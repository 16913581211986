import { fetchAgreementsInfo, progressState } from '../../../_state/application-form';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Box } from '@mui/material';
import Checkbox from '../../../netcapital-components/Checkbox';
import MaxRegCfAmountAlert from '../MaxRegCfAmountAlert';
import { saveAgreements } from '../../../_actions/application-form/agreements.actions';
import { useAlertActions } from '../../../_actions/alert.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRegCfAlertActions } from '../../../_actions/application-form/reg-cf-alert.actions';

function TermsAgreement({ doingBusinessAs }) {
  const { listingKey, offerKey } = useParams();
  const [hasTermsAgreement, setHasTermsAgreement] = useRecoilState(fetchAgreementsInfo('hasTermsAgreement'));
  const alertActions = useAlertActions();
  const regCfAlertActions = useRegCfAlertActions();
  const setProgressLevel = useSetRecoilState(progressState);
  
  const handleChangeAgreements = useCallback(async () => {
    const newVal = !hasTermsAgreement;
    const resp = await saveAgreements({hasTermsAgreement: newVal}, listingKey, offerKey);
    if (resp.response && resp.response.status > 204) {
      if (resp.response.data.errorCode === 'RegCFMaxRaiseError') {
        regCfAlertActions.error('REG CF ERROR', resp.response, JSON.parse(resp.response.data.message), resp.request);
      } else {
        alertActions.error('An error occurred', resp.response, resp.message, resp.request);
      }
    } else {
      setHasTermsAgreement(newVal);
      if (resp?.data?.progress) {
        setProgressLevel(resp.data.progress);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey, hasTermsAgreement]);
  
  return (
    <Box>
      <Checkbox
        label={`By checking this box you approve the above agreements, and you represent and warrant that you have the authority to enter into the above agreements and make the above authorizations on behalf of ${doingBusinessAs || 'your company'}.`}
        checked={Boolean(hasTermsAgreement)}
        onChange={handleChangeAgreements}
      />
      <MaxRegCfAmountAlert />
    </Box>
  );
}

export default TermsAgreement;
