import { APIListingApplicationURL } from './_api-urls';
import { fetchRequest } from '../../_helpers';

export async function createFile(listingKey, offerKey, body) {
  const response = await fetchRequest('post', 
    `${APIListingApplicationURL(listingKey, offerKey)}/files`, 
    body,
    false,
    'multipart/form-data'
  ).catch((error) => error);

  return response;
}

export async function deleteFile(listingKey, offerKey, fileKey) {
  const response = await fetchRequest('delete',
    `${APIListingApplicationURL(listingKey, offerKey)}/files/${fileKey}`,
    null,
  ).catch((error) => error);

  return response;
}
