const styles = {
  Backdrop: () => ({
    backdropFilter: 'blur(2px)',
  }),
  CloseButtonWrapper: () => ({
    display: 'flex', 
    flexDirection: 'row-reverse',
  }),
  CloseIcon: () => ({
    height: '20px',
    width: '20px',
  }),
  IconButton: () => ({
    position: 'relative',
    left: '10px',
  }),
  ModalBox: ({ palette, breakpoints} ) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: palette.common.white,
    borderRadius: '16px',
    boxShadow: `0px 4px 20px ${palette.shadows.primary}`,
    padding: '12px 24px 24px 24px',
    '&:focus': {
      outline: 'none',
    },
    [breakpoints.up('xs')]: {
      width: '95%',
    },
    [breakpoints.up('sm')]: {
      // width: '90%',
      width: '536px',
    },
    [breakpoints.up('md')]: {
      // width: '75%',
      width: '820px',
    },
    [breakpoints.up('lg')]: {
      // width: '50%',
      width: '552px',
    },
    [breakpoints.up('1400')]: { //xl?
      // width: '55%',
      width: '671px',
    },
  }),
  ModalContent: () => ({
    '& .MuiTypography-root':{
      textAlign: 'center',
    },
  }),
  ModalControls: () => ({
    paddingTop: '1.5rem',
    '& .MuiButtonBase-root':{
      minHeight: '48px', //remove when implementing shared components Button base styles
      flex: 1, 
    },
  }),
};

export default styles;