import { forwardRef, useCallback } from 'react';

import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

export const NumberFormatThousands = forwardRef((props, ref) => {
  const { onChange, value, decimals, ...other } = props;
  
  const unformatThousands = useCallback((e) => {
    const changeValue = (e.value === '') ? null : Number(e.value);
    onChange(changeValue);
  }, [onChange]);

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      decimalScale={decimals ?? 0}
      value={value}
      thousandSeparator
      onValueChange={unformatThousands}
    />
  );
});

NumberFormatThousands.defaultProps = {
  value: 0,
  decimals: false,
  onChange: () => { return; }
};

NumberFormatThousands.propTypes = {
  onChange: PropTypes.func,
  decimals: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  value: PropTypes.number,
};

NumberFormatThousands.displayName = 'NumberFormatThousands';
