import { FormControlLabel, Switch as MUISwitch } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

const component = ({ checked, label, onChange }) => <FormControlLabel control={<MUISwitch checked={checked} onChange={onChange} />} label={label} />;

export const Switch = React.memo(component);

Switch.defaultProps = {
  checked: false,
};

Switch.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;