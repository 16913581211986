import { Divider, Grid, Typography } from '@mui/material';
import { NumberFormatCents, NumberFormatThousands } from '../../../netcapital-components/NumberFormat';
import { useNavigate, useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../netcapital-components/Modal';
import { regCfAlertAtom } from '../../../_state/application-form';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useRegCfAlertActions } from '../../../_actions/application-form/reg-cf-alert.actions';

function MaxRegCfAmountAlert() {
  const { listingKey, offerKey } = useParams();
  const navigate = useNavigate();
  const regCfAlertActions = useRegCfAlertActions();
  const regCfError = useRecoilValue(regCfAlertAtom);
  
  const handleClose = useCallback(() => {
    regCfAlertActions.clear();
  }, [regCfAlertActions]);
  
  const goToSharesPricing = useCallback(() => {
    handleClose();
    navigate(`/listings/${listingKey}/offers/${offerKey}/shares-and-pricing`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey]);
  
  const goToFundingHistory = useCallback(() => {
    handleClose();
    navigate(`/listings/${listingKey}/offers/${offerKey}/funding-history`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey]);
  
  const modalMessage = (
    <>
      <Typography variant='h3' color='error.main'>Maximum Reg CF Amount Reached</Typography>
      <Grid container spacing={2} sx={styles.SharesTable}>
        <Grid item xs={7}>Shares</Grid>
        <Grid item xs={5}>
          <Typography sx={styles.Value}>
            <NumberFormatThousands
              value={regCfError?.error?.message?.totalQuantity}
              displayType={'text'}
            />
          </Typography>
        </Grid>
        <Grid item xs={7} sx={styles.IconContainer}>
          Price per share
          <CloseIcon color='primary' sx={styles.MultiplyIcon} />
        </Grid>
        <Grid item xs={5}>
          <Typography sx={styles.Value}>
            <NumberFormatCents
              value={regCfError?.error?.message?.pricePerUnit}
              displayType={'text'}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={7}>Maximum possible amount</Grid>
        <Grid item xs={5}>
          <Typography sx={styles.Value}>
            <NumberFormatCents
              value={regCfError?.error?.message?.attemptedRaiseAmount}
              displayType={'text'}
            />
          </Typography>
        </Grid>
        <Grid item>
          Based on your funding history, you can raise a maximum amount of <Typography component='span' sx={styles.Value}><NumberFormatCents value={regCfError?.error?.message?.maxRaiseAmount} displayType={'text'} /></Typography>
        </Grid>
      </Grid>
    </>
  );
  
  return (
    <Modal 
      handlePrimaryAction={goToSharesPricing}
      handleSecondaryAction={goToFundingHistory}
      hasSecondaryCta
      onClose={handleClose}
      open={Boolean(regCfError)}
      primaryBtnTitle='Go to Shares & Pricing'
      secondaryBtnColor='secondary'
      secondaryBtnTitle='Go to Funding History'
      secondaryBtnVariant='contained'
    >
      {modalMessage}
    </Modal>
  );
}

export default MaxRegCfAmountAlert;