import { FormControl, MenuItem, Select, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';
import { useCallback } from 'react';

function Selectbox(props) {
  const { onChange, options, ownerState, value, placeholder } = props;
  const setSelectRenderValue = useCallback((selected) => {
    if (selected.length === 0) {
      return <Typography variant='body1' sx={styles.Placeholder}>{placeholder}</Typography>;
    }

    const displayValue = options.find(option => option.value === selected);
    if (displayValue) {
      return displayValue.label;
    }
    
    return selected;
  },[placeholder, options]);

  if (!options.length) return null;

  return (
    <FormControl sx={styles.FormControl}>
      <Select
        {...props}
        value={value}
        defaultValue=''
        onChange={onChange}
        displayEmpty
        renderValue={setSelectRenderValue}
        ownerState={ownerState}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Selectbox.defaultProps = {
  options: [],
  value: '',
  ownerState: {
    subVariant: ''
  },
};

Selectbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired
  })),
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  }),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Selectbox;