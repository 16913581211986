import Question, { Answer, Description } from '../../Question';
import { useGetContactInfo, useGetKeyPeople } from '../../../_actions/application-form';

import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import KeyPeopleHasRelatedPartiesTransactions from './HasRelatedPartiesTransactions';
import KeyPeopleTable from './KeyPeopleTable';
import RelatedPartiesTransactions from './RelatedPartiesTransactions';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../_state/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function KeyPeople() {
  const {listingKey, offerKey} = useParams();
  const { loaded } = useGetContactInfo(listingKey, offerKey);
  const { loaded: loadedKeyPeople } = useGetKeyPeople(listingKey, offerKey);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  return (
    <Form title='Key People' loaded={loaded && loadedKeyPeople}>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Who are the founders, executives, directors, <Help explanation={HELP['Officer']}>officers</Help>, board members, and <Help explanation={HELP['Promoter']}>promoters</Help> of {doingBusinessAs || 'your company'}?
        </Typography>
        <Description>
          <Typography variant='body2'>
            Be sure to include people occupying a similar status or performing a similar function, even if their title doesn’t quite match the words above. For promoters, make sure to include any individual who has acted in such a role in the past five years as well as any individual who will act as a promoter for this offering.
          </Typography>
          <br />
          <Typography variant='body2' fontWeight='fontWeightMedium'>
            Please be sure to list the person that should receive SEC billing information, such as the CEO, first in this list.
          </Typography>
        </Description>
        <Answer>
          <KeyPeopleTable />
        </Answer>
      </Question>
      <KeyPeopleHasRelatedPartiesTransactions />
      <RelatedPartiesTransactions />
    </Form>
  );
}

export default KeyPeople;
