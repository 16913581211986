const SECTORS = [
  {value: 'ACCOMMODATIONS', label: 'Accommodations'},
  {value: 'ACCOUNTING', label: 'Accounting'},
  {value: 'ADVERTISING', label: 'Advertising'},
  {value: 'AEROSPACE', label: 'Aerospace'},
  {value: 'AGRICULTURE', label: 'Agriculture'},
  {value: 'AGRICULTURE_AGRIBUSINESS', label: 'Agriculture & Agribusiness'},
  {value: 'AIR_TRANSPORTATION', label: 'Air Transportation'},
  {value: 'AIRPLANES_AND_AIRPORTS', label: 'Airplanes & Airports'},
  {value: 'APPAREL_ACCESSORIES', label: 'Apparel & Accessories'},
  {value: 'AUTO', label: 'Auto'},
  {value: 'BANKING', label: 'Banking'},
  {value: 'BEAUTY_COSMETICS', label: 'Beauty & Cosmetics'},
  {value: 'BIOTECHNOLOGY', label: 'Biotechnology'},
  {value: 'BUSINESS_SERVICES', label: 'Business Services'},
  {value: 'CHEMICAL', label: 'Chemical'},
  {value: 'COAL_MINING', label: 'Coal Mining'},
  {value: 'COMMERCIAL', label: 'Commercial'},
  {value: 'COMMERCIAL_BANKING', label: 'Commercial Banking'},
  {value: 'COMMUNICATIONS', label: 'Communications'},
  {value: 'COMPUTER', label: 'Computer'},
  {value: 'COMPUTERS', label: 'Computers'},
  {value: 'CONSTRUCTION', label: 'Construction'},
  {value: 'CONSULTING', label: 'Consulting'},
  {value: 'CONSUMER_PRODUCTS', label: 'Consumer Products'},
  {value: 'EDUCATION', label: 'Education'},
  {value: 'ELECTRIC_UTILITIES', label: 'Electric Utilities'},
  {value: 'ELECTRONICS', label: 'Electronics'},
  {value: 'EMPLOYMENT', label: 'Employment'},
  {value: 'ENERGY', label: 'Energy'},
  {value: 'ENERGY_CONSERVATION', label: 'Energy Conservation'},
  {value: 'ENTERTAINMENT_RECREATION', label: 'Entertainment & Recreation'},
  {value: 'ENVIRONMENTAL_SERVICES', label: 'Environmental Services'},
  {value: 'FASHION', label: 'Fashion'},
  {value: 'FINANCIAL_SERVICES', label: 'Financial Services'},
  {value: 'FOOD_BEVERAGE', label: 'Food & Beverage'},
  {value: 'HEALTH', label: 'Health'},
  {value: 'HEALTH_INSURANCE', label: 'Health Insurance'},
  {value: 'HEDGE_FUND', label: 'Hedge Fund'},
  {value: 'HOSPITALS_AND_PHYSICIANS', label: 'Hospitals & Physicians'},
  {value: 'INFORMATION', label: 'Information'},
  {value: 'INFORMATION_TECHNOLOGY', label: 'Information Technology'},
  {value: 'INSURANCE', label: 'Insurance'},
  {value: 'INVESTMENT_BANKING', label: 'Investment Banking'},
  {value: 'JOURNALISM_NEWS', label: 'Journalism & News'},
  {value: 'LEGAL_SERVICES', label: 'Legal Services'},
  {value: 'LODGING_AND_CONVENTIONS', label: 'Lodging & Conventions'},
  {value: 'MANUFACTURING', label: 'Manufacturing'},
  {value: 'MEDIA_BROADCASTING', label: 'Media & Broadcasting'},
  {value: 'MEDICAL_DEVICES_SUPPLIES', label: 'Medical Devices & Supplies'},
  {value: 'MOTION_PICTURES_VIDEO', label: 'Motion Pictures & Video'},
  {value: 'MUSIC', label: 'Music'},
  {value: 'OIL_AND_GAS', label: 'Oil & Gas'},
  {value: 'OTHER', label: 'Other'},
  {value: 'OTHER_BANKING', label: 'Other Banking & Financial Services'},
  {value: 'OTHER_ENERGY', label: 'Other Energy'},
  {value: 'OTHER_HEALTH_CARE', label: 'Other Health Care'},
  {value: 'OTHER_INVESTMENT_FUND', label: 'Other Investment Fund'},
  {value: 'OTHER_REAL_ESTATE', label: 'Other Real Estate'},
  {value: 'OTHER_TECHNOLOGY', label: 'Other Technology'},
  {value: 'OTHER_TRAVEL', label: 'Other Travel'},
  {value: 'PHARMACEUTICAL', label: 'Pharmaceutical'},
  {value: 'PHARMACEUTICALS', label: 'Pharmaceuticals'},
  {value: 'POOLED_INVESTMENT_FUND', label: 'Pooled Investment Fund'},
  {value: 'PRIVATE_EQUITY_FUND', label: 'Private Equity Fund'},
  {value: 'PUBLIC_ADMINISTRATION', label: 'Public Administration'},
  {value: 'PUBLIC_RELATIONS', label: 'Public Relations'},
  {value: 'PUBLISHING', label: 'Publishing'},
  {value: 'REAL_ESTATE', label: 'Real Estate'},
  {value: 'REITS_AND_FINANCE', label: 'REITS & Finance'},
  {value: 'RESIDENTIAL', label: 'Residential'},
  {value: 'RESTAURANTS', label: 'Restaurants'},
  {value: 'RETAIL', label: 'Retail'},
  {value: 'RETAILING', label: 'Retailing'},
  {value: 'SERVICE', label: 'Service'},
  {value: 'SPORTS', label: 'Sports'},
  {value: 'TECHNOLOGY', label: 'Technology'},
  {value: 'TELECOMMUNICATIONS', label: 'Telecommunications'},
  {value: 'TOURISM', label: 'Tourism'},
  {value: 'TOURISM_AND_TRAVEL_SERVICES', label: 'Tourism & Travel Services'},
  {value: 'TRANSPORTATION', label: 'Transportation'},
  {value: 'TRAVEL', label: 'Travel'},
  {value: 'UTILITIES', label: 'Utilities'},
  {value: 'VENTURE_CAPITAL_FUND', label: 'Venture Capital Fund'},
  {value: 'VIDEO_GAME', label: 'Video Game'},
  {value: 'WEB_SERVICES', label: 'Web Services'},
];

export default SECTORS;