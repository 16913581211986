import Box from '@mui/material/Box';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';
import styles from './styles';

export default function DataGrid(props){
  const { 
    columns, 
    currentPage, 
    getRowId, 
    handlePaginate, 
    isLoading, 
    pageSizeOptions, 
    resultsPerPage, 
    rows, 
    totalResults 
  } = props;
  
  return (
    <Box sx={styles.DataGridContainer}>
      <MUIDataGrid 
        columns={columns}
        disableColumnFilter
        disableRowSelectionOnClick
        getRowId={getRowId} //needed to assign id's to row el's
        loading={isLoading}
        onPaginationModelChange={handlePaginate}
        pageSizeOptions={pageSizeOptions}
        paginationModel={{
          page: currentPage - 1, //Mui-X DataGrid inits at page === 0 and our api inits at page === 1
          pageSize: resultsPerPage,
        }}
        paginationMode='server' //https://mui.com/x/react-data-grid/pagination/#server-side-pagination
        rows={rows}
        rowCount={totalResults || 0}
        sx={styles.DataGrid}
        {...props}
      />
    </Box>
  );
}