import Question, { Answer } from '../../Question';
import { fetchContactInfo, fetchFundingHistory } from '../../../_state/application-form';
import { useRecoilState, useRecoilValue } from 'recoil';

import { FUNDING_HISTORY } from '../../../static-info/listing-application-forms';
import { PreviousFundingRoundsTable } from './previousFundingTable';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { Typography } from '@mui/material';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';

export default function CrowdfundingHistory() {
  const [hasCrowdfundingHistory, setHasCrowdfundingHistory] = useRecoilState(fetchFundingHistory('hasCrowdfundingHistory'));
  const savingState_HasCrowdfundingHistory = useAutoSave(FUNDING_HISTORY, 'hasCrowdfundingHistory', hasCrowdfundingHistory);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const handleChangeCrowdfundingHistory = useCallback((e) => setHasCrowdfundingHistory(e.target.value === 'true'), [setHasCrowdfundingHistory]);
  
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          In the last three years, has {doingBusinessAs || 'your company'} raised any money through the sale of securities?
      </Typography>
      <Answer>
        <RadioGroup
          value={hasCrowdfundingHistory}
          onChange={handleChangeCrowdfundingHistory}
          options={RADIO_OPTIONS.YES_NO}
          error={savingState_HasCrowdfundingHistory && savingState_HasCrowdfundingHistory.state === 'error'}
        />
        {hasCrowdfundingHistory && <PreviousFundingRoundsTable />}
      </Answer>
    </Question>
  );
}