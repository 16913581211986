import {
  AGREEMENTS,
  BOARD_AUTHORIZATION,
  CAP_TABLE,
  COMPANY_DESCRIPTION,
  COMPANY_FORMATION,
  COMPLIANCE,
  CONTACT_INFO,
  FINANCIAL_CONDITION,
  FINANCIAL_REPORT,
  FINANCIAL_STATEMENTS,
  FUNDING_HISTORY,
  KEY_PEOPLE,
  KEY_PERSON,
  METADATA,
  RISKS,
  SECURITIES,
  SHARES,
  SOCIAL_MEDIA,
  UOP
} from '../../static-info/listing-application-forms';
import {
  agreementsState,
  boardAuthorizationState,
  capTableState,
  companyDescriptionState,
  companyFormationState,
  complianceState,
  contactInfoState,
  financialConditionState,
  financialReportState,
  financialStatementsState,
  fundingHistoryState,
  keyPeopleState,
  keyPersonState,
  metadataState,
  risksState,
  securitiesState,
  sharesState,
  socialMediaState,
  useOfProceedsState
} from '../../_state/application-form';

export const mapApplicationForms = {
  [CONTACT_INFO]: {
    name: 'Contact Information',
    pathname: CONTACT_INFO,
    formId: CONTACT_INFO,
    state: contactInfoState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [SOCIAL_MEDIA]: {
    name: 'Social Media',
    pathname: SOCIAL_MEDIA,
    formId: SOCIAL_MEDIA,
    state: socialMediaState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [COMPANY_FORMATION]:{
    name: 'Company Formation',
    pathname: COMPANY_FORMATION,
    formId: COMPANY_FORMATION,
    state: companyFormationState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [COMPANY_DESCRIPTION]:{
    name: 'Company Description',
    pathname: COMPANY_DESCRIPTION,
    formId: COMPANY_DESCRIPTION,
    state: companyDescriptionState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [FUNDING_HISTORY]: {
    name: 'Funding History',
    pathname: FUNDING_HISTORY,
    formId: FUNDING_HISTORY,
    state: fundingHistoryState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [SECURITIES]: {
    name: 'Securities',
    pathname: SECURITIES,
    formId: SECURITIES,
    state: securitiesState,
  },
  [CAP_TABLE]:{
    name: 'Cap Table',
    pathname: CAP_TABLE,
    formId: CAP_TABLE,
    state: capTableState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [KEY_PEOPLE]: {
    name: 'Key People',
    pathname: KEY_PEOPLE,
    formId: KEY_PEOPLE,
    state: keyPeopleState,
    PUT_DISABLED: true,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [KEY_PERSON]: {
    pathname: `${KEY_PEOPLE}/:key`,
    formId: KEY_PERSON,
    state:  keyPersonState,
    hasViewPermission: true,             
    excludeFromNavigation: true,
  },
  [SHARES]: {
    name: 'Shares and Pricing',
    pathname: SHARES,
    formId: SHARES,
    state: sharesState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [UOP]:{
    name: 'Use of Proceeds',
    pathname: UOP,
    formId: UOP,
    state: useOfProceedsState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [RISKS]: {
    name: 'Risks',
    pathname: RISKS,
    formId: RISKS,
    state: risksState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [COMPLIANCE]: {
    name: 'Compliance',
    pathname: COMPLIANCE,
    formId: COMPLIANCE,
    state: complianceState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [FINANCIAL_CONDITION]: {
    name: 'Financial Condition',
    pathname: FINANCIAL_CONDITION,
    formId: FINANCIAL_CONDITION,
    state: financialConditionState,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [FINANCIAL_STATEMENTS]: {
    name: 'Financial Statements',
    pathname: FINANCIAL_STATEMENTS,
    formId: FINANCIAL_STATEMENTS,
    state: financialStatementsState,
    PUT_DISABLED: true,
    hasViewPermission: true,             
    excludeFromNavigation: false,
  },
  [FINANCIAL_REPORT]: {
    name: 'Financial Report',
    pathname: `${FINANCIAL_REPORT}/:key`,
    formId: FINANCIAL_REPORT,
    state: financialReportState,
    excludeFromNavigation: true,
  },
  [BOARD_AUTHORIZATION]: {
    name: 'Board Authorization',
    pathname: BOARD_AUTHORIZATION,
    formId: BOARD_AUTHORIZATION,
    state: boardAuthorizationState,
    hasViewPermission: true,
    excludeFromNavigation: false,
  },
  [AGREEMENTS]: {
    name: 'Agreements',
    pathname: AGREEMENTS,
    formId: AGREEMENTS,
    state: agreementsState,
    PUT_DISABLED: true,
    hasViewPermission: true,
    excludeFromNavigation: false,
  },
  [METADATA]:{
    name: 'Metadata',
    pathname: METADATA,
    formId: METADATA,
    state: metadataState,
  }
};

export const applicationFormRouteMap = (userRole) => {
  const isAdmin = userRole === 'ADMIN';
  return [
    {
      name: 'Contact Information',
      pathname: CONTACT_INFO,
      formId: CONTACT_INFO,
      state: contactInfoState,
      hasViewPermission: true,             
      excludeFromNavigation: false 
    },
    {
      name: 'Social Media',
      pathname: SOCIAL_MEDIA,
      formId: SOCIAL_MEDIA,
      state: socialMediaState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Company Formation',
      pathname: COMPANY_FORMATION,
      formId: COMPANY_FORMATION,
      state: companyFormationState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Company Description',
      pathname: COMPANY_DESCRIPTION,
      formId: COMPANY_DESCRIPTION,
      state: companyDescriptionState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Funding History',
      pathname: FUNDING_HISTORY,
      formId: FUNDING_HISTORY,
      state: fundingHistoryState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Cap Table',
      pathname: CAP_TABLE,
      formId: CAP_TABLE,
      state: capTableState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Securities',
      pathname: SECURITIES,
      formId: SECURITIES,
      state: securitiesState,
      hasViewPermission: isAdmin,             
      excludeFromNavigation: !isAdmin
    },
    {
      pathname: `${KEY_PEOPLE}/:key`,
      formId: KEY_PERSON,
      state:  keyPersonState,
      hasViewPermission: true,             
      excludeFromNavigation: true
    },
    {
      name: 'Key People',
      pathname: KEY_PEOPLE,
      formId: KEY_PEOPLE,
      state: keyPeopleState,
      PUT_DISABLED: true,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Shares and Pricing',
      pathname: SHARES,
      formId: SHARES,
      state: sharesState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Use of Proceeds',
      pathname: UOP,
      formId: UOP,
      state: useOfProceedsState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Risks',
      pathname: RISKS,
      formId: RISKS,
      state: risksState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Compliance',
      pathname: COMPLIANCE,
      formId: COMPLIANCE,
      state: complianceState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Financial Condition',
      pathname: FINANCIAL_CONDITION,
      formId: FINANCIAL_CONDITION,
      state: financialConditionState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Financial Statements',
      pathname: FINANCIAL_STATEMENTS,
      formId: FINANCIAL_STATEMENTS,
      state: financialStatementsState,
      PUT_DISABLED: true,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Financial Report',
      pathname: `${FINANCIAL_REPORT}/:key`,
      formId: FINANCIAL_REPORT,
      state: financialReportState,
      hasViewPermission: isAdmin,             
      excludeFromNavigation: true,
    },
    {
      name: 'Board Authorization',
      pathname: BOARD_AUTHORIZATION,
      formId: BOARD_AUTHORIZATION,
      state: boardAuthorizationState,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Agreements',
      pathname: AGREEMENTS,
      formId: AGREEMENTS,
      state: agreementsState,
      PUT_DISABLED: true,
      hasViewPermission: true,             
      excludeFromNavigation: false
    },
    {
      name: 'Metadata',
      pathname: METADATA,
      formId: METADATA,
      state: metadataState,
      hasViewPermission: isAdmin,             
      excludeFromNavigation: !isAdmin
    }
  ];
};