import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';
import styles from './styles';

const description = ({ children }) => {
  return (
    <Box name='Netcapital-Question-Description' sx={styles.DescriptionBox}>
      <Typography sx={styles.Description} component='span'>{children}</Typography>
    </Box>
  );
};

export const Description = React.memo(description);

Description.propTypes = {
  children: PropTypes.node.isRequired
};

export default React.memo(Description);