import Question, { Answer, Description } from '../../../Question';

import EquitySecuritiesTable from '../EquitySecuritiesTable';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Typography } from '@mui/material';

function SecurityClasses(){
  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        List all classes of equities or securities in order of preference.
      </Typography>
      <Description>
        <Typography variant='body2'>
          Many companies may only have one type, Common Stock. Some companies will also have Preferred Stock, various Classes of stock, or Debt Securities. LLCs generally have Membership Units or Interests.
        </Typography>
      </Description>
      <Answer
        helperColor='warning.main'
        helperContent='Admin Only'
        helperIcon={<ErrorOutlineOutlinedIcon />}
        helperPosition='top'
      >
        <EquitySecuritiesTable />
      </Answer>
    </Question>
  );
}

export default SecurityClasses;

