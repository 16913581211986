import { useRecoilState, useResetRecoilState } from 'recoil';

import { saveStatusState } from '../../_state/application-form';

function useSaveStatusActions () {
  const [saveStatus, setSaveStatus] = useRecoilState(saveStatusState);
  const resetSaveStatus = useResetRecoilState(saveStatusState);

  return {
    success: (status, time, message) => setSaveStatus({ status, lastSaved: time, type: 'success', msg: message }),
    info: (formId, status, message) => setSaveStatus({ ...saveStatus, formId, status, msg: message, type: 'info', error: null }),
    error: (formId, code, message, error) => setSaveStatus({ ...saveStatus, type: 'error', formId, status: 'ERROR', msg: message, code, error }),
    clear: resetSaveStatus,
  };
}

export { useSaveStatusActions };