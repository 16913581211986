const API = `${process.env.REACT_APP_API_HOST}/v2`;

export const APIListingApplicationURL = (listingKey, offerKey) => {
  if (offerKey) return `${API}/listings/${listingKey}/offers/${offerKey}`;
  return `${API}/listings/${listingKey}`;
};

export const getAPI_URL = (formId='', listingKey='', offerKey='', keyIdentifier='') => {
  let url = APIListingApplicationURL(listingKey, offerKey);
  if (formId) url += `/forms/${formId}`;
  if (keyIdentifier) url += `/${keyIdentifier}`;

  return url;
};