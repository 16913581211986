import { AppBar, Box, Drawer, Stack, Toolbar } from '@mui/material';

import Footer from './Footer';
// import HelpMenu from './HelpMenu';
import MainMenu from './MainMenu';
import { NetcapitalLogo } from './Logo';
import UserMenu from './UserMenu';
import { styles } from './styles';

const HeaderDesktop = () => {
  return (
    <Box>
      <AppBar position='fixed' variant='light' sx={styles.HeaderDesktop}>
        <Toolbar sx={styles.HeaderToolbar}>
          <Stack direction='row'>
            {/* <HelpMenu /> */}
            <UserMenu />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={styles.MenuDesktop}
        variant='permanent'
        anchor='left'
        elevation={0}
      >
        <Toolbar sx={[styles.HeaderToolbar, styles.Sidebar]}>
          <NetcapitalLogo variant='light' />
        </Toolbar>
        <MainMenu isMobile={false} />
        <Footer />
      </Drawer>
    </Box>
  );
};

export default HeaderDesktop;
