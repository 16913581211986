import Table, { Body } from '../../../../netcapital-components/Table';
import { agreementsState, fetchFormGlobals, fetchFundingHistory } from '../../../../_state/application-form';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { FUNDING_HISTORY } from '../../../../static-info/listing-application-forms';
import FundingTableHeader from './header';
import FundingTableRow from './row';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function PreviousFundingRoundsTable() {
  const [dataFunding, setFundingData] = useRecoilState(fetchFundingHistory('raises'));
  const EXEMPTIONS = useRecoilValue(fetchFormGlobals('exemptions'));
  const resetAgreements = useResetRecoilState(agreementsState);
  const savingState = useAutoSave(FUNDING_HISTORY, 'raises', dataFunding);

  const handleChangeFunding = useCallback((row, newValue) => {
    const copyDataFunding = [...dataFunding];
    const entry = [...copyDataFunding][row];
    copyDataFunding[row] = {...entry, ...newValue};
    setFundingData([...copyDataFunding]);
    resetAgreements();
  }, [dataFunding, resetAgreements, setFundingData]);

  const onNewRowFunding = useCallback(() => {
    const initialDataFunding = [];
    const newDataFunding = {
      _key: uuidv4(),
      amountSold: 0,
      exemption: EXEMPTIONS[0].value,
      offeringDate: '',
      securityType: '',
      useOfFunds: ''
    };
    if(!dataFunding){
      initialDataFunding.push(newDataFunding);
      setFundingData(initialDataFunding);
      resetAgreements();
    } else {
      setFundingData([...dataFunding, newDataFunding]);
      resetAgreements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFunding, EXEMPTIONS]);

  const handleRemoveRow = useCallback((index) => {
    const copyDataFunding = [...dataFunding];
    copyDataFunding.splice(index, 1);
    setFundingData([...copyDataFunding]);
    resetAgreements();
  }, [dataFunding, resetAgreements, setFundingData]);

  return (
    <Table 
      onNewRow={onNewRowFunding} 
      error={savingState && savingState.state === 'error'}
    >
      <FundingTableHeader />
      {dataFunding && (
        <Body>
          {dataFunding.map((round, index) => (
            <FundingTableRow 
              key={round._key} 
              round={round} 
              index={index} 
              onChange={handleChangeFunding} 
              onRemoveRow={handleRemoveRow} 
            />
          ))}
        </Body>
      )}
    </Table>
  );
}
