import { Link, Typography } from '@mui/material';
import Question, { Answer } from '../../Question';
import { fetchBoardAuthorization, fetchBoardAuthorizationFile } from '../../../_state/application-form';

import FileField from '../FileField';
import styles from './styles';
import { useRecoilValue } from 'recoil';

function BoardAuthFiles() {
  const finalAuthorizationTemplateId = useRecoilValue(fetchBoardAuthorization('finalAuthorizationTemplateId'));

  return (
    <>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Upload Board Resolution Authorizing the Sale of New Shares (<Link href='https://cdn.netcapital.com/pdfs/legal/board-resolution.docx' sx={styles.Link}>download template</Link>)
        </Typography>
        <Answer>
          <FileField usage='BOARD_AUTHORIZATION' state={fetchBoardAuthorizationFile} />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Final Authorization (<Link href={`${process.env.REACT_APP_WEB_HOST}/files/${finalAuthorizationTemplateId}/final-authorization.pdf`} target='_blank' rel='noopener noreferrer' sx={styles.Link}>download template</Link>)
        </Typography>
        <Answer>
          <FileField usage='FINAL_AUTHORIZATION' state={fetchBoardAuthorizationFile} />
        </Answer>
      </Question>
    </>
  );
}

export default BoardAuthFiles;