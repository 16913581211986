import { Link, Typography } from '@mui/material';
import { fetchContactInfo, fetchFormGlobals } from '../../../_state/application-form';
import { useGetAgreements, useGetContactInfo } from '../../../_actions/application-form';

import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import Question from '../../Question';
import TermsAgreement from './TermsAgreement';
import { isRegA } from '../../../_helpers';
import styles from './styles';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function Agreements() {
  const { listingKey, offerKey } = useParams();
  useGetContactInfo(listingKey, offerKey);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const { loaded } = useGetAgreements(listingKey, offerKey);
  const isRegAOffering = isRegA(useRecoilValue(fetchFormGlobals('offerExemptions')));
  const urlEnd = isRegAOffering ? '-reg-a' : '';
  
  return (
    <Form title='Agreements' loaded={loaded}>
      <Typography variant='body1' sx={styles.Paragraph}>Please review the following:</Typography>
      <Question>The <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/legal/issuer${urlEnd}`} rel='noreferrer' sx={styles.Link}>Issuer Agreement</Link></Question>
      <Question>
        The <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/terms${urlEnd}`} rel='noreferrer' sx={styles.Link}>Terms of Service</Link>
        , <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/privacy${urlEnd}`} rel='noreferrer' sx={styles.Link}>Privacy Policy</Link>
        , <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/legal/copyright${urlEnd}`} rel='noreferrer' sx={styles.Link}>Copyright Policy</Link>
        , <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/legal/arbitration${urlEnd}`} rel='noreferrer' sx={styles.Link}>Arbitration Agreement</Link>
        {!isRegAOffering && (<>
          , <Link target='_blank' href={`${process.env.REACT_APP_WEB_HOST}/legal/waiver-letter`} rel='noreferrer' sx={styles.Link}>Waiver Letter</Link>
        </>)}
        , and <Link target='_blank' href={`${process.env.REACT_APP_CDN_PDF}/legal/Transfer-Agent-Agreement_FORM${isRegAOffering ? '_Reg-A' : ''}.pdf`} rel='noreferrer' sx={styles.Link}>Transfer Agent Agreement</Link>
      </Question>
      <Typography variant='body1' sx={styles.Adverb}>Furthermore,</Typography>
      {!isRegAOffering && (
        <Question>You represent and warrant that Netcapital is the only <Help explanation={HELP['Funding Portal']}>funding portal</Help> that {doingBusinessAs || 'your company'} is using. You also agree that you are responsible for complying with all laws, particularly securities laws, in all relevant jurisdictions. Your offering will be available in all 50 states, the District of Columbia, and United States territories and may be made available to non-U.S. investors.</Question>
      )}
      {isRegAOffering && (
        <Question>You agree that you are responsible for complying with all laws, particularly securities laws, in all relevant jurisdictions. Your offering will be available in all 50 states, the District of Columbia, and United States territories and may be made available to non-U.S. investors.</Question>
      )}
      <Question>You represent and warrant that you are a director or <Help explanation={HELP['Officer']}>officer</Help> of {doingBusinessAs || 'your company'}, that the {doingBusinessAs || 'your company'} securities to be offered and sold through netcapital have been duly authorized and issued, are fully paid and non-assessable, and that you have board authorization to sell shares.</Question>
      <br />
      <TermsAgreement doingBusinessAs={doingBusinessAs} />
    </Form>
  );
}

export default Agreements;
