import { NumberFormatPercent } from '../NumberFormat';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';

const percentInput = (props) => {
  const { onChange, value, valueSavedAs, isBoldFocus, isNoBorder, alignRight, ...other } = props;
  const inputProps = {
    inputComponent: NumberFormatPercent,
    inputProps: { valueSavedAs },
  };
  if (props.variant === 'standard') {
    inputProps.disableUnderline = true;
  }
  
  return (
    <TextField
      {...other}
      value={value}
      onChange={onChange}
      InputProps={inputProps}
      isBoldFocus={isBoldFocus}
      isNoBorder={isNoBorder}
      alignRight={alignRight}
    />
  );
};

const PercentInput = React.memo(percentInput);

PercentInput.defaultProps = {
  value: 0,
  valueSavedAs: 'decimal',
  isBoldFocus: false,
  isNoBorder: false,
  alignRight: false,
};

PercentInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isBoldFocus: PropTypes.bool,
  isNoBorder: PropTypes.bool,
  alignRight: PropTypes.bool,
  value: PropTypes.number,
  valueSavedAs: PropTypes.oneOf([ 'decimal', 'percentage' ]),
};

export default PercentInput;