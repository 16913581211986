import { useEffect, useState } from 'react';

import { RISKS } from '../../static-info/listing-application-forms';
import { getAPI_URL } from './_api-urls';
import {risksState} from '../../_state/application-form';
import { useFetchWrapper } from '../../_helpers';
import { useRecoilState } from 'recoil';

export function useGetRisks(listingKey='', offerKey=''){
  const [risks, setRisks] = useRecoilState(risksState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (Object.keys(risks).length > 0){
      setLoaded(true);
      return abort;
    }
    
    const url = getAPI_URL(RISKS, listingKey, offerKey);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setRisks(response.risks);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, offerKey, risks]);

  return { loaded };
}