import {fetchRequest, useFetchWrapper} from '../_helpers';
import { useEffect, useState } from 'react';

import { questionnairesState } from '../_state/questionnaires';
import { useSetRecoilState } from 'recoil';

export function useGetQuestionnaires(queryParams){
  const setQuestionnaires = useSetRecoilState(questionnairesState);

  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  queryParams['limit'] = 25;
  
  const query = Object.keys(queryParams ? queryParams : {})
    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
    
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }
    
    setLoaded(false);

    fetchWrapper.get(`${process.env.REACT_APP_API_HOST}/v2/listings/admin/listings/questionnaires?${query}`, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setQuestionnaires({ metadata: response.metadata, data: response.data });
        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { loaded };
}

export async function patchLockQuestionnaire(listingKey, locked){
  const response = await fetchRequest(
    'patch',
    `${process.env.REACT_APP_API_HOST}/v2/listings/${listingKey}/lock-application`,
    {isLocked: locked}
  ).catch((error) => error);

  return response;
}
