const CONTACT_INFO = 'contact-information',
  SOCIAL_MEDIA = 'social-media',
  COMPANY_FORMATION = 'company-formation',
  COMPANY_DESCRIPTION = 'company-description',
  FUNDING_HISTORY = 'funding-history',
  CAP_TABLE = 'cap-table',
  SECURITIES = 'securities',
  KEY_PERSON = 'key-person',
  KEY_PEOPLE = 'key-people',
  SHARES = 'shares-and-pricing',
  UOP = 'use-of-proceeds',
  RISKS = 'risks',
  COMPLIANCE = 'compliance',
  FINANCIAL_CONDITION = 'financial-condition',
  FINANCIAL_STATEMENTS = 'financial-statements',
  FINANCIAL_REPORT = 'financial-reports',
  BOARD_AUTHORIZATION = 'board-authorization',
  AGREEMENTS = 'agreements',
  DEFAULT = 'default',
  METADATA = 'metadata';

const FORMS_RESPONSE_OBJECT = {
  [DEFAULT]: 'default',
  [AGREEMENTS]: 'agreements',
  [BOARD_AUTHORIZATION]: 'boardAuthorization',
  [CAP_TABLE]: 'capTable',
  [COMPANY_DESCRIPTION]: 'companyDescription',
  [COMPANY_FORMATION]: 'companyFormation',
  [COMPLIANCE]: 'compliance',
  [CONTACT_INFO]: 'contactInfo',
  [FINANCIAL_CONDITION]: 'financialCondition',
  [FINANCIAL_REPORT]: 'financialReport',
  [FINANCIAL_STATEMENTS]: 'financialStatements',
  [FUNDING_HISTORY]: 'fundingHistory',
  [KEY_PEOPLE]: 'keyPeople',
  [KEY_PERSON]: 'keyPerson',
  [METADATA]: 'metadata',
  [RISKS]: 'risks',
  [SECURITIES]: 'securities',
  [SHARES]: 'shares',
  [SOCIAL_MEDIA]: 'socialMedia',
  [UOP]: 'uop',
};

export {
  FORMS_RESPONSE_OBJECT,
  DEFAULT,
  CONTACT_INFO,
  SOCIAL_MEDIA,
  COMPANY_FORMATION,
  COMPANY_DESCRIPTION,
  FUNDING_HISTORY,
  CAP_TABLE,
  SECURITIES,
  KEY_PERSON,
  KEY_PEOPLE,
  SHARES,
  UOP,
  RISKS,
  COMPLIANCE,
  FINANCIAL_CONDITION,
  FINANCIAL_STATEMENTS,
  FINANCIAL_REPORT,
  BOARD_AUTHORIZATION,
  AGREEMENTS,
  METADATA
};