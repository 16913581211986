import {
  fetchFormGlobals,
  progressState
} from '../../_state/application-form';
import { useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Session from '../../_session';
import _ from 'lodash';
import axios from 'axios';
import { createMachine } from 'xstate';
import { getAPI_URL } from './_api-urls';
import { isAdminState } from '../../_state/user';
import { preparePayload } from '../../_helpers';
import { useMachine } from '@xstate/react';
import { useParams } from 'react-router-dom';
import { useSaveStatusActions } from './_save-status.actions';

export const DEBOUNCE_AUTOSAVE = 2000;

export const autoSaveMachine = createMachine(
  {
    id: 'autoSaveMachine',
    predictableActionArguments: true,
    initial: 'idle',
    states: {
      idle: {
        on: {
          READY_TO_SAVE: 'waitingToSave'
        }
      },
      saved: {
        on: {
          HAS_UNSAVED_DATA: 'waitingToSave'
        }
      },
      waitingToSave: {
        after: [
          {
            delay: (context, event) => event.delay ?? DEBOUNCE_AUTOSAVE,
            target: 'readyToSave'
          }
        ]
      },
      readyToSave: {
        on: {
          SAVE_DATA: 'saving'
        }
      },
      saving: {
        invoke: {
          src: 'saveData',
          onDone: 'saved',
          onError: 'error'
        }
      },
      error: {
        on: {
          RESTART: 'idle',
          HAS_UNSAVED_DATA: 'waitingToSave',
        }
      }
    }
  },
  {
    services: {
      saveData: async (context, event) => {
        let payload = {};

        if (event.attribute === 'ALL') {
          payload = { ...event.value };
        }
        else {
          payload[event.attribute] = event.value;
        }
        
        await Session.refreshToken();

        return new Promise((resolve, reject) => {
          const requestOptions = {
            method: 'PATCH',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Session.token()}`,
            },
            data: preparePayload(payload),
          };

          let url = getAPI_URL(event.formId, event.listingKey, event.offerKey, event.keyIdentifier);
          if (event.questionPath) {
            url = `${url}/${event.questionPath}`;
          }
          return axios(url, requestOptions)
            .then((response) => {
              resolve({
                status: response.status,
                progress: response.data.progress,
                message: `Saved at ${Date.now().toLocaleString()}`,
              });
            }).catch((error) => {
              reject({
                details: error,
                error: 'Error saving data on autosave.',
              });
            });
        });
      }
    }
  }
);

export const useAutoSave = (formId, attribute, value, keyIdentifier='', questionPath='') => {
  const { listingKey, offerKey } = useParams();
  const isLocked = useRecoilValue(fetchFormGlobals('isLocked'));
  const isAdmin = useRecoilValue(isAdminState);
  const saveStatusActions = useSaveStatusActions();
  const prevValue = useRef(value);
  const setProgressLevel = useSetRecoilState(progressState);
  const [current, send] = useMachine(autoSaveMachine);

  useEffect(() => {
    if (isLocked && !isAdmin) {
      saveStatusActions.error(null, 403, 'This application is currently locked.');
      return;
    }

    if (!_.isEqual(prevValue.current, value)) {
      send('HAS_UNSAVED_DATA');
      saveStatusActions.info(formId, 'UNSAVED', 'You have unsaved changes.');
    }

    if (!_.isEqual(prevValue.current, value) && current.value === 'idle') {
      send('READY_TO_SAVE');
    }
    
    if (current.matches('error')) {      
      // if API returns 401, we need to refresh the session to kick the user out
      if (current.event.data.status === 401) {
        Session.clear();
        return;
      }

      saveStatusActions.error(formId, current.event.data.status, 'An error occured while saving', current.event.data);
    }

    if (current.matches('waitingToSave')) {
      prevValue.current = value;
      saveStatusActions.info(formId, 'SAVING', 'Autosaving...');
    }
    
    if (current.matches('readyToSave')) {
      // save request
      send('SAVE_DATA', { 
        attribute,
        formId,
        listingKey,
        offerKey,
        keyIdentifier,
        questionPath,
        value: prevValue.current,
      });
    }
    
    if (current.matches('saved')) {
      if (current?.event?.data?.progress) {
        setProgressLevel(current?.event?.data?.progress);
      }
      
      saveStatusActions.success('SAVED', new Date(), 'All changes have been saved.');
    }
    
    return;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, current]);
  
  return {
    state: current.value,
  };
};