import Table, { Body } from '../../../../netcapital-components/Table';

import { SECURITIES } from '../../../../static-info/listing-application-forms';
import WarrantsOptionsOtherRightsRow from './row';
import WarrantsOptionsOtherRightsTableHeader from './header';
import { fetchSecurities } from '../../../../_state/application-form';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useGetSecurities } from '../../../../_actions/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

export default function WarrantsOptionsOtherRightsTable(){
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetSecurities(listingKey, offerKey);
  const [optionSecurities, setOptionSecurities] = useRecoilState(fetchSecurities('optionSecurities'));
  const savingState = useAutoSave(SECURITIES, 'optionSecurities', optionSecurities);

  const handleAddOptionSecurity = useCallback(() => {
    const initialOptionsSecurities = [];
    const newOptionSecurity = {
      _key: uuidv4(),
      optionDescription: '',
      optionNum: 0,
      optionType: 'Warrant'
    };
    if(!optionSecurities){
      initialOptionsSecurities.push(newOptionSecurity);
      setOptionSecurities(initialOptionsSecurities);
    } else {
      setOptionSecurities([...optionSecurities, newOptionSecurity]);
    }
  },[optionSecurities, setOptionSecurities]);

  const handleRemoveOptionSecurity = useCallback((index) => {
    const copyOptionSecurities = [...optionSecurities];
    copyOptionSecurities.splice(index, 1);
    setOptionSecurities([...copyOptionSecurities]);
  },[optionSecurities, setOptionSecurities]);

  const handleChangeOptionSecurity = useCallback((row, newValue) => {
    const copyOptionSecurities = [...optionSecurities];
    const entry = [...copyOptionSecurities][row];
    copyOptionSecurities[row] = {...entry, ...newValue};
    setOptionSecurities([...copyOptionSecurities]);
  },[optionSecurities, setOptionSecurities]);

  if (!loaded) return null;
  
  return (
    <Table onNewRow={handleAddOptionSecurity}>
      <WarrantsOptionsOtherRightsTableHeader />
      {optionSecurities && 
        <Body>
          {optionSecurities.map((security, index) => (
            <WarrantsOptionsOtherRightsRow 
              key={security._key}
              index={index} 
              security={security} 
              onRemove={handleRemoveOptionSecurity} 
              onChange={handleChangeOptionSecurity} 
              error={savingState && savingState.state === 'error'}
            />
          ))}
        </Body>
      }
    </Table>
  );
}