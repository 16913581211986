import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { regCfAlertAtom } from '../../_state/application-form';


function useRegCfAlertActions () {
  const setAlert = useSetRecoilState(regCfAlertAtom);
  const resetAlert = useResetRecoilState(regCfAlertAtom);
  
  return {
    error: (title, response, message, request) => {
      setAlert({ error: {
        title: title || 'An error has occurred',
        response,
        message,
        request,
      }, type: 'error' });},
    clear: resetAlert
  };
}

export { useRegCfAlertActions };