import { Cell, Row } from '../../../../netcapital-components/Table';
import { fetchFinancialStatements, fetchFormGlobals } from '../../../../_state/application-form';
import { useAutoSave, useDeleteFinancialReport } from '../../../../_actions/application-form';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import DateSelector from '../../../../netcapital-components/DateSelector';
import { FINANCIAL_REPORT } from '../../../../static-info/listing-application-forms';
import _ from 'lodash';
import { isAdminState } from '../../../../_state/user';
import produce from 'immer';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

function FiscalYearEndDate({ report, index }) {
  const { listingKey, offerKey } = useParams();
  const [ financialReports, setFinancialReports ] = useRecoilState(fetchFinancialStatements('financialReports'));  
  const { destroy: deleteFinancialReport } = useDeleteFinancialReport(listingKey, offerKey, report.key);
  const setFinancialReportsGlobalState = useSetRecoilState(fetchFormGlobals('financialReports'));
  const savingState = useAutoSave(FINANCIAL_REPORT, 'dateEnd', report.dateEnd, report.key);
  const isAdmin = useRecoilValue(isAdminState);
  
  const handleChangeFinancialReportDate = useCallback((date) => {
    const updatedReport = produce(financialReports[index], (draft) => {
      _.set(draft, 'dateEnd', date);
    });
    const updatedFinancialReports = produce(financialReports, (draft) => {
      draft[index] = updatedReport;
    });
    setFinancialReports(updatedFinancialReports);
    setFinancialReportsGlobalState(updatedFinancialReports);
  }, [financialReports, setFinancialReports, setFinancialReportsGlobalState, index]);
  
  const handleDeleteFinancialReport = useCallback(deleteFinancialReport, [deleteFinancialReport]);

  return (
    <Row onClickRemove={isAdmin ? handleDeleteFinancialReport : false}>
      <Cell key={`body_${report._key}`}>
        <DateSelector 
          onChange={handleChangeFinancialReportDate}
          maxDate={new Date()}
          value={report.dateEnd || ''}
          size='small'
          error={savingState && savingState.state === 'error'}
        />
      </Cell>
    </Row>
  );
}

export default FiscalYearEndDate;