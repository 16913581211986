const styles = {
  ErrorHeader: ({breakpoints}) => ({
    display: 'flex',
    marginBottom: '20px',
    paddingTop: '25px',
    [breakpoints.up(800)]: {
      marginLeft: 'auto',   
      marginRight: 'auto',
      width: '75%',
      paddingTop: '50px',
    },
  }),
  ErrorTitle: ({breakpoints}) => ({
    [breakpoints.between(800, 1199)]: {
      fontSize: '32px'
    },
    [breakpoints.up(1200)]: {
      fontSize: '48px'
    },
  }),
  ErrorBody: ({breakpoints}) => ({
    display: 'flex',
    paddingBottom: '50px',
    [breakpoints.up(800)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '75%',
      paddingBottom: '100px',
    },
  }),
  RedirectLink: {
    cursor: 'pointer'
  }
};

export default styles;