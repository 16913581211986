import { Box, Button, Container, Paper, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Body from './body';
import Cell from './cell';
import { CircularLoading } from '../CircularLoading';
import EditField from './editfield';
import Footer from './footer';
import Header from './header';
import InfiniteScroll from 'react-infinite-scroll-component';
import MUITable from '@mui/material/Table';
import MUITableContainer from '@mui/material/TableContainer';
import PropTypes from 'prop-types';
import React from 'react';
import Row from './row';
import styles from './styles';

const table = ({ children, dataLength, disableAddRow, fetchMoreData, hasInfiniteScrolling, hasMoreResults, onNewRow, newRowCTA }) => {
  const tableJsx = (
    <MUITableContainer name='Netcapital-Table' component={Paper} sx={styles.Paper}>
      <Box sx={styles.TableWrapper}>
        <MUITable sx={styles.Table} size='small'>
          {children}
        </MUITable>
      </Box>
      {(onNewRow && !disableAddRow) && (
        <Container sx={styles.Container}>
          <Button 
            variant='contained' 
            size='large'
            color='primary'
            sx={styles.AddButton} 
            onClick={onNewRow} 
            endIcon={<AddIcon sx={styles.AddIcon} />}
          >
            {newRowCTA}
          </Button>
        </Container>
      )}
    </MUITableContainer>
  );

  if(hasInfiniteScrolling){
    return (
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchMoreData}
        hasMore={hasMoreResults}
        loader={
          hasMoreResults ? 
            <Box sx={styles.CircularLoadingContainer}>
              <CircularLoading />
              <Typography variant='body1'>Loading ...</Typography>
            </Box>
            :
            null
        }
        endMessage={
          <Typography variant='body1' sx={styles.EndMessage}>
            This is the last result
          </Typography>
        }
      >
        {tableJsx}
      </InfiniteScroll>
    );
  }

  return tableJsx;
};

export const Table = React.memo(table);

Table.defaultProps = {
  children: <>
    <Header>
      <Cell>Cell</Cell>
    </Header>
    <Body>
      <Row>
        <Cell>Cell</Cell>
      </Row>
    </Body>
    <Footer>
      <Cell>Cell</Cell>
    </Footer>
  </>,
  dataLength: 0,
  disableAddRow: false,
  fetchMoreData: () => { return; },
  hasInfiniteScrolling: false,
  hasMoreResults: false,
  onNewRow: false,
  newRowCTA: 'Add New',
};

Table.propTypes = {
  children: PropTypes.node,
  dataLength: PropTypes.number,
  disableAddRow: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  hasInfiniteScrolling: PropTypes.bool,
  hasMoreResults: PropTypes.bool,
  onNewRow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  newRowCTA: PropTypes.string,
};

export default Table;

export {
  Body,
  Cell,
  EditField,
  Footer,
  Header,
  Row,
  styles,
};

/****
 * <Table>
 *   <Header><Cell>#whatever</Cell></Header>
 *   <Body>
 *      <Row><Cell>#whatever</Cell></Row>
 *      <Row><Cell>#whatever2</Cell></Row>
 *   </Body>
 *   <Footer><Cell>#whatever</Cell></Footer>
 * </Table>
 * ****/
