import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const keyPersonState = atom({
  key: 'KeyPerson',
  default: {}
});

const fetchKeyPersonDetails = selectorFamily({
  key: 'fetchKeyPersonDetails',
  get: field => ({get}) => _.get(get(keyPersonState), field),
  set: field => ({set}, newValue) =>
    set(keyPersonState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchKeyPersonDetails,
  keyPersonState,
};
