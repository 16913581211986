import Input from '@mui/material/Input';
import React from 'react';
import styles from './styles';
const component = props => (
  <Input name='Netcapital-Table-EditField' fullWidth variant="standard" sx={styles.EditField}
    disableUnderline {...props} />
);

export const EditField = React.memo(component);

export default EditField;