import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useFetchWrapper } from '../_helpers';
import { usersState } from '../_state/search-user';

export function useGetUsers(queryParams){
  const [users, setUsers] = useRecoilState(usersState);
  const resetUsers = useResetRecoilState(usersState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(true); //initialize as true since there will be no GET on initial render, only when user interacts with autocomplete input
  
  queryParams['mode'] = 'usersAndHoldings';
  queryParams['limit'] = 10;

  const query = Object.keys(queryParams ? queryParams : {})
    .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
    
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if(!queryParams.search){
      resetUsers();
      setLoaded(true);
      return abort;
    }
    
    setLoaded(false);

    fetchWrapper.get(`${process.env.REACT_APP_API_HOST}/v2/users?${query}`, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        let data = response;
      
        if(Object.keys(users).length > 0 && queryParams && queryParams.page > 1){
          data = [...users, ...response];
        }
 
        setUsers({ data });
        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });
      
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return { loaded };
}