import Question, { Answer } from '../../Question';

import { COMPANY_FORMATION } from '../../../static-info/listing-application-forms';
import DateSelector from '../../../netcapital-components/DateSelector';
import { Typography } from '@mui/material';
import { fetchCompanyFormation } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function DateOfFormation({ doingBusinessAs }) {
  const [dateFormed, setDateFormed] = useRecoilState(fetchCompanyFormation('dateFormed'));
  const savingState = useAutoSave(COMPANY_FORMATION, 'dateFormed', dateFormed);

  const handleDateFormedChange = useCallback((date) => {
    setDateFormed(date);
  }, [setDateFormed]);
  
  return (
    <Question>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        When was {doingBusinessAs || 'your company'} legally formed?
      </Typography>
      <Answer>
        <DateSelector
          onChange={handleDateFormedChange}
          value={dateFormed || ''}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}