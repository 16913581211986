import TextField from '../../../netcapital-components/TextField';
import { UOP } from '../../../static-info/listing-application-forms';
import { fetchUseOfProceeds } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function Purpose() {
  const [purpose, setPurpose] = useRecoilState(fetchUseOfProceeds('purpose'));
  const savingState = useAutoSave(UOP, 'purpose', purpose);
  const handleChange = useCallback(e => setPurpose(e.target.value), [setPurpose]);

  if (typeof purpose === 'undefined') return null;

  return (
    <TextField
      multiline
      rows={10}
      value={purpose}
      onChange={handleChange}
      placeholder='Describe how you would use the proceeds from your raise.'
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default Purpose;
