const styles = {
  CellBoxNested: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    'span:first-of-type': {
      marginRight: '20px',
    },
  },
  CellSpan: ({ breakpoints }) => ({
    fontSize: '13px',
    [breakpoints.up('md')]: {
      fontSize: '15px',
    },
  }),
  UseOfProceedsTableSizes:{
    Proceed: {
      width: '130px',
    },
    AmountMinHeader: {
      width: '150px',
    },
  },
  NotEditable: {
    cursor: 'default',
    userSelect: 'none',
  },
};

export default styles;