import { Cell, Header } from '../../../../netcapital-components/Table';

import { Typography } from '@mui/material';

export default function EmploymentRecordsHeader() {
  return (
    <Header>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Start Date</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>End Date</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Company</Typography>
      </Cell>
      <Cell>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Position &#47; Title</Typography>
      </Cell>
    </Header>
  );
}
