import { useGetContactInfo, useGetFinancialCondition } from '../../../_actions/application-form';

import { FinancialCondition } from './FinancialCondition';
import Form from '../FormWrapper';
import { fetchContactInfo } from '../../../_state/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function FinancialConditionForm() {
  const {listingKey, offerKey} = useParams();
  const { loaded } = useGetFinancialCondition(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  
  return (
    <Form title='Financial Condition' loaded={loaded && loadedContactInfo}>
      <FinancialCondition doingBusinessAs={doingBusinessAs} />
    </Form>
  );
}

export default FinancialConditionForm;
