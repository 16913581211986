import Question, { Answer } from '../../Question';

import { SOCIAL_MEDIA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Typography } from '@mui/material';
import { fetchSocialMedia } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function TwitterURL() {
  const [twitterUrl, setTwitterUrl] = useRecoilState(fetchSocialMedia('twitter'));
  const savingState = useAutoSave(SOCIAL_MEDIA, 'twitter', twitterUrl);
  const handleChange = useCallback(e => setTwitterUrl(e.target.value), [setTwitterUrl]);

  if (typeof twitterUrl === 'undefined') return null;

  return (
    <Question>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        What is the address of your company’s Twitter account? (optional)
      </Typography>
      <Answer>
        <TextField
          placeholder={'https://twitter.com'}
          icon={<TwitterIcon />}
          value={twitterUrl}
          onChange={handleChange}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}

export default TwitterURL;
