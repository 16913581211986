import Address from './address';
import DoingBusinessAs from './doingBusinessAs';
import Form from '../FormWrapper';
import LegalName from './legalName';
import NumberOfEmployees from './employees';
import PreviousNames from './previousNames';
import { Typography } from '@mui/material';
import Website from './website';
import styles from './styles';
import { useGetContactInfo } from '../../../_actions/application-form';
import { useParams } from 'react-router-dom';

export default function ContactInformation() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetContactInfo(listingKey, offerKey);

  return (
    <Form title='Contact Information' loaded={loaded}>
      <Typography variant='body1' sx={styles.IntroParagraph}>
        In order to sell shares, we’ll need to file a few forms with the SEC for you. To do so, you will need to answer each question on the following pages. You should assume that any information you give us will be made public and available to investors.
      </Typography>
      <LegalName />
      <PreviousNames />
      <DoingBusinessAs />
      <Address />
      <Website />
      <NumberOfEmployees />
    </Form>
  );
}
