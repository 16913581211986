import { Buffer } from 'buffer/';
import _ from 'lodash';
import axios from 'axios';

const STORAGE_KEY = 'nc_listings_web_session';

const encodeFromJSON = (json) => {
  if (!json) return null;
  return Buffer.from(JSON.stringify(json)).toString('base64');
};

const decodeToJSON = (encodedString) => {
  if (!encodedString) return null;
  return JSON.parse(Buffer.from(encodedString, 'base64').toString('utf8') ?? null);
};

const toJSON = () => {
  return decodeToJSON(sessionStorage.getItem(STORAGE_KEY));
};

const get = (attribute) => {
  return _.get(toJSON(), attribute);
};
  
const set = (data) => {
  const session = toJSON();
  const encodedData = encodeFromJSON({ ...session, ...data });
  sessionStorage.setItem(STORAGE_KEY, encodedData);
};
  
const clear = () => sessionStorage.setItem(STORAGE_KEY, null);

const token = () => get('user.accessToken');
  
const expiresOn = () => get('user.accessTokenExpiresAt');

function isTokenExpired() {
  const expiration = expiresOn();
  return !expiration || new Date() >= new Date(expiration);
}

const fetchVerifyToken = (signal) => {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    signal
  };

  return axios(`${process.env.REACT_APP_API_HOST}/v2/auth/verify`, requestOptions);
};

const setFromResponse = (response) => {
  if (!response || !response.authenticated) return;
  set({
    mimicMode: response.mimicMode || false,
    user: {
      firstName: response.user.firstName,
      lastName: response.user.lastName,
      accessToken: response.user.accessToken,
      accessTokenExpiresAt: response.user.accessTokenExpiresAt,
    },
  });
};

const refreshToken = async () => {
  if (isTokenExpired()) {
    const controller = new AbortController();
    const signal = controller.signal;
    const response = await fetchVerifyToken(signal);
    if (!response.data.authenticated){
      window.location.href = `${process.env.REACT_APP_API_HOST}/v2/auth/login?returnTo=${window.location.href}`;
      return;
    }

    setFromResponse(response.data);
  }
};

const Session = {
  toJSON,
  get,
  set,
  setFromResponse,
  clear,
  token,
  expiresOn,
  isTokenExpired,
  refreshToken,
  fetchVerifyToken,
};

export default Session;