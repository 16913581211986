import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const financialStatementsState = atom({
  key: 'financialStatementsState',
  default: {}
});

const fetchFinancialStatements = selectorFamily({
  key: 'fetchFinancialStatements',
  get: field => ({get}) => _.get(get(financialStatementsState), field),
  set: field => ({set}, newValue) =>
    set(financialStatementsState, prevState => ({...prevState, [field]: newValue})),
});

const fetchFinancialStatementsFile = selectorFamily({
  key: 'fetchFinancialStatementsFile',
  get: usage => ({get}) => {
    const files = get(financialStatementsState).files || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(financialStatementsState).files || [];

    if (!newFile) {
      set(financialStatementsState, prevState => ({...prevState, files: files.filter(file => file.usage !== usage)}));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(financialStatementsState, prevState => ({...prevState, files: updatedFiles}));
    }
  }
});

export {
  fetchFinancialStatements,
  financialStatementsState,
  fetchFinancialStatementsFile,
};
