import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonIsPromoter() {
  const { key: personKey } = useParams();
  const [ isPromoter, setIsPromoter ] = useRecoilState(fetchKeyPersonDetails('isPromoter'));
  const savingState = useAutoSave(KEY_PERSON, 'isPromoter', isPromoter, personKey);
  const onChangeIsPromoter = useCallback((e) => setIsPromoter(e.target.value === 'true'), [setIsPromoter]);

  if (typeof isPromoter === 'undefined') return null;

  return (
    <RadioGroup
      name='isPromoter'
      value={isPromoter}
      onChange={onChangeIsPromoter}
      options={RADIO_OPTIONS.YES_NO}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonIsPromoter;
