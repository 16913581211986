import Table, { Body, Cell, EditField, Header, Row } from '../../../../netcapital-components/Table';
import { useRecoilState, useRecoilValue } from 'recoil';

import { KEY_PEOPLE } from '../../../../static-info/listing-application-forms';
import MoneyInput from '../../../../netcapital-components/MoneyInput';
import _ from 'lodash';
import { fetchKeyPeople } from '../../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

function RelatedPartiesTransactionsTable() {
  const [relatedPartiesTransactions, setRelatedPartiesTransactions] = useRecoilState(fetchKeyPeople('transactions.relatedPartiesTransactions'));
  const savingState = useAutoSave(KEY_PEOPLE, 'relatedPartiesTransactions', relatedPartiesTransactions);
  const hasRelatedPartiesTransactions = useRecoilValue(fetchKeyPeople('transactions.hasRelatedPartiesTransactions'));

  const handleAddTransaction = useCallback(() => {
    const newTransaction = {
      _key: uuidv4(),
      fullName: 'Name',
      relationship: 'Relationship',
      natureOfInterest: 'Interest',
      amountOfInterest: 1,
    };
    setRelatedPartiesTransactions([...relatedPartiesTransactions, newTransaction]);
  }, [setRelatedPartiesTransactions, relatedPartiesTransactions]);

  const handleChangeTransaction = useCallback((index, attribute) => (e) => {
    let newValue = e;
    if (attribute !== 'amountOfInterest') {
      newValue = e.target.value;
    }
    const updatedTransactions = produce(relatedPartiesTransactions, (draft) => {
      _.set(draft[index], attribute, newValue);
    });
    setRelatedPartiesTransactions([...updatedTransactions]);
  },[setRelatedPartiesTransactions, relatedPartiesTransactions]);
  
  const handleRemoveTransaction = useCallback((index) => () => {
    const updatedValues = [...relatedPartiesTransactions];
    updatedValues.splice(index, 1);
    setRelatedPartiesTransactions([...updatedValues]);
  }, [setRelatedPartiesTransactions, relatedPartiesTransactions]);

  if (!hasRelatedPartiesTransactions) return null;

  return (
    <Table onNewRow={handleAddTransaction} error={savingState && savingState.state === 'error'}>
      <Header>
        <Cell>Specified Person</Cell>
        <Cell>Relationship to Your Company</Cell>
        <Cell>Nature of Interest in Transaction</Cell>
        <Cell>Amount of Interest</Cell>
      </Header>
      <Body>
        {relatedPartiesTransactions?.map((transaction, index) => (
          <Row onClickRemove={handleRemoveTransaction(index)} key={transaction._key}>
            <Cell>
              <EditField 
                value={transaction.fullName} 
                onChange={handleChangeTransaction(index, 'fullName')} 
                placeholder='Name' 
              />
            </Cell>
            <Cell>
              <EditField 
                value={transaction.relationship} 
                onChange={handleChangeTransaction(index, 'relationship')} 
                placeholder='CEO, Son of Board Member, etc' 
              />
            </Cell>
            <Cell>
              <EditField 
                value={transaction.natureOfInterest} 
                onChange={handleChangeTransaction(index, 'natureOfInterest')} 
                placeholder='Interest'
              />
            </Cell>
            <Cell>
              <MoneyInput 
                value={transaction.amountOfInterest} 
                onChange={handleChangeTransaction(index, 'amountOfInterest')} 
                variant='standard'
              />
            </Cell>
          </Row>
        ))}
      </Body>
    </Table>
  );
}

export default RelatedPartiesTransactionsTable;
