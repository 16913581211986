import Table, { Body, Cell, Header, Row } from '../../../netcapital-components/Table';
import { fetchFinancialStatements, financialReportState } from '../../../_state/application-form';
import { useCallback, useEffect } from 'react';

import { FINANCIAL_REPORT } from '../../../static-info/listing-application-forms';
import MoneyInput from '../../../netcapital-components/MoneyInput';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form';
import { useRecoilState } from 'recoil';

const reportAnswers = [
  { 
    label: 'Total Assets',
    attribute: 'assets',
  },
  { 
    label: 'Cash and Cash Equivalents',
    attribute: 'cash',
  },
  { 
    label: 'Accounts Receivable',
    attribute: 'accountsReceivable',
  },
  { 
    label: 'Short-term Debt',
    attribute: 'shortTermDebt',
  },
  { 
    label: 'Long-term Debt',
    attribute: 'longTermDebt',
  },
  { 
    label: 'Revenue/Sales',
    attribute: 'revenue',
  },
  { 
    label: 'Cost of Goods Sold',
    attribute: 'costOfGoodsSold',
  },
  { 
    label: 'Taxes Paid',
    attribute: 'taxesPaid',
  },
  { 
    label: 'Net Income (or loss)',
    attribute: 'netIncome',
  },
];

function FinancialReportTable({ financialReportKey }) {
  const [ financialReports, setFinancialReports ] = useRecoilState(fetchFinancialStatements('financialReports'));
  const [ financialReportInState, setFinancialReportInState ] = useRecoilState(financialReportState);
  const reportIndex = financialReports.findIndex(report => report.key === financialReportKey);
  const savingState = useAutoSave(FINANCIAL_REPORT, 'ALL', financialReportInState, financialReportKey);
  
  const handleChange = useCallback((attribute) => (newValue) => {
    const updatedReport = produce(financialReports[reportIndex], (draft) => {
      _.set(draft, attribute, newValue);
    });
    const updatedFinancialReports = produce(financialReports, (draft) => {
      draft[reportIndex] = updatedReport;
    });
    setFinancialReports(updatedFinancialReports);
    setFinancialReportInState(updatedReport);
  }, [financialReports, reportIndex, setFinancialReports, setFinancialReportInState]);
  
  useEffect(() => {
    if (!financialReportInState && reportIndex >= 0) {
      setFinancialReportInState(financialReports[reportIndex]);
    }
  }, [financialReportInState, financialReports, reportIndex, setFinancialReportInState]);
  
  return (
    <Table>
      <Header>
        <Cell>Description</Cell>
        <Cell>Amount</Cell>
      </Header>
      <Body>
        {reportAnswers.map((item) => (
          <Row key={item.attribute} error={savingState && savingState.state === 'error'}>
            <Cell>{item.label}</Cell>
            <Cell>
              <MoneyInput
                value={financialReports[reportIndex][item.attribute]}
                onChange={handleChange(item.attribute)}
                variant="standard"
              />
            </Cell>
          </Row>
        ))}
      </Body>
    </Table>
  );
}

FinancialReportTable.propTypes = {
  financialReportKey: PropTypes.string.isRequired,
};

export default FinancialReportTable;