import { Link, Stack } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SocialLinks = () => {
  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={1}
    >
      <Link href='https://www.facebook.com/Netcapital/' target='_blank' rel='noopener' color='common.white'>
        <FacebookIcon />
      </Link>
      <Link href='https://www.linkedin.com/company/netcapital' target='_blank' rel='noopener' color='common.white'>
        <LinkedInIcon />
      </Link>
      <Link href='https://twitter.com/netcapital' target='_blank' rel='noopener' color='common.white'>
        <TwitterIcon />
      </Link>
      <Link href='https://www.instagram.com/netcapital/' target='_blank' rel='noopener' color='common.white'>
        <InstagramIcon />
      </Link>
      <Link href='https://www.youtube.com/channel/UCxwsozT-gzwmpewB4IXnRsw' target='_blank' rel='noopener' color='common.white'>
        <YouTubeIcon />
      </Link>
    </Stack>
  );
};

export default SocialLinks;