import { NumberFormatThousands } from '../NumberFormat';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';

const numberInput = (props) => {
  const { onChange, value, isNoBorder, alignRight, isBoldFocus, ownerState, ...other } = props;

  const inputProps = { inputComponent: NumberFormatThousands }; 
  if (props.variant === 'standard') {
    inputProps.disableUnderline = true;
  }

  return (
    <TextField
      name='NumberInput'
      {...other}
      value={value}
      onChange={onChange}
      InputProps={inputProps}
      isNoBorder={isNoBorder}
      isBoldFocus={isBoldFocus}
      alignRight={alignRight}
      ownerState={ownerState}
    />
  );
};

export const NumberInput = React.memo(numberInput);

NumberInput.defaultProps = {
  value: 0,
  isBoldFocus: true,
  isNoBorder: false,
  alignRight: false,
  ownerState: { subVariant: '' },
};

NumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  isBoldFocus: PropTypes.bool,
  isNoBorder: PropTypes.bool,
  alignRight: PropTypes.bool,
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  })
};

export default NumberInput;