import Question, { Answer } from '../../Question';
import { useGetContactInfo, useGetRisks } from '../../../_actions/application-form';

import AdditionalRisks from './AdditionalRisks';
import CorporateActionRisks from './CorporateActionRisks';
import Form from '../FormWrapper';
import MinorityOwnershipRisks from './MinorityOwnershipRisks';
import OtherShareholderRisks from './OtherShareholderRisks';
import SecurityCaveats from './SecurityCaveats';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../_state/application-form';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function Risks() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetRisks(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));

  return (
    <Form title='Risks' loaded={loaded && loadedContactInfo}>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          How could the exercise of rights held by the individuals who each hold greater than 20% of the outstanding voting securities affect the purchasers of the securities being offered?
        </Typography>
        <Answer>
          <OtherShareholderRisks />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          How might the rights of currently outstanding securities limit, dilute, or qualify the rights of the securities you will offer on Netcapital?
        </Typography>
        <Answer>
          <SecurityCaveats />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          What are the risks to Netcapital investors relating to minority ownership in {doingBusinessAs || 'your company'}?
        </Typography>
        <Answer>
          <MinorityOwnershipRisks />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          What are the risks to Netcapital investors associated with corporate actions including:
        </Typography>
        <ul>
          <li>additional issuances of securities</li>
          <li>issuer repurchases of securities</li>
          <li>a sale of the issuer or of assets of the issuer</li>
          <li>transactions with related parties</li>
        </ul>
        <Answer>
          <CorporateActionRisks />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          In addition to the default risks included in your offering statement, what factors make an investment in {doingBusinessAs || 'your company'} speculative or risky?
        </Typography>
        <Answer>
          <AdditionalRisks />
        </Answer>
      </Question>
    </Form>
  );
}

export default Risks;
