import MUIButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const underlinedButton = (props) => {
  const { children } = props;
  return (
    <MUIButton variant="text" color="secondary" sx={styles.Input} {...props}>{children}</MUIButton>
  );
};

export const UnderlinedButton = React.memo(underlinedButton);

UnderlinedButton.defaultProps = {
  children: 'initial',
  color: 'secondary'
};

UnderlinedButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info','warning'])
};

export default UnderlinedButton;