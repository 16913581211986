const styles = {
  AppBar: ({ palette }) => ({
    display: 'block',
    position: 'static',
    backgroundColor: palette.common.white,
    boxShadow: 'none',
    marginBottom: '15px',
  }),
  Button: () => ({
    padding: '0.5rem',
  }),
  Link: {
    textDecoration: 'none',
  },
  Toolbar: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '65px',
  }),
};

export default styles;