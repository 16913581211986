import Question, { Answer } from '../../Question';

import { COMPANY_FORMATION } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchCompanyFormation } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function TaxIDNumber({ doingBusinessAs }) {
  const [ein, setEin] = useRecoilState(fetchCompanyFormation('ein'));
  const savingState = useAutoSave(COMPANY_FORMATION, 'ein', ein);
  const handleEIN = useCallback(e => setEin(e.target.value), [setEin]);
  
  return (
    <Question>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        What is {doingBusinessAs || 'your company'}’s Tax ID Number, TIN, or EIN?
      </Typography>
      <Answer>
        <TextField
          value={ein || ''}
          onChange={handleEIN}
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}