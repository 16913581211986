import Table, { Body } from '../../../../netcapital-components/Table';

import EquivalentLineItemRow from './row';
import EquivalentLineItemsTableHeader from './header';
import { FINANCIAL_STATEMENTS } from '../../../../static-info/listing-application-forms';
import { fetchFinancialStatements } from '../../../../_state/application-form';
import { useAutoSave } from '../../../../_actions/application-form';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function EquivalentLineItemsTable(){
  const [taxableIncome, setTaxableIncome] = useRecoilState(fetchFinancialStatements('taxableIncome'));
  const [taxesPaid, setTaxesPaid] = useRecoilState(fetchFinancialStatements('taxesPaid'));
  const [totalIncome, setTotalIncome] = useRecoilState(fetchFinancialStatements('totalIncome'));

  const savingTaxableIncome = useAutoSave(FINANCIAL_STATEMENTS, 'taxableIncome', taxableIncome);
  const savingTaxesPaid = useAutoSave(FINANCIAL_STATEMENTS, 'taxesPaid', taxesPaid);
  const savingTotalIncome = useAutoSave(FINANCIAL_STATEMENTS, 'totalIncome', totalIncome);
  
  const onChangeTaxableIncome = useCallback((newValue) => {
    setTaxableIncome(newValue);
  }, [setTaxableIncome]);
  const onChangeTaxesPaid = useCallback((newValue) => {
    setTaxesPaid(newValue);
  }, [setTaxesPaid]);
  const onChangeTotalIncome = useCallback((newValue) => {
    setTotalIncome(newValue);
  }, [setTotalIncome]);

  return (
    <Table onNewRow={null}>
      <EquivalentLineItemsTableHeader />
      <Body>
        <EquivalentLineItemRow 
          totalIncome={totalIncome}
          taxableIncome={taxableIncome}
          taxesPaid={taxesPaid}
          onChangeTotalIncome={onChangeTotalIncome}
          onChangeTaxableIncome={onChangeTaxableIncome}
          onChangeTaxesPaid={onChangeTaxesPaid}
          errorTaxableIncome={savingTaxableIncome && savingTaxableIncome.state === 'error'}
          errorTaxesPaid={savingTaxesPaid && savingTaxesPaid.state === 'error'}
          errorTotalIncome={savingTotalIncome && savingTotalIncome.state === 'error'}
        />
      </Body>
    </Table>
  );
}
