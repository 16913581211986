import Table, { Body } from '../../../../netcapital-components/Table';

import EmploymentRecordsHeader from './header';
import EmploymentRecordsRow from './row';
import Loading from '../../../Loading';
import _ from 'lodash';
import { fetchKeyPersonDetails } from '../../../../_state/application-form';
import produce from 'immer';
import { useCallback } from 'react';
import { useCreateEmploymentRecord } from '../../../../_actions/application-form/employment-record.actions';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonEmploymentRecords() {
  const { listingKey, offerKey, key: personKey } = useParams();
  const [ employmentRecords, setEmploymentRecords ] = useRecoilState(fetchKeyPersonDetails('employmentRecords'));
  const { create, loading } = useCreateEmploymentRecord(listingKey, offerKey, personKey);
  
  const addEmploymentRecord = useCallback(create, [create]);
  const handleChangeEmploymentRecord = useCallback((row, attribute, value) => {
    const updatedValues = produce(employmentRecords, (draft) => {
      _.set(draft[row], attribute, value);
    });
    setEmploymentRecords([...updatedValues]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentRecords]);

  if (typeof employmentRecords === 'undefined') return null;

  return (
    <>
      <Loading loading={loading} />
      <Table onNewRow={addEmploymentRecord}>
        <EmploymentRecordsHeader />
        <Body>
          {employmentRecords?.map((employmentRecord, index) =>
            <EmploymentRecordsRow
              employmentRecord={employmentRecord}
              index={index}
              onChange={handleChangeEmploymentRecord}
              key={employmentRecord.key}
            />
          )}
        </Body>
      </Table>
    </>
  );
}

export default KeyPersonEmploymentRecords;
