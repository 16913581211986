import EdgarCodes from './EdgarCodes';
import FilingAgreement from './FilingAgreement';
import Form from '../FormWrapper';
import { useGetCompliance } from '../../../_actions/application-form';
import { useParams } from 'react-router-dom';

function Compliance() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetCompliance(listingKey, offerKey);
  
  return (
    <Form title='Compliance' loaded={loaded}>
      <EdgarCodes />
      <FilingAgreement />
    </Form>
  );
}

export default Compliance;
