import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import { useCallback } from 'react';

function DateSelector({ onChange, ownerState, size, sx, value, ...props }) {
  const milliseconds = 60000;
  const getTimezoneOffset = (localDateTime) => localDateTime.getTimezoneOffset() * milliseconds;
  
  const makeLocalAppearUTC = useCallback((localValue) => {
    const localDateTime = new Date(localValue);
    const utcFromLocal = new Date(localDateTime.getTime() + getTimezoneOffset(localDateTime));
    return utcFromLocal;
  }, []);

  const renderInput = useCallback((params) => {
    return (
      <TextField 
        {...params}
        error={value.length > 0 && value === 'NaN-NaN-NaN'}
        size={size}
        ownerState={ownerState}
        sx={sx ? [{...sx}, { '& .MuiInputBase-root': { pointerEvents: 'none' }, '& .MuiInputBase-root button': { pointerEvents: 'all' } }] : { '& .MuiInputBase-root': { pointerEvents: 'none' }, '& .MuiInputBase-root button': { pointerEvents: 'all' } }}
        inputProps={{ 
          ...params.inputProps, 
          placeholder: 'MM/DD/YYYY' 
        }}
      />
    );
  },[ownerState, size, sx, value]);

  const handleDateChange = useCallback((date) => {
    if (!date){
      onChange('');
      return;
    }
    onChange(`${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-${date.day < 10 ? `0${date.day}` : date.day}`);
  },[onChange]);
  
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        {...props} 
        inputFormat='MM/dd/yyyy'
        onChange={handleDateChange}
        renderInput={renderInput}
        value={makeLocalAppearUTC(value)}
      />
    </LocalizationProvider>
  );
}

DateSelector.defaultProps = {
  ownerState: { subVariant: '' },
  size: 'large'
};

DateSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  ownerState: PropTypes.shape({
    subVariant: PropTypes.string
  }),
  size: PropTypes.oneOf(['small','medium','large']),
  value: PropTypes.string.isRequired,
};

export default DateSelector;