import Question, { Answer } from '../../Question';

import { CONTACT_INFO } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchContactInfo } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export default function LegalName() {
  const [legalName, setLegalName] = useRecoilState(fetchContactInfo('legalName'));
  const handleChangeLegalName = useCallback((e) => setLegalName(e.target.value), [setLegalName]);
  const savingState = useAutoSave(CONTACT_INFO, 'legalName', legalName);
  
  return (
    <Question>
      <Typography 
        variant='body1' 
        color='primary.main' 
        fontWeight='fontWeightMedium'
      >
        What is the full legal name of your company?
      </Typography>
      <Answer>
        <TextField 
          value={legalName || ''}
          onChange={handleChangeLegalName}
          name='legalName'
          placeholder='My Company, Inc.'
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}