import Question, { Answer, Description } from '../../Question';
import { fetchContactInfo, fetchFormGlobals, isReadOnlyState } from '../../../_state/application-form';
import { useGetContactInfo, useGetShares } from '../../../_actions/application-form';

import FinancialsReviewLevel from './FinancialsReviewLevel';
import Form from '../FormWrapper';
import { HELP } from '../../../static-info';
import Help from '../../../netcapital-components/Help';
import MinimumInvestmentAmount from './MinimumInvestmentAmount';
import SecuritiesAsPortalFee from './SecuritiesAsPortalFee';
import SharesAndPricing from './SharesAndPricing';
import { Typography } from '@mui/material';
import Valuation from './Valuation';
import { isAdminState } from '../../../_state/user';
import { isRegCF } from '../../../_helpers';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

function Shares() {
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetShares(listingKey, offerKey);
  const { loaded: loadedContactInfo } = useGetContactInfo(listingKey, offerKey);
  const isAdmin = useRecoilValue(isAdminState);
  const doingBusinessAs = useRecoilValue(fetchContactInfo('doingBusinessAs'));
  const isReadOnly = useRecoilValue(isReadOnlyState);
  const isRegCfOffering = isRegCF(useRecoilValue(fetchFormGlobals('offerExemptions')));

  return (
    <Form title='Shares &amp; Pricing' loaded={loaded && loadedContactInfo}>
      {isReadOnly && <Typography variant='body1'>This offering was published and can no longer be edited.</Typography>}
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          Have {doingBusinessAs || 'your company'}’s financial statements been reviewed or audited by an <Help explanation={HELP['Independent Accountant']}>independent accountant</Help>?
        </Typography>
        <Answer>
          <FinancialsReviewLevel readOnly={isReadOnly} />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          How much money are you planning to raise in this offering?
        </Typography>
        <Description>
          <Typography variant='body2' fontWeight='fontWeightMedium'>
            Note: When choosing the price per share for this offering, you are valuing your business. The pre-money valuation of your business is the price per share you choose for this offering multiplied by all the shares you currently have issued in your Cap Table. To calculate the post-money valuation, just add your Netcapital raise $ amount to the pre-money valuation.
          </Typography>
        </Description>
        <Answer>
          <SharesAndPricing doingBusinessAs={doingBusinessAs} readOnly={isReadOnly}/>
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
          How did you arrive at the valuation of your company?
        </Typography>
        <Answer>
          <Valuation readOnly={isReadOnly} />
        </Answer>
      </Question>
      {isAdmin && (
        <MinimumInvestmentAmount readOnly={isReadOnly} />
      )}
      {isRegCfOffering && isAdmin && (
        <SecuritiesAsPortalFee readOnly={isReadOnly} />
      )}
    </Form>
  );
}

export default Shares;
