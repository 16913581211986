import { KEY_PERSON } from '../../../static-info/listing-application-forms';
import { RADIO_OPTIONS } from '../../../static-info';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { fetchKeyPersonDetails } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

function KeyPersonHasOfficerHistory() {
  const { key: personKey } = useParams();
  const [ hasOfficerHistory, setHasOfficerHistory ] = useRecoilState(fetchKeyPersonDetails('hasOfficerHistory'));
  const savingState = useAutoSave(KEY_PERSON, 'hasOfficerHistory', hasOfficerHistory, personKey);
  const onChangeHasOfficerHistory = useCallback((e) => setHasOfficerHistory(e.target.value === 'true'), [setHasOfficerHistory]);

  if (typeof hasOfficerHistory === 'undefined') return null;

  return (
    <RadioGroup
      name='hasOfficerHistory'
      value={hasOfficerHistory}
      onChange={onChangeHasOfficerHistory}
      options={RADIO_OPTIONS.YES_NO}
      error={savingState && savingState.state === 'error'}
    />
  );
}

export default KeyPersonHasOfficerHistory;
