import EquitySecurities from './EquitySecurities';
import Form from '../FormWrapper';
import { Typography } from '@mui/material';
import WarrantsOptionsOtherRights from './WarrantsOptionsOtherRights';
import { useGetSecurities } from '../../../_actions/application-form';
import { useParams } from 'react-router-dom';

function Securities(){
  const { listingKey, offerKey } = useParams();
  const { loaded } = useGetSecurities(listingKey, offerKey);

  return (
    <Form title='Securities &amp; Debt' loaded={loaded}>
      <Typography variant='body1'>
        Describe the current structure of your company. You should also include the securities that are (or will be) authorized for this offering.
      </Typography>
      <EquitySecurities />
      <WarrantsOptionsOtherRights />
    </Form>
  );
}

export default Securities;