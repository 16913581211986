import Question, { Answer } from '../../Question';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { METADATA } from '../../../static-info/listing-application-forms';
import TextField from '../../../netcapital-components/TextField';
import TrackingPixelConversionLabels from './TrackingPixelConversionLabels';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { fetchMetadata } from '../../../_state/application-form';
import produce from 'immer';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

function DataLayerConfig() {
  const [dataLayerConfig, setDataLayerConfig] = useRecoilState(fetchMetadata('dataLayerConfig'));
  useAutoSave(METADATA, 'dataLayerConfig', dataLayerConfig);
  const handleChangeDataLayerConfig = useCallback((attribute) => (e) => {
    const newValue = e.target.value;
    const updatedValues = produce(dataLayerConfig, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setDataLayerConfig(updatedValues);
  }, [dataLayerConfig, setDataLayerConfig]);
  
  if (typeof dataLayerConfig === 'undefined') return null;
  
  return (
    <>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Data Layer Config</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Google Analytics Tracking ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'gaTrackingId')}
            onChange={handleChangeDataLayerConfig('gaTrackingId')}
            placeholder='G-XXXXXXXXX'
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Facebook Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'facebookPixel')}
            onChange={handleChangeDataLayerConfig('facebookPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Google Ads</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Conversion ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'googleAdsConversions.id')}
            onChange={handleChangeDataLayerConfig('googleAdsConversions.id')}
            placeholder='AW-XXXXXXXXX'
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Conversion Labels</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='top'
        >
          <TrackingPixelConversionLabels
            dataLayerConfig={dataLayerConfig}
            baseAttribute='googleAdsConversions.labels'
            onChange={handleChangeDataLayerConfig}
          />
        </Answer>
      </Question>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Twitter</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Universal Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'twitterUniversalPixel')}
            onChange={handleChangeDataLayerConfig('twitterUniversalPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Purchase Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'twitterPurchasePixel')}
            onChange={handleChangeDataLayerConfig('twitterPurchasePixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h2' fontWeight='fontWeightMedium'>LinkedIn</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'linkedInPixel')}
            onChange={handleChangeDataLayerConfig('linkedInPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Conversion Pixels</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='top'
        >
          <TrackingPixelConversionLabels
            dataLayerConfig={dataLayerConfig}
            baseAttribute='linkedInConversionPixels'
            onChange={handleChangeDataLayerConfig}
          />
        </Answer>
      </Question>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Yahoo/Oath Dot Tag</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Project ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'yahooProjectId')}
            onChange={handleChangeDataLayerConfig('yahooProjectId')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'yahooPixelId')}
            onChange={handleChangeDataLayerConfig('yahooPixelId')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Typography variant='h2' fontWeight='fontWeightMedium'>Other Vendors</Typography>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Snapchat Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'snapchatPixel')}
            onChange={handleChangeDataLayerConfig('snapchatPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Tiktok Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'tiktokPixel')}
            onChange={handleChangeDataLayerConfig('tiktokPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Reddit Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'redditPixel')}
            onChange={handleChangeDataLayerConfig('redditPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Bing Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'bingPixel')}
            onChange={handleChangeDataLayerConfig('bingPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>Oribi Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'oribiPixel')}
            onChange={handleChangeDataLayerConfig('oribiPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
      <Question>
        <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>HubioID Pixel ID</Typography>
        <Answer
          helperColor='warning.main'
          helperContent='Admin Only'
          helperIcon={<ErrorOutlineOutlinedIcon />}
          helperPosition='bottom'
        >
          <TextField
            value={_.get(dataLayerConfig, 'hubioIdPixel')}
            onChange={handleChangeDataLayerConfig('hubioIdPixel')}
            ownerState={{ subVariant: 'admin' }}
          />
        </Answer>
      </Question>
    </>
  );
}

export default DataLayerConfig;