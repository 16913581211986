import Copyright from './copyright';
import FooterLinks from './links';
import Social from './social';
import { Stack } from '@mui/material';
import styles from './styles';

const Footer = () => {
  return (
    <Stack spacing={3} sx={styles.Footer}>
      <FooterLinks />
      <Social />
      <Copyright />
    </Stack>
  );
};

export default Footer;