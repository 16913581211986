import { Box, Container, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';

import Loading from '../../Loading';
import MobileNavigation from './MobileNavigation';
import PropTypes from 'prop-types';
import { appGlobalSettingsState } from '../../../_state';
import { applicationFormRouteMap } from '../../../layouts/ApplicationForm/routeMap';
import styles from './styles';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userRoleState } from '../../../_state/user';

function Form({ children, title, loaded }) {
  const { isMobile, isTablet } = useRecoilValue(appGlobalSettingsState);
  const location = useLocation();
  const userRole = useRecoilValue(userRoleState);
  const topLevelRoutes = applicationFormRouteMap(userRole).filter(route => route.excludeFromNavigation !== true);
  const currentRouteIndex = useMemo(()=>topLevelRoutes.findIndex(route => location.pathname.includes(`/${route.pathname}`)),[location.pathname, topLevelRoutes]);
  const prevRoute = useMemo(()=> (currentRouteIndex > 0 ? topLevelRoutes[currentRouteIndex-1] : false),[currentRouteIndex, topLevelRoutes]);
  const nextRoute = useMemo(() => (currentRouteIndex < topLevelRoutes.length-1 ? topLevelRoutes[currentRouteIndex+1] : false), [currentRouteIndex, topLevelRoutes]);
  
  useEffect(() => {
    if (loaded === true) {
      window.scrollTo(0, 0);
    }
  }, [loaded]);
  
  return (
    <Box name='Form-component'>
      <Loading loading={!loaded} />
      <Stack spacing={{ xs: 2 }} sx={styles.Content}>
        <Typography variant='h1' sx={styles.Title}>{loaded && title}</Typography>
        {loaded && children}
      </Stack>
      {(isMobile || isTablet) && (
        <Box sx={[styles.Navigation, nextRoute && prevRoute && styles.NavigationFullHeight]}>
          <Container maxWidth='lg' sx={styles.Container}>
            <Stack
              sx={styles.ButtonStack}
              direction={{xs: 'column', sm: 'column', md: 'row'}}
              spacing={2}
            >
              <MobileNavigation
                prevRoute={prevRoute}
                nextRoute={nextRoute}
              />
            </Stack>
          </Container>
        </Box>
      )}
    </Box>
  );
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default Form;
