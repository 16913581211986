import MUIButton from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

const solidButton = (props) => {
  const { children } = props;
  return (
    <MUIButton variant="contained" color="secondary" {...props}>{children}</MUIButton>
  );
};

export const SolidButton = React.memo(solidButton);

SolidButton.defaultProps = {
  children: 'initial',
  color: 'secondary'
};

SolidButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info', 'warning', 'grey'])
};

export default SolidButton;
