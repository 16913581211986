import Cell from './cell';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

const component = ({children}) => (
  <MUITableHead name='Netcapital-Table-Footer'>
    <MUITableRow>{children}</MUITableRow>
  </MUITableHead>
);

export const Footer = React.memo(component);

Footer.defaultProps = {
  children: Cell
};

Footer.propTypes = {
  children: PropTypes.node
};

export default Footer;