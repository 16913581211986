import {
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import styles from './styles';
import { useCallback } from 'react';

function RelatedPersonDialog({ handleClose, open }){
  const onClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <IconButton sx={styles.DialogIconButton} onClick={onClose}>
        <CloseIcon sx={styles.DialogClose}/>
      </IconButton>
      <DialogContent sx={styles.DialogContent}>
        <Typography variant='h1' sx={[styles.BlockSpan, styles.BlockSpan.Bold]}>Related Person:</Typography>
        <Typography variant='body1' sx={styles.BlockSpan}>For purposes of this question, the term related person means:</Typography>
        <Typography variant='body1' sx={styles.BlockSpan}>
          <ul>
            <li>Any director or executive officer of the issuer;</li>
            <li>Any person who is the beneficial owner of more than 5% of any class of the issuer’s voting securities;</li>
            <li>Any immediate family member of the foregoing persons.</li>
          </ul>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

RelatedPersonDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default RelatedPersonDialog;