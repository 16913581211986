const SIDEBAR_WIDTH = 236;

const styles = {
  FixedHeaderOffset: () => ({
    margin: 0,
  }),
  HamburgerIcon: ({ palette }) => ({
    color: palette.common.white,
  }),
  HeaderDesktop: ({palette}) => ({
    width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
    marginLeft: `${SIDEBAR_WIDTH}px`,
    backgroundColor: palette.common.white,
  }),
  HeaderToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  HeaderToolbarMobile: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  GoBack: {
    margin: 'auto',
    marginLeft: 0,
    width: '1.875rem',
    height: '1.875rem',
  },
  MenuDesktop: {
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: SIDEBAR_WIDTH,
      boxSizing: 'border-box',
    },
    '& > .MuiPaper-elevation0': {
      border: 0,
    },
  },
  SearchBarMobile: {
    marginBottom: '1rem',
  },
  Sidebar: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    display: 'block',
  },
};

export {
  styles,
  SIDEBAR_WIDTH,
};
