import { Box, Container, Stack, Typography } from '@mui/material';
import { patchLockQuestionnaire, useGetQuestionnaires } from '../../_actions/questionnaires.actions';
import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import DataGrid from '../../netcapital-components/DataGrid';
import { Dialog } from '../../netcapital-components/Dialog';
import DialogBar from '../../layouts/DialogBar';
import Filters from './filters';
import { appGlobalSettingsState } from '../../_state';
import { fetchQuestionnaires } from '../../_state/questionnaires';
import { generateQuestionnairesTableColumns } from './utils';
import styles from './styles';
import { useAlertActions } from '../../_actions/alert.actions';
import { useSearchParams } from 'react-router-dom';

export default function Questionnaires(){
  const { isMobile } = useRecoilValue(appGlobalSettingsState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState({
    company: searchParams.get('company') || '',
    maxrange: parseInt(searchParams.get('maxrange'), 10) || 100,
    minrange: parseInt(searchParams.get('minrange'), 10) || 65,
    orderby: searchParams.get('orderby') || '',
    page: parseInt(searchParams.get('page'), 10) || 1,
  });
  const { loaded } = useGetQuestionnaires(query);
  const alertActions = useAlertActions();
  const [questionnaires, setQuestionnaires] = useRecoilState(fetchQuestionnaires('data'));
  const metadata = useRecoilValue(fetchQuestionnaires('metadata'));

  const handleChangeSearch = useCallback((e) => {
    //enforce page 1 when changing company name
    setQuery({...query, company: e.target.value, page: 1});
    setSearchParams({...query, company: e.target.value, page: 1});
  },[query, setQuery, setSearchParams]);

  const handleProgressRangeSliderChange = useCallback((e, values, activeThumb) => {
    const minDistance = 10;
    if (!Array.isArray(values)) {
      return;
    }
    if (values[1] - values[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(values[0], 100 - minDistance);
        setQuery({...query, minrange: clamped, maxrange: clamped + minDistance});
        setSearchParams({...query, minrange: clamped, maxrange: clamped + minDistance});
      } else {
        const clamped = Math.max(values[1], minDistance);
        setQuery({...query, minrange: clamped - minDistance, maxrange: clamped});
        setSearchParams({...query, minrange: clamped - minDistance, maxrange: clamped});
      }
    } else {
      setQuery({...query, minrange: values[0], maxrange: values[1]});
      setSearchParams({...query, minrange: values[0], maxrange: values[1]});
    }
  },[query, setQuery, setSearchParams]);

  const handleOrderBy = useCallback((e) => {
    // enforce page 1 when changing orderBy filter
    setQuery(() => ({...query, orderby: e.target.value, page: 1}));
    setSearchParams(() => ({...query, orderby: e.target.value, page: 1}));
  },[query, setQuery, setSearchParams]);

  const handleTablePagination = useCallback((paginationModel) => {
    setQuery({...query, page: paginationModel.page + 1});
    setSearchParams({...query, page: paginationModel.page + 1});
  },[query, setQuery, setSearchParams]);

  const handleQuestionnaireLockedStatusChange = useCallback(async (e, _key, listingKey) => {
    const matchingQuestionnaire = questionnaires.find(questionnaire => questionnaire._key === _key);
    const matchingIndex = questionnaires.findIndex(questionnaire => questionnaire._key === _key);

    if(matchingQuestionnaire){
      const copyQuestionnaires = [...questionnaires];
      copyQuestionnaires[matchingIndex] = {...matchingQuestionnaire, locked: e.target.checked};
      const resp = await patchLockQuestionnaire(listingKey, e.target.checked);
      if (resp.response && resp.response.status > 204) {
        alertActions.error('An error occurred', resp.response, 'Failed to lock/unlock the questionnaire', resp.request);
      } else {
        setQuestionnaires(copyQuestionnaires);
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertActions, setQuestionnaires]);

  return (
    <Dialog fullScreen open>
      <DialogBar />
      <Container maxWidth='1920' sx={styles.Container}>
        <Stack spacing={1}>
          <Box sx={styles.TitleWrapper}>
            <Typography variant='h1' sx={styles.Title}>Open Questionnaires</Typography>
          </Box>
          <Filters 
            query={query}
            onRangeChange={handleProgressRangeSliderChange}
            onSearchChange={handleChangeSearch}
            onSortChange={handleOrderBy}
          />
          <DataGrid 
            columns={generateQuestionnairesTableColumns(isMobile, handleQuestionnaireLockedStatusChange)}
            currentPage={query.page}
            getRowId={(row) => row.OfferKey}
            handlePaginate={handleTablePagination}
            isLoading={!loaded}
            pageSizeOptions={[25]}
            resultsPerPage={metadata?.limit || 25}
            rows={questionnaires || []}
            totalResults={metadata?.totalCount}
          />
        </Stack>
      </Container>
    </Dialog>
  );
}