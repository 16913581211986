import { Cell, EditField, Row } from '../../../../netcapital-components/Table';

import { useCallback } from 'react';

export default function PreviousNamesRow({ previousName, index, onChange, onRemoveRow, error }) {
  const handleRemoveRow = useCallback(() => onRemoveRow(index), [index, onRemoveRow]);
  const onChangeName = useCallback((e) => onChange(index, 'legalName', e.target.value), [onChange, index]);

  return (
    <Row onClickRemove={handleRemoveRow}>
      <Cell>
        <EditField value={previousName.legalName} onChange={onChangeName} placeholder='Previous Company Name' error={error} />
      </Cell>
    </Row>
  );
}
