import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const capTableState = atom({
  key: 'capTableState',
  default: {}
});

const fetchCapTable = selectorFamily({
  key: 'fetchCapTable',
  get: field => ({get}) => _.get(get(capTableState), field),
  set: field => ({set}, newValue) =>
    set(capTableState, prevState => ({...prevState, [field]: newValue})),
});

const fetchCapTableFile = selectorFamily({
  key: 'fetchCapTableFile',
  get: usage => ({get}) => {
    const files = get(capTableState).files || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(capTableState).files || [];

    if (!newFile) {
      set(capTableState, prevState => ({...prevState, files: files.filter(file => file.usage !== usage)}));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(capTableState, prevState => ({...prevState, files: updatedFiles}));
    }
  }
});

export {
  fetchCapTable,
  capTableState,
  fetchCapTableFile,
};
