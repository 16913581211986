import Question, { Answer } from '../../Question';

import { FINANCIAL_CONDITION } from '../../../static-info/listing-application-forms';
import { HELP } from '../../../static-info/index';
import Help from '../../../netcapital-components/Help';
import TextField from '../../../netcapital-components/TextField';
import { Typography } from '@mui/material';
import { fetchFinancialCondition } from '../../../_state/application-form';
import { useAutoSave } from '../../../_actions/application-form/_save-forms.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

export function FinancialCondition({ doingBusinessAs }) {
  const [financialCondition, setFinancialCondition] = useRecoilState(fetchFinancialCondition('description'));

  const savingState = useAutoSave(FINANCIAL_CONDITION, 'description', financialCondition);
  const handleChange = useCallback((e) => setFinancialCondition(e.target.value), [setFinancialCondition]);

  return (
    <Question>
      <Typography variant='body1' color='primary.main' fontWeight='fontWeightMedium'>
        What is the <Help explanation={HELP['Financial Condition']}>financial condition</Help> of {doingBusinessAs || 'your company'} and its predecessors?
      </Typography>
      <Answer>
        <TextField
          multiline
          rows={10}
          value={financialCondition || ''}
          onChange={handleChange}
          placeholder='Financial Condition'
          error={savingState && savingState.state === 'error'}
        />
      </Answer>
    </Question>
  );
}