import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const keyPeopleState = atom({
  key: 'KeyPeople',
  default: {},
});

const fetchKeyPeople = selectorFamily({
  key: 'fetchKeyPeople',
  get: field => ({get}) => _.get(get(keyPeopleState), field),
  set: field => ({set}, newValue) =>
    set(keyPeopleState, prevState => ({...prevState, [field]: newValue})),
});

export {
  fetchKeyPeople,
  keyPeopleState,
};
